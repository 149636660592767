import EnlilAxios from "../../../../util/EnlilAxios";
import {V1EmployeeIDOCToArray} from "../../../../util/Payload";
import EnterpriseComponent from "../../EnterpriseComponent";


class EmployeeEnterpriseComponent extends EnterpriseComponent {
    performQuery = (page, pageSize, foreignKey) => {
        page = page || 1;
        pageSize = pageSize || this.getDefaultCacheSize();

        this.notifyLoadStartListeners();

        EnlilAxios({
            method: "GET",
            url: "t2/v1/employees",
            json: true,
            params: {
                "Limit": 10000,
                "Offset": (page - 1) * pageSize,
                "Sort": this.getFormattedSortSpecification(),
                ...this.getSearchSpecification(),
            },
            headers: {
                "idoc-response": this.getIDOCResponse() ?? "v1.Employee-001",
            },
        }).then(response => {
            this.fetchEnterpriseComponentSuccess(V1EmployeeIDOCToArray(response.data), page, pageSize);
        }).catch(error => {
            this.fetchEnterpriseComponentFailed(error);
        });
    };
}

export default EmployeeEnterpriseComponent;