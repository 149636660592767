import EnterpriseComponentMetadata from "../../metadata/ec/EnterpriseComponentMetadata";
import picklistClassFactory from "../PicklistClassFactory";
import Picklist from "../picklist/Picklist";
import EnterpriseComponent from "./EnterpriseComponent";
import EnterpriseComponentAttributeMetadata from "../../metadata/ec/EnterpriseComponentAttributeMetadata";

class EnterpriseComponentAttribute extends EnterpriseComponentMetadata {
    fEnterpriseComponent: EnterpriseComponent;
    name: string;
    fNoCopy: boolean;
    fNoUpdate: boolean;
    fRequired: boolean;
    fRequiredForQuery: boolean;
    fPicklistName: string | null;
    fPicklist: Picklist | null;
    fScale: number | null;
    fPrecision: number | null;
    fLength: number | null;
    fMinLength: number | null;
    fPattern: string | null;
    fType: string | null;
    fDefaultValue: string | boolean | null;
    fComputed: boolean;

    constructor(parent: EnterpriseComponent, enterpriseComponentAttributeMetadata: EnterpriseComponentAttributeMetadata) {
        super();

        this.fEnterpriseComponent = parent;
        this.name = enterpriseComponentAttributeMetadata.getName();
        this.fNoCopy = enterpriseComponentAttributeMetadata.getNoCopy();
        this.fNoUpdate = enterpriseComponentAttributeMetadata.getNoUpdate();
        this.fRequired = enterpriseComponentAttributeMetadata.getRequired();
        this.fRequiredForQuery = enterpriseComponentAttributeMetadata.getRequiredForQuery();
        this.fPicklistName = enterpriseComponentAttributeMetadata.getPicklist();
        this.fPicklist = null;
        this.fScale = enterpriseComponentAttributeMetadata.getScale();
        this.fPrecision = enterpriseComponentAttributeMetadata.getPrecision();
        this.fLength = enterpriseComponentAttributeMetadata.getLength();
        this.fMinLength = enterpriseComponentAttributeMetadata.getMinLength();
        this.fPattern = enterpriseComponentAttributeMetadata.getPattern();
        this.fType = enterpriseComponentAttributeMetadata.getType();
        this.fDefaultValue = enterpriseComponentAttributeMetadata.getDefaultValue();
        this.fComputed = enterpriseComponentAttributeMetadata.getComputed();

        if (this.fPicklistName !== null) {
            this.fPicklist = picklistClassFactory(this.fPicklistName);
        }
    }

    getAttributeValue(type: string) {
        return this.fEnterpriseComponent.getAttributeValue(this.name, type);
    };

    getEnterpriseComponent() {
        return this.fEnterpriseComponent;
    };

    getNoCopy() {
        return this.fNoCopy;
    };

    getNoUpdate() {
        return this.fNoUpdate;
    };

    getRequired() {
        return this.fRequired;
    };

    getRequiredForQuery() {
        return this.fRequiredForQuery;
    };

    getPicklist() {
        return this.fPicklist;
    };

    getScale() {
        return this.fScale;
    };

    getPrecision() {
        return this.fPrecision;
    };

    getLength() {
        return this.fLength;
    };

    getMinLength() {
        return this.fMinLength;
    };

    getPattern() {
        return this.fPattern;
    };

    getType() {
        return this.fType;
    };

    getDefaultValue() {
        return this.fDefaultValue;
    };

    getComputed() {
        return this.fComputed;
    };
}

export default EnterpriseComponentAttribute;


