import EnterpriseObjectMemberMetadata from "../../EnterpriseObjectMemberMetadata";
import EnterpriseObjectMetadata from "../../EnterpriseObjectMetadata";

class BillConfigurationEnterpriseObjectMetadata extends EnterpriseObjectMetadata {

    constructor() {
        super();

        this.name = "BillConfiguration";
        this.primaryEnterpriseComponent = "BillConfiguration";

        let eom: EnterpriseObjectMemberMetadata = new EnterpriseObjectMemberMetadata();
        eom.name = "BillConfiguration";
        eom.enterpriseComponent = "BillConfiguration";
        eom.relationship = undefined;
        this.enterpriseComponentMembers.set(eom.name, eom);

        eom = new EnterpriseObjectMemberMetadata();
        eom.name = "BillSubscription";
        eom.enterpriseComponent = "BillSubscription";
        eom.relationship = "BillConfigurationBillSubscription";
        this.enterpriseComponentMembers.set(eom.name, eom);

        eom = new EnterpriseObjectMemberMetadata();
        eom.name = "BillSubscriptionCommunication";
        eom.enterpriseComponent = "BillSubscriptionCommunication";
        eom.relationship = "BillSubscriptionBillSubscriptionCommunication";
        this.enterpriseComponentMembers.set(eom.name, eom);
    }
}

export default BillConfigurationEnterpriseObjectMetadata;