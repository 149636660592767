import RelationshipMetadata from "../../RelationshipMetadata";

class BillConfigurationBillSubscriptionRelationshipMetadata extends RelationshipMetadata {
    constructor() {
        super();

        this.name = "BillConfigurationBillSubscriptionRelationshipMetadata";
        this.parentEnterpriseComponent = "BillConfiguration";
        this.childEnterpriseComponent = "BillSubscription";
        this.sourceAttribute = "USID";
        this.destinationAttribute = "BillConfigurationUSID";
    }
}

export default BillConfigurationBillSubscriptionRelationshipMetadata;