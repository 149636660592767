import React from "react";
import "../Navigation/css/NavBar.scss";
import {Black} from "../../theme/styles";

export default function Exceptions() {
    return (
        <div style={{paddingLeft: "20px", paddingRight: "30px"}}>
            <p style={{fontSize: "24px", fontWeight: "400", color: Black}}>Exceptions</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}></p>
        </div>
    )
}