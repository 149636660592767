import React from "react";
import "../Navigation/css/NavBar.scss";
import {Black} from "../../theme/styles";
import part001 from '../../json/part/part-001.json';
import part004Post from '../../json/part/part-004Post.json';
import ReactJson from 'react-json-view'
import css_self from "../../pages/css/Pages.module.scss";
import {Link, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import document_type_001 from "../../json/document-type-001-PART.json";
import document_type_002 from "../../json/document-type-002-PART.json";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineContent from "@mui/lab/TimelineContent";


export default function EquipmentsPost() {
    const navigate = useNavigate();

    return (
        <div style={{paddingLeft: "20px", paddingRight: "30px"}}>
            <p style={{fontSize: "24px", fontWeight: "400", color: Black}}>Equipments POST</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Overview</p>

            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                In Enlil, equipments are documents with a DocumentTypeGroup attribute equal to "EQUIPMENT". The equipments POST API allows for the creation of equipment content.
            </p>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Parameters</p>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>Header Parameters
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>X-Enlil-AccessToken (Required String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The X-Enlil-AccessToken is obtained during the authentication process and expires after 90 days.</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>X-Enlil-RefreshToken (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The X-Enlil-RefreshToken is obtained during the authentication process and never expires. This token is provided is the caller wants a refreshed X-Enlil-AccessToken</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>idoc-request (Required String: must equal v1.Part-004)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The name of the IDOC being specified in the POST request.</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>idoc-response (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The name of the IDOC being requested in the response. In idoc-response is provided, the response payload (HTTP Code: 200) will match the specified format, else no response (HTTP Code: 204) will be provided.</li>
                </li>
            </ul>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Examples</p>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                Enlil requires POST content to be in the v1.Equipment-004 format. For example, the user executes this POST using idoc-request=v1.Equipment-004 (below) with idoc-response=v1.Equipment-001, Enlil will respond (HTTP Code: 200) with a payload of the v1.Equipment-001 format:<br/><br/>

                <p style={{fontFamily: "Courier New", fontSize: "12px"}}>
                    <ReactJson src={part004Post} collapseStringsAfterLength={75} collapsed={4}/>
                </p>

                <br/>
                The response will resemble:<br/><br/>

                <p style={{fontFamily: "Courier New", fontSize: "12px"}}>
                    <ReactJson src={part001} collapseStringsAfterLength={75} collapsed={4}/>
                </p>
            </p>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>v1.Part-001 Payload Documentation</p>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                    <li style={{marginTop: "20px"}}>Part attributes
                        <li style={{marginTop: "20px", marginLeft: "20px"}}>DocumentTypeUSID (Required String). To obtain a DocumentTypeUSID, the user is advised to execute the following procedure:<br/><br/>
                            <Timeline position="left" sx={{ width: "900px", marginLeft: "65px"}} >
                                <TimelineItem>
                                    <TimelineOppositeContent sx={{m: 'auto 0'}} align="right" variant="body2" color="black">
                                        In this step, the Enlil user will pick the Document Type to associate the part with.<br/><br/>
                                        Execute <Link className={css_self.Link4} onClick={() => {navigate("/api-docs-tools/api-explorer/document-types-get");}}>/t3/v1/document-types GET</Link> API<br/><br/>
                                        <span style={{fontFamily: "Courier New", fontSize: "14px",}}>curl -G \\<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-d "Group=PART" \<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-H "Accept: application/json" \<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-H "idoc-response: v1.DocumentType-001" \<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-H "X-Enlil-AccessToken: &#60;token&#62;" \<br/>http://integrate.enlil.io/t3/v1/document_types</span><br/><br/>

                                        The response will resemble:<br/><br/>

                                        <p style={{fontFamily: "Courier New", fontSize: "12px"}}>
                                            <ReactJson src={document_type_001} collapseStringsAfterLength={75} collapsed={4}/>
                                        </p>
                                        <div style={{borderBottom: "1px solid #DDDDDD", width: "860px"}}/>
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                                        <TimelineDot color="primary" sx={{ width: "20px", height: "20px", justifyContent: "center"}}>1</TimelineDot>
                                        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                                    </TimelineSeparator>
                                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                                        <Typography variant="h6" component="span">Execute</Typography>
                                    </TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                    <TimelineOppositeContent sx={{m: 'auto 0'}} variant="body2" color="black">
                                        In this step, the Enlil user will select a Document Type record from the response. Note the USID value. For example, in the result set from step 1, USID 522542ef-eafe-4a2f-a39f-a1a79a4f701d is located at .ShifamedMessage.ListOfDocumentType.DocumentType[1].USID<br/><br/>
                                        <div style={{borderBottom: "1px solid #DDDDDD", width: "860px"}}/>
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                                        <TimelineDot color="primary" sx={{ width: "20px", height: "20px", justifyContent: "center"}}>2</TimelineDot>
                                        <TimelineConnector sx={{ bgcolor: 'white' }} />
                                    </TimelineSeparator>
                                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                                        <Typography variant="h6" component="span">Select</Typography>
                                    </TimelineContent>
                                </TimelineItem>
                            </Timeline>
                        </li>
                    </li>
                    <li style={{marginTop: "20px"}}>PartRevision attributes
                        <li style={{marginTop: "20px", marginLeft: "20px"}}>Description (Optional String)</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>The description of the part which will appear in the UI</li>
                        <li style={{marginTop: "20px", marginLeft: "20px"}}>Name (Optional String)</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>The name of the part which will appear in the UI</li>
                        <li style={{marginTop: "20px", marginLeft: "20px"}}>DisplayRevision (Optional String)</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Enlil supports Revision Stages and a company may have 2 (revision stage 1 and 3) or 3 (revision stages 1, 2 and 3). <br/>
                            If a document is in Numeric revision stage, its display revision will increment as follows: 1, 2, 3 ...<br/>
                            If a document is in Alpha-Numeric revision stage, its display revision will increment as follows: 1A, 1B, 1C ...<br/>
                            If a document is in Alpha revision stage, its display revision will increment as follows: A, B, C ...<br/><br/>
                            When creating a part, if no value is provided for DisplayRevision, then 1 is set.<br/>
                            If a company has 2 revision stages configured and a value is provided for DisplayRevision, then valid values are 1 and A.<br/>
                            If a company has 3 revision stages configured and a value is provided for DisplayRevision, then valid values are 1, 1A and A.<br/>
                        </li>
                        <li style={{marginTop: "20px", marginLeft: "20px"}}>FormDocumentRevisionUSID (Optional String). To obtain a FormDocumentRevisionUSID, the user is advised to execute the following procedure:<br/><br/>
                            <Timeline position="left" sx={{width: "900px", marginLeft: "65px"}}>
                                <TimelineItem>
                                    <TimelineOppositeContent sx={{m: 'auto 0'}} align="right" variant="body2" color="black">
                                        In this step, the Enlil user will pick the Document Type to associate the part with.<br/><br/>
                                        Execute <Link className={css_self.Link4} onClick={() => {
                                        navigate("/api-docs-tools/api-explorer/document-types-get");
                                    }}>/t3/v1/document-types GET</Link> API<br/><br/>
                                        <span style={{fontFamily: "Courier New", fontSize: "14px",}}>curl -G \\<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-d "Group=PART" \<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-H "Accept: application/json" \<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-H "idoc-response: v1.DocumentType-001" \<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-H "X-Enlil-AccessToken: &#60;token&#62;" \<br/>http://integrate.enlil.io/t3/v1/document_types</span><br/><br/>

                                        The response will resemble:<br/><br/>

                                        <p style={{fontFamily: "Courier New", fontSize: "12px"}}>
                                            <ReactJson src={document_type_001} collapseStringsAfterLength={75} collapsed={4}/>
                                        </p>
                                        <div style={{borderBottom: "1px solid #DDDDDD", width: "860px"}}/>
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineConnector sx={{bgcolor: 'secondary.main'}}/>
                                        <TimelineDot color="primary" sx={{width: "20px", height: "20px", justifyContent: "center"}}>1</TimelineDot>
                                        <TimelineConnector sx={{bgcolor: 'secondary.main'}}/>
                                    </TimelineSeparator>
                                    <TimelineContent sx={{py: '12px', px: 2}}>
                                        <Typography variant="h6" component="span">Execute</Typography>
                                    </TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                    <TimelineOppositeContent sx={{m: 'auto 0'}} variant="body2" color="black">
                                        In this step, the Enlil user will select a Document Type record from the response. Note the USID value. For example, in the result set from step 1, USID 522542ef-eafe-4a2f-a39f-a1a79a4f701d is located at .ShifamedMessage.ListOfDocumentType.DocumentType[1].USID<br/><br/>
                                        <div style={{borderBottom: "1px solid #DDDDDD", width: "860px"}}/>
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineConnector sx={{bgcolor: 'secondary.main'}}/>
                                        <TimelineDot color="primary" sx={{width: "20px", height: "20px", justifyContent: "center"}}>2</TimelineDot>
                                        <TimelineConnector sx={{bgcolor: 'secondary.main'}}/>
                                    </TimelineSeparator>
                                    <TimelineContent sx={{py: '12px', px: 2}}>
                                        <Typography variant="h6" component="span">Select</Typography>
                                    </TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                    <TimelineOppositeContent sx={{m: 'auto 0'}} align="right" variant="body2" color="black">
                                        In this step, the Enlil user will again execute the document type GET API using the v1.DocumentType-002 IDOC and get the list of associated forms<br/><br/>
                                        Execute <Link className={css_self.Link4} onClick={() => {
                                        navigate("/api-docs-tools/api-explorer/document-types-get");
                                    }}>/t3/v1/document-types GET</Link> API<br/><br/>
                                        <span style={{fontFamily: "Courier New", fontSize: "14px",}}>curl -G \<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-d "USID=&#60;USID&#62;" \<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-H "Accept: application/json" \<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-H "idoc-response: v1.DocumentType-002" \<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-H "X-Enlil-AccessToken: &#60;token&#62;" \<br/>http://integrate.enlil.io/t3/v1/document_types</span><br/><br/>

                                        The response will resemble:<br/><br/>

                                        <p style={{fontFamily: "Courier New", fontSize: "12px"}}>
                                            <ReactJson src={document_type_002} collapseStringsAfterLength={75} collapsed={4}/>
                                        </p>
                                        <div style={{borderBottom: "1px solid #DDDDDD", width: "860px"}}/>
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineConnector sx={{bgcolor: 'secondary.main'}}/>
                                        <TimelineDot color="primary" sx={{width: "20px", height: "20px", justifyContent: "center"}}>3</TimelineDot>
                                        <TimelineConnector sx={{bgcolor: 'secondary.main'}}/>
                                    </TimelineSeparator>
                                    <TimelineContent sx={{py: '12px', px: 2}}>
                                        <Typography variant="h6" component="span">Execute</Typography>
                                    </TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                    <TimelineOppositeContent sx={{m: 'auto 0'}} variant="body2" color="black">
                                        In this step, the Enlil user will select a Form record from the response. Note the DocumentRevisionUSID value. For example, in the result set from step 3, DocumentRevisionUSID faa42d32-009f-4533-91a6-db96e603e67b is located at
                                        .ShifamedMessage.ListOfDocumentType.DocumentType[0].ListOfForm.Form[0].DocumentRevisionUSID<br/><br/>
                                        <div style={{borderBottom: "1px solid #DDDDDD", width: "860px"}}/>
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineConnector sx={{bgcolor: 'secondary.main'}}/>
                                        <TimelineDot color="primary" sx={{width: "20px", height: "20px", justifyContent: "center"}}>4</TimelineDot>
                                        <TimelineConnector sx={{bgcolor: 'white'}}/>
                                    </TimelineSeparator>
                                    <TimelineContent sx={{py: '12px', px: 2}}>
                                        <Typography variant="h6" component="span">Select</Typography>
                                    </TimelineContent>
                                </TimelineItem>
                            </Timeline>
                        </li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>A Part Revision will request a valid reference to a FormDocumentRevisionUSID. If the Enlil user provided this value, Enlil will verify the value. If no value is provided and only one form is configured for the Document
                            Type, then that form is selected else an error will be raised.
                        </li>
                    </li>
                </ul>
            </p>
        </div>
    )
}