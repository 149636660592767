import EnlilAxios from "../../../../util/EnlilAxios";
import {V1BillSubscriptionCommunicationIDOCToArray, V1BillSubscriptionIDOCToArray} from "../../../../util/Payload";
import EnterpriseComponent from "../../EnterpriseComponent";
import {v4 as uuidv4} from "uuid";


class BillSubscriptionCommunicationEnterpriseComponent extends EnterpriseComponent {
    performQuery = (page, pageSize, foreignKey) => {
        page = page || 1;
        pageSize = pageSize || this.getDefaultCacheSize();

        this.notifyLoadStartListeners();

        let qbe = btoa(JSON.stringify({
            "ShifamedMessage": {
                "-idoc": "v1.BillConfiguration-003",
                "-id": uuidv4(),
                "BillConfiguration": {
                    "_attributes": {
                        "limit": 1,
                        "sort": null,
                        "offset": 0
                    },
                    "BillSubscription": {
                        "_attributes": {
                            "limit": 20,
                            "offset": 0
                        },
                        "QueryGroups": {
                            "QueryGroup": [
                                {
                                    "Name": "QG1",
                                    "Type": "QueryGroup",
                                    "Operator": "AND",
                                    "Membership": {
                                        "Member": [
                                            {
                                                "Type": "Attribute",
                                                "Name": "USID",
                                                "Value": foreignKey,
                                                "Operator": "EQUAL"
                                            }
                                        ]
                                    }
                                }
                            ]
                        },
                        "BillSubscriptionCommunication": {
                            "_attributes": {
                                "limit": 40,
                                "sort": "CreatedDatetime:DESC",
                                "offset": (page - 1) * pageSize
                            }
                        }
                    }
                }
            }
        }))

        EnlilAxios({
            method: "GET",
            url: "t2/v1/bill_configurations",
            json: true,
            params: {
                "QBE": qbe
            },
            headers: {
                "idoc-response": this.getIDOCResponse() ?? "v1.BillConfiguration-003",
            },
        }).then(response => {
            this.fetchEnterpriseComponentSuccess(V1BillSubscriptionCommunicationIDOCToArray(response.data), page, pageSize);
        }).catch(error => {
            this.fetchEnterpriseComponentFailed(error);
        });
    };

    enterpriseComponentPutIDOC = () => {
        let payload = {};

        payload.ShifamedMessage = {};
        payload.ShifamedMessage["-idoc"] = "v1.BillConfiguration-001";
        payload.ShifamedMessage.BillConfiguration = {
            "USID": this.getEnterpriseObject().getEnterpriseComponent("BillConfiguration").getAttributeValue("USID"),
            "BillSubscription": this.preparePUTPayload()
        };

        return payload;
    };

    performUpdate() {
        let payload = this.enterpriseComponentPutIDOC();

        let options = {
            method: "PUT",
            url: "t2/v1/bill_configurations",
            json: true,
            data: payload,
            headers: {
                "idoc-request": "v1.BillConfiguration-001",
                "idoc-response": "v1.BillConfiguration-001",
            },
        };
        this.notifyUpdateStartListeners();
        EnlilAxios(options)
            .then(response => {
                this.updateEnterpriseComponentSuccess(V1BillSubscriptionIDOCToArray(response.data));
            })
            .catch(error => {
                this.updateEnterpriseComponentFailed(error);
            });
    };
}

export default BillSubscriptionCommunicationEnterpriseComponent;