import React from "react";
import "../Navigation/css/NavBar.scss";
import {Black} from "../../theme/styles";
import css_self from "../../pages/css/Pages.module.scss";
import {Link} from "@mui/material";
import {useNavigate} from "react-router-dom";

export default function GettingStarted() {
    const navigate = useNavigate();

    return (
        <div style={{paddingLeft: "20px", paddingRight: "30px"}}>
            <p style={{fontSize: "24px", fontWeight: "400", color: Black}}>Getting Started</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>The Enlil REST API utilizes a REST architecture so you can seamlessly integrate your application with the Enlil SaaS platform.</p>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black}}>Here is how to get started:</p>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px", marginLeft: "20px"}}><Link className={css_self.Link4} onClick={(event) => {
                    navigate("/api-docs-tools/getting-started/create-your-application")
                }}>Create your application</Link>
                </li>
                <li style={{marginTop: "20px", marginLeft: "20px"}}><Link className={css_self.Link4} onClick={(event) => {
                    navigate("/api-docs-tools/getting-started/authenticate")
                }}>Authenticate</Link>
                </li>
                <li style={{marginTop: "20px", marginLeft: "20px"}}><Link className={css_self.Link4} onClick={(event) => {
                    navigate("/api-docs-tools/getting-started/using-api-explorer")
                }}>Using API Explorer</Link>
                </li>
                <li style={{marginTop: "20px", marginLeft: "20px"}}><Link className={css_self.Link4} onClick={(event) => {
                    navigate("/api-docs-tools/getting-started/create-get-requests")
                }}>Create GET requests</Link>
                </li>
                <li style={{marginTop: "20px", marginLeft: "20px"}}><Link className={css_self.Link4} onClick={(event) => {
                    navigate("/api-docs-tools/getting-started/privileges-and-authorization")
                }}>Privileges & authorization</Link>
                </li>
                <li style={{marginTop: "20px", marginLeft: "20px"}}><Link className={css_self.Link4} onClick={(event) => {
                    navigate("/api-docs-tools/getting-started/logging")
                }}>Getting logs</Link>
                </li>
                <li style={{marginTop: "20px", marginLeft: "20px"}}><Link className={css_self.Link4} onClick={(event) => {
                    navigate("/api-docs-tools/getting-started/exceptions")
                }}>Getting information about exceptions</Link>
                </li>
            </ul>
        </div>
    )
}