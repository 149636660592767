import EnterpriseComponentAttributeMetadata from "../../EnterpriseComponentAttributeMetadata";
import EnterpriseComponentMetadata from "../../EnterpriseComponentMetadata";

class BillConfigurationEnterpriseComponentMetadata extends EnterpriseComponentMetadata {

    constructor() {
        super();

        this.name = "BillConfiguration";
        this.defaultCacheSize = 50;

        this.attributes["UserName"] = new EnterpriseComponentAttributeMetadata(
            "UserName", // name
            false, // NoCopy
            false, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "String", // Type
            null, // default value
            false, // computed
        );
        this.attributes["Password"] = new EnterpriseComponentAttributeMetadata(
            "Password", // name
            false, // NoCopy
            false, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "String", // Type
            null, // default value
            false, // computed
        );
        this.attributes["OrganizationID"] = new EnterpriseComponentAttributeMetadata(
            "OrganizationID", // name
            false, // NoCopy
            false, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "String", // Type
            null, // default value
            false, // computed
        );
        this.attributes["DeveloperKey"] = new EnterpriseComponentAttributeMetadata(
            "DeveloperKey", // name
            false, // NoCopy
            false, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "String", // Type
            null, // default value
            false, // computed
        );
        this.attributes["V2APIUrl"] = new EnterpriseComponentAttributeMetadata(
            "V2APIUrl", // name
            false, // NoCopy
            false, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "String", // Type
            null, // default value
            false, // computed
        );
        this.attributes["V3APIUrl"] = new EnterpriseComponentAttributeMetadata(
            "V3APIUrl", // name
            false, // NoCopy
            false, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "String", // Type
            null, // default value
            false, // computed
        );
        this.attributes["CreatedDatetime"] = new EnterpriseComponentAttributeMetadata(
            "CreatedDatetime", // name
            false, // NoCopy
            true, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "Datetime", // Type
            null, // default value
            false, // computed
        );
        this.attributes["LastModifiedDatetime"] = new EnterpriseComponentAttributeMetadata(
            "LastModifiedDatetime", // name
            false, // NoCopy
            true, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "Datetime", // Type
            null, // default value
            false, // computed
        );
        this.attributes["USID"] = new EnterpriseComponentAttributeMetadata(
            "USID", // name
            false, // NoCopy
            true, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            36, // length
            null, // minLength
            null, // pattern
            "UUID", // Type
            null, // default value
            false, // computed
        );
    }
}

export default BillConfigurationEnterpriseComponentMetadata;
