import {withStyles} from "@mui/styles";
import styles, {Black, Blue3, Grey6} from "../../theme/styles";

import {EnlilTable} from "../EnlilTable";
import {withAppContext} from "../withAppContext";
import withNavigate from "../../util/useNavigate";
import withBreakpoint from "../../util/withBreakpoint";
import React from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import ReactJson from "react-json-view";
import CCButton from "../Common/CCButton";

class DeveloperExceptionTable extends EnlilTable {

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getCellRenderer(name) {
        if (name === "Exception Type Cell") {
            return this.renderExceptionTypeCell;
        } else if (name === "Exception Application Cell") {
            return this.renderExceptionApplicationCell;
        } else if (name === "Exception Analysis Cell") {
            return this.renderExceptionAnalysisCell;
        }
        return super.getCellRenderer(name);
    };

    onRowDoubleClick(params) {
        this.applet.getEnterpriseComponent().selectRecord(params.row.id);

        this.setState({renderTarget: "Show Exception"});
    };

    handleMenuClick(identifier) {
        super.handleMenuClick(identifier);
    }

    renderJSONDialog() {
        return (
            <Dialog onClose={(event, reason) => this.setState({renderTarget: null})} open={true} scroll="body" maxWidth={"xl"} maxHeight={"xl"}>
                <DialogTitle>Enlil Exception {this.applet.getEnterpriseComponent().getAttributeValue("ExceptionPayload")["name"]} - {this.applet.getEnterpriseComponent().getAttributeValue("ExceptionPayload")["description"]}</DialogTitle>
                <DialogActions>
                    <CCButton color="primary" onClick={() => this.setState({renderTarget: null})}>
                        Close
                    </CCButton>
                </DialogActions>
                <DialogContent>
                    <p style={{fontFamily: "Courier New", fontSize: "12px"}}>
                        <ReactJson keySort={true} src={this.applet.getEnterpriseComponent().getAttributeValue("ExceptionPayload")} collapseStringsAfterLength={175} collapsed={10}/>
                    </p>
                </DialogContent>
                <DialogActions>
                    <CCButton color="primary" onClick={() => this.setState({renderTarget: null})}>
                        Close
                    </CCButton>
                </DialogActions>
            </Dialog>
        )
    }

    renderExtension() {
        if (this.state.renderTarget === "Show Exception") {
            return this.renderJSONDialog()
        }
    }

    renderExceptionTypeCell = (___, params, __, _) => {
        if (params.row.id > this.state.buffer.length - 1) return;

        return (
            <div style={{marginTop: "-10px"}}>
                <div style={{fontSize: 14, color: Blue3, height: "20px"}}>{this.state.buffer[params.row.id].get("ExceptionType")}</div>
                <div style={{fontSize: 11, color: Grey6, height: "20px"}}>Event ID: {this.state.buffer[params.row.id].get("USID")}</div>
            </div>
        );
    };

    renderExceptionAnalysisCell = (___, params, __, _) => {
        if (params.row.id > this.state.buffer.length - 1) return;

        let value = this.state.buffer[params.row.id].get("ExceptionPayload")["description"]

        return (
            <div style={{marginTop: "0px"}}>
                <div style={{fontSize: 14, color: Black, height: "20px"}}>{value}</div>
            </div>
        );
    };

    renderExceptionApplicationCell = (___, params, __, _) => {
        if (params.row.id > this.state.buffer.length - 1) return;

        let value = this.state.buffer[params.row.id].get("ExceptionPayload")["application"]

        return (
            <div style={{marginTop: "0px"}}>
                <div style={{fontSize: 14, color: Black, height: "20px"}}>{value}</div>
            </div>
        );
    };
}

export default withNavigate(withAppContext(withStyles(styles, {withTheme: true})(withBreakpoint(DeveloperExceptionTable))));