import AbstractMetadata from "../AbstractMetadata";
import EnterpriseComponentAttributePickMapMetdata from "./EnterpriseComponentAttributePickMapMetadata";

class EnterpriseComponentAttributeMetadata extends AbstractMetadata {
    fName: string;
    fNoCopy: boolean;
    fNoUpdate: boolean;
    fRequired: boolean;
    fRequiredForQuery: boolean;
    fPicklist: string | null;
    fScale: number | null;
    fPrecision: number | null;
    fLength: number | null;
    fMinLength: number | null;
    fPattern: string | null;
    fType: string | null;
    fDefaultValue: string | boolean | null;
    fComputed: boolean;
    fPickMaps: EnterpriseComponentAttributePickMapMetdata[]

    constructor(fName: string,
                fNoCopy: boolean,
                fNoUpdate: boolean,
                fRequired: boolean,
                fRequiredForQuery: boolean,
                fPicklist: string | null,
                fScale: number | null,
                fPrecision: number | null,
                fLength: number | null,
                fMinLength: number | null,
                fPattern: string | null,
                fType: string | null,
                fDefaultValue: string | boolean | null,
                fComputed: boolean) {
        super();

        this.fName = fName;
        this.fNoCopy = fNoCopy;
        this.fNoUpdate = fNoUpdate;
        this.fRequired = fRequired;
        this.fRequiredForQuery = fRequiredForQuery;
        this.fPicklist = fPicklist;
        this.fScale = fScale;
        this.fPrecision = fPrecision;
        this.fLength = fLength;
        this.fMinLength = fMinLength;
        this.fPattern = fPattern;
        this.fType = fType;
        this.fDefaultValue = fDefaultValue;
        this.fComputed = fComputed;
        this.fPickMaps = [];
    };

    getName() {
        return this.fName;
    };

    getNoCopy() {
        return this.fNoCopy;
    };

    getNoUpdate() {
        return this.fNoUpdate;
    };

    getRequired() {
        return this.fRequired;
    };

    getRequiredForQuery() {
        return this.fRequiredForQuery;
    };

    getPicklist() {
        return this.fPicklist;
    };

    getScale() {
        return this.fScale;
    };

    getPrecision() {
        return this.fPrecision;
    };

    getLength() {
        return this.fLength;
    };

    getMinLength() {
        return this.fMinLength;
    };

    getPattern() {
        return this.fPattern;
    };

    getType() {
        return this.fType;
    };

    getDefaultValue() {
        return this.fDefaultValue;
    };

    getComputed() {
        return this.fComputed;
    };

    getPickMaps() {
        return this.fPickMaps;
    };

}

export default EnterpriseComponentAttributeMetadata;
