import EnterpriseObjectMetadata from "../../metadata/eo/EnterpriseObjectMetadata";
import EnterpriseComponent from "../ec/EnterpriseComponent";
import Relationship from "../relationship/Relationship";
import EnterpriseObjectMemberMetadata from "../../metadata/eo/EnterpriseObjectMemberMetadata";
import enterpriseObjectMetadataClassFactory from "../../metadata/EnterpriseObjectMetadataClassFactory";
import relationshipClassFactory from "../RelationshipClassFactory";
import enterpriseComponentClassFactory from "../EnterpriseComponentClassFactory";

class EnterpriseObject {
    public enterpriseComponents: Map<string, EnterpriseComponent> = new Map();
    public relationships: Map<string, Relationship> = new Map();
    public enterpriseObjectMetadata: EnterpriseObjectMetadata = new EnterpriseObjectMetadata();
    public primaryEnterpriseComponent: EnterpriseComponent | undefined;

    constructor(name: string) {

        let metadataClass = enterpriseObjectMetadataClassFactory(name);
        // @ts-ignore
        this.enterpriseObjectMetadata = new metadataClass() as EnterpriseObjectMetadata;

        for (let key of this.enterpriseObjectMetadata.getEnterpriseComponentMembers().keys()) {
            let enterpriseComponentName = this.enterpriseObjectMetadata.getEnterpriseComponentMembers().get(key)?.getEnterpriseComponent();
            if (enterpriseComponentName) {
                let enterpriseComponentObject = enterpriseComponentClassFactory(enterpriseComponentName);
                // @ts-ignore
                let enterpriseComponent = new (enterpriseComponentObject)(enterpriseComponentName) as EnterpriseComponent;
                enterpriseComponent.setEnterpriseObject(this);
                this.enterpriseComponents.set(key, enterpriseComponent);
            }
        }
        for (let key of this.enterpriseObjectMetadata.getEnterpriseComponentMembers().keys()) {
            let enterpriseObjectMemberMetadata: EnterpriseObjectMemberMetadata | undefined = this.enterpriseObjectMetadata.getEnterpriseComponentMembers().get(key);

            if (enterpriseObjectMemberMetadata?.getRelationship() !== null) {
                let relationshipName = enterpriseObjectMemberMetadata?.getRelationship();
                if (relationshipName) {
                    let relationship = relationshipClassFactory(relationshipName);

                    let r = relationship.getParentEnterpriseComponentName();
                    let enterpriseComponent = this.getEnterpriseComponent(r);
                    relationship.setParentEnterpriseComponent(enterpriseComponent);
                    relationship.setChildEnterpriseComponent(this.getEnterpriseComponent(relationship.getChildEnterpriseComponentName()));
                    this.relationships.set(relationship.getName(), relationship);
                }
            }
        }

        this.primaryEnterpriseComponent = this.getEnterpriseComponent(this.enterpriseObjectMetadata.getPrimaryEnterpriseComponent());
    };

    getName() {
        return this.enterpriseObjectMetadata.getName();
    };

    getEnterpriseComponents() {
        return this.enterpriseComponents;
    };

    getEnterpriseComponent(name: string): EnterpriseComponent | undefined {
        for (let key of this.enterpriseComponents.keys()) {
            let ec: EnterpriseComponent | undefined = this.enterpriseComponents.get(key);
            if (key === name) {
                return ec;
            }
        }
        return undefined;
    };

    getChildEnterpriseComponents(parentEnterpriseComponent: EnterpriseComponent): Map<string, EnterpriseComponent> {
        let out: Map<string, EnterpriseComponent> = new Map();

        for (let key of this.getRelationships().keys()) {
            let relationship = this.getRelationships().get(key);

            let eom = this.getEnterpriseObjectMemberForEnterpriseComponent(parentEnterpriseComponent.getName());

            if (relationship?.getParentEnterpriseComponentName() === eom?.getName()) {
                if (relationship?.getChildEnterpriseComponentName() !== undefined) {
                    if (this.getEnterpriseComponent(relationship.getChildEnterpriseComponentName()) !== undefined) {
                        let v: EnterpriseComponent | undefined = this.getEnterpriseComponent(relationship.getChildEnterpriseComponentName())
                        if (v) {
                            // @ts-ignore
                            out.set(relationship.getChildEnterpriseComponent().getName(), v)
                        }
                    }
                }
            }
        }
        return out;
    };

    getPrimaryEnterpriseComponent(): EnterpriseComponent | undefined {
        return this.primaryEnterpriseComponent;
    };

    getRelationships(): Map<string, Relationship>{
        return this.relationships;
    };

    getRelationshipForEnterpriseComponent(ec: EnterpriseComponent): Relationship | undefined {
        if (ec === null)
            return undefined;

        for (let key of this.enterpriseObjectMetadata.enterpriseComponentMembers.keys()) {
            if (ec.getName() === this.enterpriseObjectMetadata.getEnterpriseComponentMembers().get(key)?.getEnterpriseComponent()) {
                let relationshipName: string | undefined = this.enterpriseObjectMetadata.getEnterpriseComponentMembers().get(key)?.getRelationship();
                if (relationshipName !== undefined) {
                    return this.getRelationships().get(relationshipName);
                }
                return undefined;
            }
        }

        return undefined;
    };

    getRelationship(name: string): Relationship | undefined {
        return this.relationships.get(name);
    };

    getRelationshipForChildEnterpriseComponent(ec: EnterpriseComponent) {
        for (let key of this.getRelationships().keys()) {
            let relationship: Relationship | undefined = this.getRelationships().get(key);

            if (relationship?.getChildEnterpriseComponent()?.getName() === ec.getName()) {
                return relationship;
            }
        }
        return undefined;
    };

    getRelationshipForParentEnterpriseComponent = () => {
    };

    getEnterpriseObjectMemberForEnterpriseComponent(name: string): EnterpriseObjectMemberMetadata | undefined {
        for (let key of this.enterpriseObjectMetadata.enterpriseComponentMembers.keys()) {
            let enterpriseComponentName = this.enterpriseObjectMetadata.getEnterpriseComponentMembers().get(key)?.getEnterpriseComponent();

            if (name === enterpriseComponentName) return this.enterpriseObjectMetadata.enterpriseComponentMembers.get(key);
        }
        return undefined;
    }

    isPrimaryEnterpriseComponent = (ec: EnterpriseComponent) => {
        return ec.getName() === this.primaryEnterpriseComponent?.getName();
    };
}

export default EnterpriseObject;