import React from "react";
import "../Navigation/css/NavBar.scss";
import {Black, Blue3, Grey7} from "../../theme/styles";
import part_revision_004_put_reference_request_1 from '../../json/part/part-revision-004-put-reference-request-1.json';
import part_004_put_employee_request_1 from '../../json/part/part-004-put-employee-request-1.json';
import part_004_put_attachment_request_1 from '../../json/part/part-004-put-attachment-request-1.json';
import part_revision_004_put_attachment_request_1 from '../../json/part/part-revision-004-put-attachment-request-1.json';
import part_revision_003_put_attachment_response_1 from '../../json/part/part-revision-003-put-attachment-response-1.json';
import part_revision_004_delete_attachment_request_1 from '../../json/part/part-revision-004-delete-attachment-request-1.json';
import part_004_put_group_request_1 from '../../json/part/part-004-put-group-request-1.json';
import part_002_put_group_response_1 from '../../json/part/part-002-put-group-response-1.json';
import part_002_put_employee_response_1 from '../../json/part/part-002-put-employee-response-1.json';
import part_revision_002_put_reference_response_1 from '../../json/part/part-revision-002-put-reference-response-1.json';

import part_004_delete_group_request_1 from '../../json/part/part-004-delete-group-request-1.json';
import part_004_delete_employee_request_1 from '../../json/part/part-004-delete-employee-request-1.json';
import part_004_delete_reference_request_1 from '../../json/part/part-revision-004-delete-reference-request-1.json';
import part_form_input_template_1 from '../../json/part/part-revision-form-input-template-1.json'
import part_form_input_1 from '../../json/part/part-revision-form-input-1.json'

import ReactJson from 'react-json-view'
import {Accordion, AccordionDetails, AccordionSummary, Avatar, Typography} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";

export default function PartRevisionsPut() {

    return (
        <div style={{paddingLeft: "20px", paddingRight: "30px"}}>
            <p style={{fontSize: "24px", fontWeight: "400", color: Black}}>Part Revisions PUT</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Overview</p>

            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                In Enlil, the Parts PUT API allows for the update of part content.
            </p>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Parameters</p>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>Header Parameters
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>X-Enlil-AccessToken (Required String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The X-Enlil-AccessToken is obtained during the authentication process and expires after 90 days.</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>X-Enlil-RefreshToken (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The X-Enlil-RefreshToken is obtained during the authentication process and never expires. This token is provided is the caller wants a refreshed X-Enlil-AccessToken</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>idoc-request (Required String: must equal v1.Part-004)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The name of the IDOC being specified in the PUT request.</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>idoc-response (Optional String).</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>v1.Part-001, v1.Part-002, v1.Part-003, and v1.Part-004 are permitted values.</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>If idoc-response is provided, the response payload (HTTP Code: 201) will match the specified format, else no response (HTTP Code: 204) will be provided.</li>
                </li>
            </ul>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Scenarios</p>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                <Accordion style={{backgroundColor: "#EEEEEE"}}>
                    <AccordionSummary expandIcon={<ExpandMore/>} aria-controls="panel1-content" id="panel1-header">
                        <Avatar sx={{marginTop: "2px", bgcolor: Blue3, width: 25, height: 25, fontSize: "14px", marginRight: "10px"}}>1</Avatar>
                        <Typography style={{paddingTop: "3px"}}><b>Adding/Removing an attachment to/from a Part Revision</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p style={{marginLeft: "20px"}}>
                            <span style={{lineHeight: "1.5em"}}>An attachment may be added to a Part Revision through the use of the /t3/v1/parts PUT API or the /t3/v1/part_revisions API. The following payload may be used to perform the upsert operation. The user can upload the actual attachment to Enlil by using the signed PUT url in the ShifamedMessage.ListOfPart.Part[n].ListOfPartRevision.PartRevision[n].ListOfPartRevisionAttachment.PartRevisionAttachment[n].PutURL</span>
                        </p>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={part_004_put_attachment_request_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <br/>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={part_revision_004_put_attachment_request_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <li style={{marginTop: "20px", marginLeft: "20px"}}>The following is a representative response.</li>
                        <br/>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={part_revision_003_put_attachment_response_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <li style={{marginTop: "20px", marginLeft: "20px"}}>To remove an attachment from a Part Revision execute the /t3/v1/parts DELETE API with the following payload. Attachments are not permanently deleted and may be recovered by contacting Enlil Professional Services.</li>
                        <br/>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={part_revision_004_delete_attachment_request_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <li style={{marginTop: "5px", marginLeft: "20px"}}>The delete operation requires two pieces of information which may be obtained from v1.Part-002, v1.Part-003 or v1.Part-004.</li>
                        <li style={{marginTop: "5px", marginLeft: "40px"}}>ShifamedMessage.ListOfPart.Part[n].USID</li>
                        <li style={{marginTop: "5px", marginLeft: "40px"}}>ShifamedMessage.ListOfPart.Part[n].ListOfPartRevision.PartRevision[m].USID</li>
                        <li style={{marginTop: "5px", marginLeft: "40px"}}>ShifamedMessage.ListOfPart.Part[n].ListOfPartRevision.PartRevision[m].ListOfPartRevisionAttachment.PartRevisionAttachment[p].USID</li>
                        <br/>
                        <br/>
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{backgroundColor: "#EEEEEE"}}>
                    <AccordionSummary expandIcon={<ExpandMore/>} aria-controls="panel1-content" id="panel1-header">
                        <Avatar sx={{marginTop: "2px", bgcolor: Blue3, width: 25, height: 25, fontSize: "14px", marginRight: "10px"}}>2</Avatar>
                        <Typography style={{paddingTop: "3px"}}><b>Modifying Form Input values</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <span style={{lineHeight: "1.5em"}}>Each Part created by an Enlil user may have a different form template, although typically they each use the same form template or share from a subset of a few form templates particularly intended to support the customers requirements.</span>
                        <span style={{lineHeight: "1.5em"}}>When an Enlil user creates a Part, the form template becomes a form and is used by each revision of the Part until the user decides to change the form by adding or removing form elements on the form.</span>
                        <span style={{lineHeight: "1.5em"}}>From that revision going forward, the new form will be used. To perform a put on a Part revision that modifies these form values the user of the Enlil API must know the current schema of the Part Revision record.</span>
                        <span style={{lineHeight: "1.5em"}}>The schema may be retrieved from the following path in v1.Part-002, v1.Part-003 and v1.Part-004. The value is base64 encoded and will need to be decoded. </span><br/><br/>
                        v1.Part-002/v1.Part-003/v1.Part-004: ShifamedMessage.ListOfPart.Part[n].ListOfPartRevision.PartRevision[m].FormInputTemplate<br/><br/>
                        An example of this template is provided below.
                        <p style={{fontFamily: "Courier New", fontSize: "12px"}}>
                            <ReactJson src={part_form_input_template_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <br/>
                        <span style={{lineHeight: "1.5em"}}>To obtain the current values of the form used by the Part revision, access the attribute FormInput. The value is base64 encoded and will need to be decoded. An example of this value is provide below.</span>

                        <p style={{fontFamily: "Courier New", fontSize: "12px"}}>
                            <ReactJson src={part_form_input_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <br/>
                        <span style={{lineHeight: "1.5em"}}>To change the current form values of the form used by the Part revision, post changes to the FormInputExternal attribute. The value must be base64 encoded.</span>

                        <p style={{fontFamily: "Courier New", fontSize: "12px"}}>
                            <ReactJson src={part_form_input_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <br/>
                        <span style={{lineHeight: "1.5em"}}>The following will help explain where to get the reference values required to complete the data set.</span><br/>

                        <div style={{display: "flex", flexDirection: "row", marginTop: "10px"}}>
                            <Avatar sx={{bgcolor: Grey7, width: 25, height: 25, fontSize: "14px", marginRight: "10px"}}>1</Avatar>
                            <Typography style={{paddingTop: "3px", fontSize: "14px"}}>ShifamedMessage.PartRevision.Suppliers[i].Supplier.SupplierRevisionUSID</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", marginTop: "10px", marginLeft: "35px"}}>
                            <span style={{lineHeight: "1.5em"}}>
                                To obtain this value, the user can access the /t3/v1/suppliers GET API using the IDOCs specified below. Only Suppliers in the RELEASED state may be selected.<br/>
                                v1.Supplier-002 (ShifamedMessage.ListOfSupplier.Supplier[n].ListOfSupplierRevision.SupplierRevision[k].USID)<br/>
                                v1.Supplier-003 (ShifamedMessage.ListOfSupplier.Supplier[n].ListOfSupplierRevision.SupplierRevision[k].USID)<br/>
                                v1.Supplier-004 (ShifamedMessage.ListOfSupplier.Supplier[n].ListOfSupplierRevision.SupplierRevision[k].USID)<br/><br/>

                                Also, the user may access the value by using the /t3/v1/supplier_revisions GET API using the IDOCs specified below<br/>
                                v1.SupplierRevision-001 (ShifamedMessage.ListOfSupplierRevision.SupplierRevision[n].USID)<br/>
                                v1.SupplierRevision-002 (ShifamedMessage.ListOfSupplierRevision.SupplierRevision[n].USID)<br/>
                                v1.SupplierRevision-003 (ShifamedMessage.ListOfSupplierRevision.SupplierRevision[n].USID)<br/>
                                v1.SupplierRevision-004 (ShifamedMessage.ListOfSupplierRevision.SupplierRevision[n].USID)<br/>
                            </span>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{backgroundColor: "#EEEEEE"}}>
                    <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1-content" id="panel1-header">
                        <Avatar sx={{marginTop: "2px", bgcolor: Blue3, width: 25, height: 25, fontSize: "14px", marginRight: "10px"}}>3</Avatar>
                        <Typography style={{paddingTop: "3px"}}><b>Adding/Removing a group to/from a Part</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p style={{marginLeft: "20px"}}>
                            <span style={{lineHeight: "1.5em"}}>A group may be added to a Part through the use of the /t3/v1/parts PUT API. The following payload may be used to perform the upsert operation.</span>
                        </p>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={part_004_put_group_request_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <li style={{marginTop: "20px", marginLeft: "20px"}}>The following is a representative response.</li>
                        <br/>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={part_002_put_group_response_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <li style={{marginTop: "20px", marginLeft: "20px"}}>To remove a group from a Part, execute the /t3/v1/parts DELETE API with the following payload.</li>
                        <br/>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={part_004_delete_group_request_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <li style={{marginTop: "5px", marginLeft: "20px"}}>The delete operation requires two pieces of information which may be obtained from v1.Part-002 or v1.Part-004.</li>
                        <li style={{marginTop: "5px", marginLeft: "40px"}}>ShifamedMessage.ListOfPart.Part[n].USID</li>
                        <li style={{marginTop: "5px", marginLeft: "40px"}}>ShifamedMessage.ListOfPart.Part[n].ListOfGroup.Group[m].USID</li>
                        <br/>
                        <br/>
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{backgroundColor: "#EEEEEE"}}>
                    <AccordionSummary expandIcon={<ExpandMore/>} aria-controls="panel1-content" id="panel1-header">
                        <Avatar sx={{marginTop: "2px", bgcolor: Blue3, width: 25, height: 25, fontSize: "14px", marginRight: "10px"}}>4</Avatar>
                        <Typography style={{paddingTop: "3px"}}><b>Adding/Removing an employee to/from a Part</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p style={{marginLeft: "20px"}}>
                            <span style={{lineHeight: "1.5em"}}>An employee may be added to a Part through the use of the /t3/v1/parts PUT API. The following payload may be used to perform the upsert operation.</span>
                        </p>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={part_004_put_employee_request_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <li style={{marginTop: "20px", marginLeft: "20px"}}>The following is a representative response.</li>
                        <br/>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={part_002_put_employee_response_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <li style={{marginTop: "20px", marginLeft: "20px"}}>To remove an employee from a Part, execute the /t3/v1/parts DELETE API with the following payload.</li>
                        <br/>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={part_004_delete_employee_request_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <li style={{marginTop: "5px", marginLeft: "20px"}}>The delete operation requires two pieces of information which may be obtained from v1.Part-002 or v1.Part-004.</li>
                        <li style={{marginTop: "5px", marginLeft: "40px"}}>ShifamedMessage.ListOfPart.Part[n].USID</li>
                        <li style={{marginTop: "5px", marginLeft: "40px"}}>ShifamedMessage.ListOfPart.Part[n].ListOfEmployee.Employee[m].USID</li>
                        <br/>
                        <br/>
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{backgroundColor: "#EEEEEE"}}>
                    <AccordionSummary expandIcon={<ExpandMore/>} aria-controls="panel1-content" id="panel1-header">
                        <Avatar sx={{marginTop: "2px", bgcolor: Blue3, width: 25, height: 25, fontSize: "14px", marginRight: "10px"}}>5</Avatar>
                        <Typography style={{paddingTop: "3px"}}><b>Adding/Removing a reference to/from a Part Revision</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p style={{marginLeft: "20px"}}>
                            <span style={{lineHeight: "1.5em"}}>A reference may be added to a Part Revision via this API. References added to a Part Revision must be in the RELEASED state.</span>
                        </p>
                        <p style={{marginLeft: "20px"}}>
                            <span style={{lineHeight: "1.5em"}}>A reference may be added to a Part Revision through the use of the /t3/v1/parts PUT API or the /t3/v1/part_revisions PUT API. The following payload may be used to perform the upsert operation.</span>
                        </p>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={part_revision_004_put_reference_request_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <li style={{marginTop: "20px", marginLeft: "20px"}}>The following is a representative response.</li>
                        <br/>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={part_revision_002_put_reference_response_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <li style={{marginTop: "20px", marginLeft: "20px"}}>To remove a reference from a Part, execute the /t3/v1/parts DELETE API with the following payload.</li>
                        <br/>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={part_004_delete_reference_request_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <br/>
                        <li style={{marginTop: "5px", marginLeft: "20px"}}>The delete operation requires three pieces of information which may be obtained from v1.Part-002.</li>
                        <li style={{marginTop: "5px", marginLeft: "40px"}}>ShifamedMessage.ListOfPart.Part[n].USID</li>
                        <li style={{marginTop: "5px", marginLeft: "40px"}}>ShifamedMessage.ListOfPart.Part[n].ListOfPartRevision.PartRevision[m].USID</li>
                        <li style={{marginTop: "5px", marginLeft: "40px"}}>ShifamedMessage.ListOfPart.Part[n].ListOfPartRevision.PartRevision[m].ListOfPartRevisionReference.PartRevisionReference[z].USID</li>
                        <br/>
                        <br/>
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{backgroundColor: "#EEEEEE"}}>
                    <AccordionSummary expandIcon={<ExpandMore/>} aria-controls="panel1-content" id="panel1-header">
                        <Avatar sx={{marginTop: "2px", bgcolor: Blue3, width: 25, height: 25, fontSize: "14px", marginRight: "10px"}}>6</Avatar>
                        <Typography style={{paddingTop: "3px"}}><b>Releasing a Part Revision</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p style={{marginLeft: "20px"}}>
                            <span style={{lineHeight: "1.5em"}}></span>
                        </p>
                    </AccordionDetails>
                </Accordion>
            </p>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Business Rules</p>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                    <li style={{marginTop: "20px"}}>External users may not modify the ENLIL_FORM_INPUT field."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-BXDC-NXUD</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: External users may not modify the ENLIL_FORM_INPUT field.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>External users may not modify the PRE_CALC field."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-EGDE-MDJE</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: External users may not modify the PRE_CALC field.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>Schema validation error."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-JJDU-HHDE</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Invalid value for PartRevisionUSID</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>Invalid value for PartToBeManufactured."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-MDJE-XVCX</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Invalid value for PartToBeManufactured</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>

                    <li style={{marginTop: "20px"}}>Cannot transition Part to IN_REVIEW until the required number of approvers are added."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-ODOO-PP90</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Cannot transition Part to IN_REVIEW until the required number of approvers are added.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>

                </ul>
            </p>
        </div>
    )
}