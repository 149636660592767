import PicklistMetadata from "../PicklistMetadata";

class SubscriptionStatusPicklistMetadata extends PicklistMetadata {
    constructor() {
        super();

        this.bounded = true;
        this.enterpriseComponent = "";
        this.longList = false;
        this.noDelete = true;
        this.noUpdate = true;
        this.noInsert = true;
        this.noMerge = true;
        this.useRealm = false;
        this.sortSpecification = null;
        this.static = true;
        this.typeField = "Type";
        this.typeValue = "SubscriptionStatus";
        this.typeValue2 = null;
        this.visibilityType = null;
        this.allowNoSelection = false;
        this.allowNoSelectionValue = null;
        this.allowNoSelectionDisplayValue = null;
    }
}

export default SubscriptionStatusPicklistMetadata;
