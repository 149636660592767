import {useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";
import { jwtDecode } from "jwt-decode";
import {useHandler} from "./useHandler";
import EventBus from "./EventBus";
import {pick} from "lodash";

type PickValue = {
    USID: string,
    Name: string,
    Group: string,
    Order: number,
    Type: string,
    Value: string
};

type Position = {
    USID: string,
    Name: string,
    OrganizationUSID: string
};

type UserGroup = {
    USID: string,
    Name: string
};

type WorkGroup = {
    USID: string,
    Name: string
};

type Privilege = {
    Name: string,
    USID: string
};

type View = {
    Color: string,
    Description: string,
    DisplayName: string,
    Name: string,
    USID: string,
    Visibility: string
};

type Role = {
    Name: string,
    USID: string,
    Views: View[],
    Privileges: Privilege[]
};

export type Screen = {
    DefaultViewUSID: string,
    DisplayName: string,
    Name: string,
    USID: string,
    Views:[View]
};

export type Visibility = {
    Mode: string,
    Value: string
}

export interface Company {
    Positions: [Position],
    UserGroups: [UserGroup],
    WorkGroups: [WorkGroup]
}

export class SessionContext {
    sessionUSID: string | null;
    identityToken: string | null;
    accessToken: string | null;
    accessTokenClaims: string | null;
    identityTokenClaims: string | null;
    sessionId: string;
    refreshToken: string | null;
    showLoginDialog: boolean;
    isLoggedIn: boolean;
    activeVisibility: Visibility | null;
    company: Company | null;
    screens: Screen[];
    pickValues: PickValue[];
    roles: Role[];

    name: string | null;
    email: string | null;
    companyName: string | null;
    employeeUSID: string | null;
    realm: string | null;
    userUSID: string | null;

    constructor() {
        this.sessionUSID = null;
        this.identityToken = null;
        this.accessToken = null;
        this.accessTokenClaims = null;
        this.companyName = null;
        this.employeeUSID = null;
        this.identityTokenClaims = null;
        this.refreshToken = null;
        this.name = null;
        this.email = null;
        this.realm = null;
        this.userUSID = null;
        this.showLoginDialog = true;
        this.isLoggedIn = false;
        this.activeVisibility = null;
        this.company = null;
        this.screens = [];
        this.pickValues = []
        this.roles = [];

        this.sessionId = SessionContext.loadSessionId();
    }

    static loadSessionId() {
        let sessionId: string | null = sessionStorage.getItem("sessionId");
        if (sessionId === null) {
            sessionId = uuidv4();
            sessionStorage.setItem("sessionId", sessionId);
        }
        return sessionId;
    }

    convert(type: any, value: any) {
        if (value === "null") value = null;
        if (value === null) return null;
        if (value === undefined) return undefined;
        if (typeof value === "string") {
            if (type === Boolean) return value === "true";
            if (type === Object) return JSON.parse(value);
        }
        return value;
    }

    getIdentityTokenClaims(): string | null {
        this.identityTokenClaims = sessionStorage.getItem("IdentityTokenClaims")

        return this.identityTokenClaims;
    }

    getAccessTokenClaims(): string | null {
        this.accessTokenClaims = sessionStorage.getItem("AccessTokenClaims")

        return this.accessTokenClaims;
    }

    getAccessToken(): string | null {
        this.accessToken = sessionStorage.getItem("AccessToken")

        return this.accessToken;
    }

    getCompanyName(): string | null {
        this.companyName = sessionStorage.getItem("CompanyName")

        return this.companyName;
    }

    getCompany(): Company | null {
        this.company = this.convert(Object, sessionStorage.getItem("Company"))

        return this.company;
    }

    getEmployeeUSID(): string | null {
        this.employeeUSID = sessionStorage.getItem("EmployeeUSID")

        return this.employeeUSID;
    }

    getIdentityToken(): string | null {
        this.identityToken = sessionStorage.getItem("IdentityToken")

        return this.identityToken;
    }

    getShowLoginDialog(): boolean {
        this.showLoginDialog = sessionStorage.getItem("ShowLoginDialog") === 'true'

        return this.showLoginDialog;
    }

    getActiveVisibility(): Visibility | null {
        this.activeVisibility = JSON.parse(sessionStorage.getItem("ActiveVisibility")!)

        return this.activeVisibility;
    }

    getName(): string {
        this.name = sessionStorage.getItem("Name")!

        return this.name;
    }

    getPickValues(): PickValue[] {
        this.pickValues = JSON.parse(sessionStorage.getItem("PickValues")!)

        return this.pickValues;
    }

    getRefreshToken(): string | null {
        this.refreshToken = sessionStorage.getItem("RefreshToken")

        return this.refreshToken;
    }

    getRoles(): Role[] {
        this.roles = JSON.parse(sessionStorage.getItem("Roles")!)

        return this.roles;
    }

    getScreens(): Screen[] {
        if (sessionStorage.getItem("Screens")) {
            this.screens = JSON.parse(sessionStorage.getItem("Screens")!)
        }
        return this.screens;
    }

    jwtDecode(token: string | null): any {
        if (token === null) {
            return null;
        }
        try {
            return jwtDecode(token);
        } catch (e) {
            return null;
        }
    }

    setCompanyName(companyName: string) {
        this.companyName = companyName;
        sessionStorage.setItem("CompanyName", companyName)
        EventBus.session.emit("update", {"CompanyName": this.companyName});
    }

    setAccessToken(accessToken: string) {
        this.accessToken = accessToken;
        sessionStorage.setItem("AccessToken", accessToken)

        const claims: any = this.jwtDecode(accessToken);

        this.setAccessTokenClaims(claims);
        EventBus.session.emit("update", {"AccessToken": this.accessToken});
    }

    setAccessTokenClaims(claims: string) {
        this.accessTokenClaims = claims
        sessionStorage.setItem("AccessTokenClaims", this.accessTokenClaims)
        EventBus.session.emit("update", {"AccessTokenClaims": this.accessTokenClaims});
    }

    setActiveVisibility(visibilityMode: string, visibilityValue: string) {
        this.activeVisibility = {
            Mode: visibilityMode,
            Value: visibilityValue
        }
        sessionStorage.setItem("ActiveVisibility", JSON.stringify(this.activeVisibility))
        EventBus.session.emit("update", {"ActiveVisibility": this.activeVisibility});
    }

    setCompany(company: Company) {
        this.company = company
        sessionStorage.setItem("Company", JSON.stringify(company))
        EventBus.session.emit("update", {"Company": this.company});
    }

    setEmail(email: string) {
        this.email = email
        sessionStorage.setItem("EMail", this.email)
        EventBus.session.emit("update", {"EMail": this.email});
    }

    setEmployeeUSID(employeeUSID: string) {
        this.employeeUSID = employeeUSID
        sessionStorage.setItem("EmployeeUSID", this.employeeUSID)
        EventBus.session.emit("update", {"EmployeeUSID": this.employeeUSID});
    }

    setIdentityTokenClaims(claims: string) {
        this.identityTokenClaims = claims
        sessionStorage.setItem("IdentityTokenClaims", this.identityTokenClaims)
        EventBus.session.emit("update", {"IdentityTokenClaims": this.identityTokenClaims});
    }

    setIdentityToken(identityToken: string) {
        this.identityToken = identityToken;
        sessionStorage.setItem("IdentityToken", identityToken)

        const claims: any = this.jwtDecode(identityToken);

        this.setIdentityTokenClaims(claims);
        this.setName(claims.name)
        this.setCompanyName(claims.companyName);
        this.setEmail(claims.email);
        this.setEmployeeUSID(claims.employeeUSID);
        this.setRealm(claims.realm);
        this.setUserUSID(claims.userUSID);
    }

    setName(name: string) {
        this.name = name
        sessionStorage.setItem("Name", this.name)
        EventBus.session.emit("update", {"Name": this.name});
    }

    setPickValues(pickValues: PickValue[]) {
        this.pickValues = pickValues
        sessionStorage.setItem("PickValues", JSON.stringify(pickValues))
        EventBus.session.emit("update", {"PickValues": this.pickValues});
    }

    setRealm(realm: string) {
        this.realm = realm
        sessionStorage.setItem("Realm", this.realm)
        EventBus.session.emit("update", {"Realm": this.realm});
    }

    setRefreshToken(refreshToken: string) {
        this.refreshToken = refreshToken
        sessionStorage.setItem("RefreshToken", this.refreshToken)
        EventBus.session.emit("update", {"RefreshToken": this.refreshToken});
    }

    setSessionUSID(sessionUSID: string) {
        this.sessionUSID = sessionUSID
        sessionStorage.setItem("SessionUSID", sessionUSID)
        EventBus.session.emit("update", {"SessionUSID": this.sessionUSID});
    }

    setScreens(screens: Screen[]) {
        this.screens = screens
        sessionStorage.setItem("Screens", JSON.stringify(screens))
        EventBus.session.emit("update", {"Screens": this.screens});
    }

    setRoles(roles: Role[]) {
        this.roles = roles
        sessionStorage.setItem("Roles", JSON.stringify(roles))
        EventBus.session.emit("update", {"Roles": this.roles});
    }

    setShowLoginDialog(loginDialog: boolean) {
        this.showLoginDialog = loginDialog
        sessionStorage.setItem("ShowLoginDialog", loginDialog.toString())
        EventBus.session.emit("update", {"ShowLoginDialog": this.showLoginDialog});
    }

    setUserUSID(userUSID: string) {
        this.userUSID = userUSID
        sessionStorage.setItem("UserUSID", this.userUSID)
        EventBus.session.emit("update", {"UserUSID": this.userUSID});
    }

    valid() {
        return Boolean(this.getIdentityTokenClaims() && this.getAccessTokenClaims());
    }

}


export function useSessionContext() {

    const [sessionContext, setSessionContext] = useState(new SessionContext());

    const updateListener = useHandler(
        () => setSessionContext(new SessionContext()),
        [sessionContext],
    );

    useEffect(() => {
        EventBus.session.addListener("update", updateListener);
        return () => {
            EventBus.session.removeListener("update", updateListener);
        }
    }, [updateListener]);

    return sessionContext;
}

