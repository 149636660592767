import {Grid, MenuItem, TextField} from "@mui/material";
import React, {Component} from "react";
import {v4 as uuidv4} from "uuid";
import {withAppContext} from "./withAppContext";
import spinner from "../assets/Spinner-1s-200px.svg";
import {prepareBuffer} from "../util/Buffer";
import enterpriseComponentClassFactory from "../eoec/EnterpriseComponentClassFactory";

class EnlilSelect extends Component {

    constructor(props) {
        super(props);
        this.pickValues = props.appContext.sessionContext.getPickValues();
    }

    state = {
        id: uuidv4(),
        fetchBackgroundOperation: false,
        ec: null,
        buffer: [],
    };

    componentDidMount() {
        if (this.props.picklist !== null && this.props.picklist.getStatic() === false) {
            let enterpriseComponentObject = enterpriseComponentClassFactory(this.props.picklist.getEnterpriseComponent());

            let picklistEC = new (enterpriseComponentObject)(this.props.picklist.getEnterpriseComponent());

            this.setState({ec: picklistEC});

            let searchSpecification = this.props.picklist.getSearchSpecification();
            if (searchSpecification !== null) {
                let keys = Object.keys(searchSpecification);
                for (let i = 0; i < keys.length; i++) {
                    let ss = searchSpecification[keys[i]];
                    // eslint-disable-next-line no-template-curly-in-string
                    if (ss.includes("${sessionContext:realm}")) {
                        searchSpecification[keys[i]] = this.props.appContext.sessionContext.realm;
                    }
                }
            }
            picklistEC.addLoadStartedListener(this);
            picklistEC.addLoadCompletedListener(this);
            picklistEC.newQuery();
            picklistEC.setSearchSpecification(searchSpecification);
            picklistEC.setSortSpecification(this.props.picklist.getSortSpecification());
            picklistEC.executeQuery();
        }
    }

    enterpriseComponentLoadStarted = (_) => {
        this.setState({fetchBackgroundOperation: true});
    };

    enterpriseComponentLoadCompleted = (ec) => {
        this.setState({buffer: prepareBuffer(ec), fetchBackgroundOperation: false});
    };

    getId() {
        if (this.props.id !== undefined && this.props.id !== null && this.props.id !== "") {
            return this.props.id;
        } else {
            return this.state.id;
        }
    }

    render() {
        let renderSpinner = false;

        let content;

        let selectOptions = [];

        if (this.props.picklist.getAllowNoSelection() === true) {
            let option = {
                value: this.props.picklist.getAllowNoSelectionValue(),
                displayValue: this.props.picklist.getAllowNoSelectionDisplayValue(),
            };
            selectOptions.push(option);
        }

        if (this.props.picklist.getStatic() === true) {
            for (let i = 0; i < this.pickValues.length; i++) {
                let pickInstance = this.pickValues[i];

                let pickTypeField = this.props.picklist.getTypeField();
                if (pickInstance[pickTypeField] !== this.props.picklist.getTypeValue()) continue;

                let option = {
                    value: pickInstance["Value"],
                    displayValue: pickInstance["Name"],
                };
                selectOptions.push(option);
            }
        } else if (this.props.picklist.getStatic() === false && this.state.fetchBackgroundOperation === true) {
            renderSpinner = true;
        } else if (this.props.picklist.getStatic() === false && this.state.fetchBackgroundOperation === false) {
            for (let i = 0; i < this.state.buffer.length; i++) {
                let pickTypeField = this.props.picklist.getTypeField();
                let pickValueField = this.props.picklist.getTypeValue();

                let option = {
                    value: this.state.buffer[i].get(pickValueField),
                    displayValue: this.state.buffer[i].get(pickTypeField),
                };
                selectOptions.push(option);
            }
        }

        if (renderSpinner === true) {
            content = (
                <Grid item style={{marginBottom: "1.4em"}}>
                    <img style={{position: "relative", top: "10px", left: "0px"}} width="35" height="35" src={spinner} alt="spinner" />
                </Grid>
            );
        } else {
            const {appContext, defaultOptions, ...rest} = this.props;
            content = (
                <TextField
                    select={true}
                    SelectProps={{
                        MenuProps: {
                            PaperProps: {
                                elevation: 2,
                            },
                        },
                    }}
                    {...rest}
                >
                    {selectOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.displayValue}
                        </MenuItem>
                    ))}
                </TextField>
            );
        }

        return (
            <React.Fragment>
                {content}
            </React.Fragment>
        );
    }
}

export default withAppContext(EnlilSelect);