import DeveloperApplicationDeveloperCredentialRelationshipMetadata from "./relationship/impl/RestManagement/DeveloperApplicationDeveloperCredentialRelationshipMetadata";
import DeveloperCredentialDeveloperCredentialAuthorizationRelationshipMetadata from "./relationship/impl/RestManagement/DeveloperCredentialDeveloperCredentialAuthorizationRelationshipMetadata";
import DeveloperCredentialAuthorizationDeveloperCredentialAuthorizationDetailRelationshipMetadata from "./relationship/impl/RestManagement/DeveloperCredentialAuthorizationDeveloperCredentialAuthorizationDetailRelationshipMetadata";
import DeveloperEndpointDeveloperEndpointConfigurationRelationshipMetadata from "./relationship/impl/EndpointManagement/DeveloperEndpointDeveloperEndpointConfigurationRelationshipMetadata";
import DeveloperEventDeveloperEventConfigurationRelationshipMetadata from "./relationship/impl/EventManagement/DeveloperEventDeveloperEventConfigurationRelationshipMetadata";
import BillConfigurationBillSubscriptionRelationshipMetadata from "./relationship/impl/BillConfiguration/BillConfigurationBillSubscriptionRelationshipMetadata";
import BillSubscriptionBillSubscriptionCommunicationRelationshipMetadata from "./relationship/impl/BillConfiguration/BillSubscriptionBillSubscriptionCommunicationRelationshipMetadata";

const relationshipMetadataClasses: Map<string, object> = new Map();

relationshipMetadataClasses.set("DeveloperApplicationDeveloperCredentialRelationshipMetadata", DeveloperApplicationDeveloperCredentialRelationshipMetadata);
relationshipMetadataClasses.set("DeveloperCredentialDeveloperCredentialAuthorizationRelationshipMetadata", DeveloperCredentialDeveloperCredentialAuthorizationRelationshipMetadata);
relationshipMetadataClasses.set("DeveloperCredentialAuthorizationDeveloperCredentialAuthorizationDetailRelationshipMetadata", DeveloperCredentialAuthorizationDeveloperCredentialAuthorizationDetailRelationshipMetadata);
relationshipMetadataClasses.set("DeveloperEndpointDeveloperEndpointConfigurationRelationshipMetadata", DeveloperEndpointDeveloperEndpointConfigurationRelationshipMetadata);
relationshipMetadataClasses.set("DeveloperEventDeveloperEventConfigurationRelationshipMetadata", DeveloperEventDeveloperEventConfigurationRelationshipMetadata);
relationshipMetadataClasses.set("BillConfigurationBillSubscriptionRelationshipMetadata", BillConfigurationBillSubscriptionRelationshipMetadata);
relationshipMetadataClasses.set("BillSubscriptionBillSubscriptionCommunicationRelationshipMetadata", BillSubscriptionBillSubscriptionCommunicationRelationshipMetadata);

export default function RelationshipMetadataClassFactory(name: string): object | undefined{
    return relationshipMetadataClasses.get(name + "RelationshipMetadata");
}