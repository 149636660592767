import {Button, ButtonProps} from "@mui/material";
import {
    Grey3
} from "../../theme/styles";
import CCPulse from "./CCPulse";
import css_self from "./css/CCButton.module.scss";

const commonCSS = {
    fontWeight: 400,
    fontSize: "14px",
    borderRadius: "100px",
    minWidth: {xs: "auto", sm: "136px"},
    "&:disabled": {
        color: Grey3,
        cursor: "not-allowed",
        pointerEvents: "all",
    },
}

type CCButtonLocalProps = {
    animate?: boolean,
    color?: "primary" | "secondary" | "red" | "red-outline" | "yellow" | "green" | "blue-outline"
}

type CCButtonProps = Omit<ButtonProps, "color"> & CCButtonLocalProps

export default function CCButton({
    color,
    variant,
    animate,
    children,
    onClick,
    ...props
}: CCButtonProps) {
    let rest = props;

    if (animate) onClick = () => null;

    return (
        <Button
            {...{"data-cc-component": "CCButton"}}
            {...{"data-animate": animate}}
            variant={variant}
            onClick={animate ? undefined : onClick}
            sx={{...commonCSS}}
            {...rest}
        >
            <span style={animate ? {visibility: "hidden"} : undefined}>{children}</span>
            {animate ? <CCPulse className={css_self.Pulse} DotProps={undefined} /> : undefined}
        </Button>
    );
}
