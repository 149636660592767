export const V1DeveloperApplicationIDOCToArray = (input: any) => {
    let output: any = [];

    if (input["ShifamedMessage"]["ListOfDeveloperApplication"] !== undefined) {
        if (input["ShifamedMessage"]["ListOfDeveloperApplication"].Count === undefined) {
            output["Count"] = 0;
        } else {
            output["Count"] = input["ShifamedMessage"]["ListOfDeveloperApplication"].Count;
        }

        let results = [];

        if ("DeveloperApplication" in input["ShifamedMessage"]["ListOfDeveloperApplication"]) {
            for (let i = 0; i < input["ShifamedMessage"]["ListOfDeveloperApplication"]["DeveloperApplication"].length; i++) {
                let developerApplication = input["ShifamedMessage"]["ListOfDeveloperApplication"]["DeveloperApplication"][i];

                let row: any = {};

                row.RowSelected = false;
                row.USID = developerApplication.USID;
                row.Name = developerApplication.Name;
                row.Status = null;
                row.Environment = developerApplication.Environment;
                row.EmployeeUSID = developerApplication.EmployeeUSID;
                row.UserUSID = developerApplication.UserUSID;
                row.EmployeeEmail = developerApplication.EmployeeEmail;
                row.EmployeeName = developerApplication.EmployeeName;
                row.Active = developerApplication.Active;
                row.DeactivationDatetime = developerApplication.DeactivationDatetime;
                row.CreatedDatetime = developerApplication.CreatedDatetime;
                row.LastModifiedDatetime = developerApplication.LastModifiedDatetime;
                row.Action = null;

                row["__operation"] = "query results";

                results.push(row);
            }
        }

        output["Results"] = results;
    }
    return output;
};

export const V1DeveloperCredentialIDOCToArray = (input: any) => {
    let output: any = [];

    if (input["ShifamedMessage"]["ListOfDeveloperCredential"] !== undefined) {
        if (input["ShifamedMessage"]["ListOfDeveloperCredential"].Count === undefined) {
            output["Count"] = 0;
        } else {
            output["Count"] = input["ShifamedMessage"]["ListOfDeveloperCredential"].Count;
        }

        let results = [];

        if ("DeveloperCredential" in input["ShifamedMessage"]["ListOfDeveloperCredential"]) {
            for (let i = 0; i < input["ShifamedMessage"]["ListOfDeveloperCredential"]["DeveloperCredential"].length; i++) {
                let developerCredential = input["ShifamedMessage"]["ListOfDeveloperCredential"]["DeveloperCredential"][i];

                let row: any = {};

                row.RowSelected = false;
                row.USID = developerCredential.USID;
                row.ApplicationUSID = developerCredential.ApplicationUSID;
                row.ApplicationName = developerCredential.ApplicationName;
                row.APIKey = developerCredential.APIKey;
                row.SecretKey = developerCredential.SecretKey;
                row.Active = developerCredential.Active;
                row.DeactivationDatetime = developerCredential.DeactivationDatetime;
                row.Status = null;
                row.CreatedDatetime = developerCredential.CreatedDatetime;
                row.LastModifiedDatetime = developerCredential.LastModifiedDatetime;
                row.Action = null;

                row["__operation"] = "query results";

                results.push(row);
            }
        }

        output["Results"] = results;
    }
    return output;
}

export const V1DeveloperCredentialAuthorizationIDOCToArray = (input: any) => {
    let output: any = [];

    if (input["ShifamedMessage"]["ListOfDeveloperCredentialAuthorization"] !== undefined) {
        if (input["ShifamedMessage"]["ListOfDeveloperCredentialAuthorization"].Count === undefined) {
            output["Count"] = 0;
        } else {
            output["Count"] = input["ShifamedMessage"]["ListOfDeveloperCredentialAuthorization"].Count;
        }

        let results = [];

        if ("DeveloperCredentialAuthorization" in input["ShifamedMessage"]["ListOfDeveloperCredentialAuthorization"]) {
            for (let i = 0; i < input["ShifamedMessage"]["ListOfDeveloperCredentialAuthorization"]["DeveloperCredentialAuthorization"].length; i++) {
                let developerCredentialAuthorization = input["ShifamedMessage"]["ListOfDeveloperCredentialAuthorization"]["DeveloperCredentialAuthorization"][i];

                let row: any = {};

                row.RowSelected = false;
                row.USID = developerCredentialAuthorization.USID;
                row.CredentialUSID = developerCredentialAuthorization.CredentialUSID;
                row.Entity = developerCredentialAuthorization.Entity;
                row.ReadPermission = developerCredentialAuthorization.ReadPermission;
                row.CreatePermission = developerCredentialAuthorization.CreatePermission;
                row.UpdatePermission = developerCredentialAuthorization.UpdatePermission;
                row.DeletePermission = developerCredentialAuthorization.DeletePermission;
                row.CreatedDatetime = developerCredentialAuthorization.CreatedDatetime;
                row.LastModifiedDatetime = developerCredentialAuthorization.LastModifiedDatetime;

                row["__operation"] = "query results";

                results.push(row);
            }
        }

        output["Results"] = results;
    }
    return output;
}

export const V1DeveloperCredentialAuthorizationDetailIDOCToArray = (input: any) => {
    let output: any = [];

    if (input["ShifamedMessage"]["ListOfDeveloperCredentialAuthorizationDetail"] !== undefined) {
        if (input["ShifamedMessage"]["ListOfDeveloperCredentialAuthorizationDetail"].Count === undefined) {
            output["Count"] = 0;
        } else {
            output["Count"] = input["ShifamedMessage"]["ListOfDeveloperCredentialAuthorizationDetail"].Count;
        }

        let results = [];

        if ("DeveloperCredentialAuthorizationDetail" in input["ShifamedMessage"]["ListOfDeveloperCredentialAuthorizationDetail"]) {
            for (let i = 0; i < input["ShifamedMessage"]["ListOfDeveloperCredentialAuthorizationDetail"]["DeveloperCredentialAuthorizationDetail"].length; i++) {
                let developerCredentialAuthorizationDetail = input["ShifamedMessage"]["ListOfDeveloperCredentialAuthorizationDetail"]["DeveloperCredentialAuthorizationDetail"][i];

                let row: any = {};

                row.RowSelected = false;
                row.USID = developerCredentialAuthorizationDetail.USID;
                row.AuthorizationUSID = developerCredentialAuthorizationDetail.AuthorizationUSID;
                row.IDOC = developerCredentialAuthorizationDetail.IDOC;
                row.Active = developerCredentialAuthorizationDetail.Active;
                row.CreatedDatetime = developerCredentialAuthorizationDetail.CreatedDatetime;
                row.LastModifiedDatetime = developerCredentialAuthorizationDetail.LastModifiedDatetime;

                row["__operation"] = "query results";

                results.push(row);
            }
        }

        output["Results"] = results;
    }
    return output;
}

export const V1DeveloperEndpointIDOCToArray = (input: any) => {
    let output: any = [];

    if (input["ShifamedMessage"]["ListOfDeveloperEndpoint"] !== undefined) {
        if (input["ShifamedMessage"]["ListOfDeveloperEndpoint"].Count === undefined) {
            output["Count"] = 0;
        } else {
            output["Count"] = input["ShifamedMessage"]["ListOfDeveloperEndpoint"].Count;
        }

        let results = [];

        if ("DeveloperEndpoint" in input["ShifamedMessage"]["ListOfDeveloperEndpoint"]) {
            for (let i = 0; i < input["ShifamedMessage"]["ListOfDeveloperEndpoint"]["DeveloperEndpoint"].length; i++) {
                let developerEndpoint = input["ShifamedMessage"]["ListOfDeveloperEndpoint"]["DeveloperEndpoint"][i];

                let row: any = {};

                row.RowSelected = false;
                row.USID = developerEndpoint.USID;
                row.Name = developerEndpoint.Name;
                row.Protocol = developerEndpoint.Protocol;
                row.CreatedDatetime = developerEndpoint.CreatedDatetime;
                row.LastModifiedDatetime = developerEndpoint.LastModifiedDatetime;

                row["__operation"] = "query results";

                results.push(row);
            }
        }

        output["Results"] = results;
    }
    return output;
}

export const V1DeveloperEndpointConfigurationIDOCToArray = (input: any) => {
    let output: any = [];

    if (input["ShifamedMessage"]["ListOfDeveloperEndpointConfiguration"] !== undefined) {
        if (input["ShifamedMessage"]["ListOfDeveloperEndpointConfiguration"].Count === undefined) {
            output["Count"] = 0;
        } else {
            output["Count"] = input["ShifamedMessage"]["ListOfDeveloperEndpointConfiguration"].Count;
        }

        let results = [];

        if ("DeveloperEndpointConfiguration" in input["ShifamedMessage"]["ListOfDeveloperEndpointConfiguration"]) {
            for (let i = 0; i < input["ShifamedMessage"]["ListOfDeveloperEndpointConfiguration"]["DeveloperEndpointConfiguration"].length; i++) {
                let developerEndpointConfiguration = input["ShifamedMessage"]["ListOfDeveloperEndpointConfiguration"]["DeveloperEndpointConfiguration"][i];

                let row: any = {};

                row.RowSelected = false;
                row.USID = developerEndpointConfiguration.USID;
                row.DeveloperEndpointUSID = developerEndpointConfiguration.DeveloperEndpointUSID;
                row.Protocol = developerEndpointConfiguration.Protocol;
                row.ConfigurationName = developerEndpointConfiguration.ConfigurationName;
                row.ConfigurationValue = developerEndpointConfiguration.ConfigurationValue;
                row.CreatedDatetime = developerEndpointConfiguration.CreatedDatetime;
                row.LastModifiedDatetime = developerEndpointConfiguration.LastModifiedDatetime;

                row["__operation"] = "query results";

                results.push(row);
            }
        }

        output["Results"] = results;
    }
    return output;
}

export const V1DeveloperEventConfigurationIDOCToArray = (input: any) => {
    let output: any = [];

    if (input["ShifamedMessage"]["ListOfDeveloperEventConfiguration"] !== undefined) {
        if (input["ShifamedMessage"]["ListOfDeveloperEventConfiguration"].Count === undefined) {
            output["Count"] = 0;
        } else {
            output["Count"] = input["ShifamedMessage"]["ListOfDeveloperEventConfiguration"].Count;
        }

        let results = [];

        if ("DeveloperEventConfiguration" in input["ShifamedMessage"]["ListOfDeveloperEventConfiguration"]) {
            for (let i = 0; i < input["ShifamedMessage"]["ListOfDeveloperEventConfiguration"]["DeveloperEventConfiguration"].length; i++) {
                let developerEventConfiguration = input["ShifamedMessage"]["ListOfDeveloperEventConfiguration"]["DeveloperEventConfiguration"][i];

                let row: any = {};

                row.RowSelected = false;
                row.USID = developerEventConfiguration.USID;
                row.DeveloperEventUSID = developerEventConfiguration.DeveloperEventUSID;
                row.ConfigurationName = developerEventConfiguration.ConfigurationName;
                row.ConfigurationValue = developerEventConfiguration.ConfigurationValue;
                row.CreatedDatetime = developerEventConfiguration.CreatedDatetime;
                row.LastModifiedDatetime = developerEventConfiguration.LastModifiedDatetime;

                row["__operation"] = "query results";

                results.push(row);
            }
        }

        output["Results"] = results;
    }
    return output;
}

export const V1DeveloperEventIDOCToArray = (input: any) => {
    let output: any = [];

    if (input["ShifamedMessage"]["ListOfDeveloperEvent"] !== undefined) {
        if (input["ShifamedMessage"]["ListOfDeveloperEvent"].Count === undefined) {
            output["Count"] = 0;
        } else {
            output["Count"] = input["ShifamedMessage"]["ListOfDeveloperEvent"].Count;
        }

        let results = [];

        if ("DeveloperEvent" in input["ShifamedMessage"]["ListOfDeveloperEvent"]) {
            for (let i = 0; i < input["ShifamedMessage"]["ListOfDeveloperEvent"]["DeveloperEvent"].length; i++) {
                let developerEvent = input["ShifamedMessage"]["ListOfDeveloperEvent"]["DeveloperEvent"][i];

                let row: any = {};

                row.RowSelected = false;
                row.USID = developerEvent.USID;
                row.DeveloperEndpointUSID = developerEvent.DeveloperEndpointUSID;
                row.EndpointName = developerEvent.EndpointName;
                row.EventType = developerEvent.EventType;
                row.CreatedDatetime = developerEvent.CreatedDatetime;
                row.LastModifiedDatetime = developerEvent.LastModifiedDatetime;

                row["__operation"] = "query results";

                results.push(row);
            }
        }

        output["Results"] = results;
    }
    return output;
}

export const V1EmployeeIDOCToArray = (input: any) => {
    let output: any = [];

    if (input["ShifamedMessage"]["ListOfEmployee"] !== undefined) {
        if (input["ShifamedMessage"]["ListOfEmployee"].Count === undefined) {
            output["Count"] = 0;
        } else {
            output["Count"] = input["ShifamedMessage"]["ListOfEmployee"].Count;
        }

        let results = [];

        if ("Employee" in input["ShifamedMessage"]["ListOfEmployee"]) {
            for (let i = 0; i < input["ShifamedMessage"]["ListOfEmployee"]["Employee"].length; i++) {
                let employee = input["ShifamedMessage"]["ListOfEmployee"]["Employee"][i];

                if (employee.Active === false || employee.Locked === true) {
                    continue
                }

                let row: any = {};

                row.RowSelected = false;
                row.USID = employee.USID;
                row.UserUSID = employee.UserUSID;
                row.Email = employee.Email;
                row.UserName = employee.UserName;
                row.Confirmed = employee.Confirmed;
                row.Locked = employee.Locked;
                row.Active = employee.Active;
                row.SlackUserID = employee.SlackUserID;
                row.CreatedDatetime = employee.CreatedDatetime;
                row.LastModifiedDatetime = employee.LastModifiedDatetime;
                row.Action = null;

                row["__operation"] = "query results";

                results.push(row);
            }
        }

        output["Results"] = results;
    }
    return output;
};

export const V1DeveloperExceptionIDOCToArray = (input: any) => {
    let output: any = [];

    if (input["ShifamedMessage"]["ListOfDeveloperException"] !== undefined) {
        if (input["ShifamedMessage"]["ListOfDeveloperException"].Count === undefined) {
            output["Count"] = 0;
        } else {
            output["Count"] = input["ShifamedMessage"]["ListOfDeveloperException"].Count;
        }

        let results = [];
        if ("DeveloperException" in input["ShifamedMessage"]["ListOfDeveloperException"]) {
            for (let i = 0; i < input["ShifamedMessage"]["ListOfDeveloperException"]["DeveloperException"].length; i++) {
                let developerException = input["ShifamedMessage"]["ListOfDeveloperException"]["DeveloperException"][i];

                let row: any = {};

                let unordered = JSON.parse(atob(developerException.Payload));
                const ordered = Object.keys(unordered).sort().reduce(
                    (obj: any, key: string) => {
                        obj[key] = unordered[key];
                        return obj;
                    },
                    {}
                );

                row.RowSelected = false;
                row.USID = developerException.USID;
                row.ExceptionCode = developerException.EventID;
                row.ExceptionDatetime = developerException.CreatedDatetime;
                row.ExceptionType = developerException.EventType;
                row.ExceptionPayload = ordered
                row.CreatedDatetime = developerException.CreatedDatetime;
                row.LastModifiedDatetime = developerException.LastModifiedDatetime;
                row.Action = null;

                row["__operation"] = "query results";

                results.push(row);
            }
        }

        output["Results"] = results;
    }
    return output;
};

export const V1DeveloperLogIDOCToArray = (input: any) => {
    let output: any = [];

    if (input["ShifamedMessage"]["ListOfDeveloperLog"] !== undefined) {
        if (input["ShifamedMessage"]["ListOfDeveloperLog"].Count === undefined) {
            output["Count"] = 0;
        } else {
            output["Count"] = input["ShifamedMessage"]["ListOfDeveloperLog"].Count;
        }

        let results = [];

        if ("DeveloperLog" in input["ShifamedMessage"]["ListOfDeveloperLog"]) {
            for (let i = 0; i < input["ShifamedMessage"]["ListOfDeveloperLog"]["DeveloperLog"].length; i++) {
                let developerLog = input["ShifamedMessage"]["ListOfDeveloperLog"]["DeveloperLog"][i];

                let row: any = {};

                row.RowSelected = false;
                row.USID = developerLog.USID;
                row.EventCode = developerLog.EventID;
                row.EventDatetime = developerLog.CreatedDatetime;
                row.EventType = developerLog.EventType;
                row.EventPayload = developerLog.Payload;
                row.EventPayloadJSON = JSON.parse(atob(developerLog.Payload));
                row.Action = null;

                row["__operation"] = "query results";

                results.push(row);
            }
        }

        output["Results"] = results;
    }
    return output;
};

export const V1BillConfigurationIDOCToArray = (input: any) => {
    let output: any = [];

    if (input["ShifamedMessage"]["ListOfBillConfiguration"] !== undefined) {
        if (input["ShifamedMessage"]["ListOfBillConfiguration"].Count === undefined) {
            output["Count"] = 0;
        } else {
            output["Count"] = input["ShifamedMessage"]["ListOfBillConfiguration"].Count;
        }

        let results = [];

        if ("BillConfiguration" in input["ShifamedMessage"]["ListOfBillConfiguration"]) {
            for (let i = 0; i < input["ShifamedMessage"]["ListOfBillConfiguration"]["BillConfiguration"].length; i++) {
                let billConfiguration = input["ShifamedMessage"]["ListOfBillConfiguration"]["BillConfiguration"][i];

                let row: any = {};

                row.RowSelected = false;
                row.USID = billConfiguration.USID;
                row.UserName = billConfiguration.UserName;
                row.Password = billConfiguration.Password;
                row.V2APIUrl = billConfiguration.V2APIUrl;
                row.V3APIUrl = billConfiguration.V3APIUrl;
                row.DeveloperKey = billConfiguration.DeveloperKey;
                row.OrganizationID = billConfiguration.OrganizationID;
                row.CreatedDatetime = billConfiguration.CreatedDatetime;
                row.LastModifiedDatetime = billConfiguration.LastModifiedDatetime;

                row["__operation"] = "query results";

                results.push(row);
            }
        }

        output["Results"] = results;
    }
    return output;
};

export const V1BillSubscriptionIDOCToArray = (input: any) => {
    let output: any = [];

    if (input.ShifamedMessage["ListOfBillConfiguration"] !== undefined) {
        if (input.ShifamedMessage["ListOfBillConfiguration"].Count === undefined) {
            output["Count"] = 0;
        } else {
            output["Count"] = input.ShifamedMessage["ListOfBillConfiguration"].Count;
        }

        let results: any[] = [];

        if ("BillConfiguration" in input.ShifamedMessage["ListOfBillConfiguration"]) {
            for (let i = 0; i < input.ShifamedMessage["ListOfBillConfiguration"]["BillConfiguration"].length; i++) {
                let billConfiguration = input.ShifamedMessage["ListOfBillConfiguration"]["BillConfiguration"][i];

                for (let j = 0; j < billConfiguration["ListOfBillSubscription"]["BillSubscription"].length; j++) {
                    let billSubscription: any = billConfiguration["ListOfBillSubscription"]["BillSubscription"][j];

                    let row: any = {};

                    row.RowSelected = false;
                    row.USID = billSubscription.USID;
                    row.Name = billSubscription.Name;
                    row.BillConfigurationUSID = billSubscription.BillConfigurationUSID;
                    row.Status = billSubscription.Status;
                    row.EventType = billSubscription.EventType;
                    row.EventVersion = billSubscription.EventVersion;
                    row.SubscriptionID = billSubscription.SubscriptionID;
                    row.SecurityKey = billSubscription.SecurityKey;
                    row.CreatedDatetime = billSubscription.CreatedDatetime;
                    row.LastModifiedDatetime = billSubscription.LastModifiedDatetime;
                    row.Action = null;

                    row["__operation"] = "query results";

                    results.push(row);
                }
                if (billConfiguration["ListOfBillSubscription"].Count === undefined) {
                    output["Count"] = 0;
                } else {
                    output["Count"] = billConfiguration["ListOfBillSubscription"].Count;
                }
            }
        }
        output["Results"] = results;
    }
    return output;
};

export const V1BillSubscriptionCommunicationIDOCToArray = (input: any) => {
    let output: any = [];

    if (input.ShifamedMessage["ListOfBillConfiguration"] !== undefined) {
        if (input.ShifamedMessage["ListOfBillConfiguration"].Count === undefined) {
            output["Count"] = 0;
        } else {
            output["Count"] = input.ShifamedMessage["ListOfBillConfiguration"].Count;
        }

        let results: any[] = [];

        if ("BillConfiguration" in input.ShifamedMessage["ListOfBillConfiguration"]) {
            for (let i = 0; i < input.ShifamedMessage["ListOfBillConfiguration"]["BillConfiguration"].length; i++) {
                let billConfiguration = input.ShifamedMessage["ListOfBillConfiguration"]["BillConfiguration"][i];

                for (let j = 0; j < billConfiguration["ListOfBillSubscription"]["BillSubscription"].length; j++) {
                    let billSubscription: any = billConfiguration["ListOfBillSubscription"]["BillSubscription"][j];

                    for (let k = 0; k < billSubscription["ListOfBillSubscriptionCommunication"]["BillSubscriptionCommunication"].length; k++) {
                        let billSubscriptionCommunication = input.ShifamedMessage["BillSubscriptionCommunication"]["BillSubscriptionCommunication"][i];

                        let row: any = {};

                        row.RowSelected = false;
                        row.USID = billSubscriptionCommunication.USID;
                        row.Payload = billSubscriptionCommunication.Name;
                        row.BillSubscriptionUSID = billSubscriptionCommunication.BillSubscriptionUSID;
                        row.CreatedDatetime = billSubscriptionCommunication.CreatedDatetime;
                        row.LastModifiedDatetime = billSubscriptionCommunication.LastModifiedDatetime;
                        row.Action = null;

                        row["__operation"] = "query results";

                        results.push(row);
                    }
                    if (billSubscription["ListOfBillSubscriptionCommunication"].Count === undefined) {
                        output["Count"] = 0;
                    } else {
                        output["Count"] = billSubscription["ListOfBillSubscriptionCommunication"].Count;
                    }
                }
            }
        }
        output["Results"] = results;
    }
    return output;
};
