import {withStyles} from "@mui/styles";
import styles from "../../theme/styles";

import {EnlilTable} from "../EnlilTable";
import {withAppContext} from "../withAppContext";
import withNavigate from "../../util/useNavigate";
import withBreakpoint from "../../util/withBreakpoint";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import CCButton from "../Common/CCButton";
import ReactJson from "react-json-view";
import React from "react";
import {formatLocal} from "../../util/Formatters";

class DeveloperLogTable extends EnlilTable {

    onRowDoubleClick(params) {
        this.applet.getEnterpriseComponent().selectRecord(params.row.id);

        this.setState({renderTarget: "Show Event"});
    };

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getCellRenderer(name) {
        return super.getCellRenderer(name);
    };

    handleMenuClick(identifier) {
        super.handleMenuClick(identifier);
    }

    renderJSONDialog() {
        return (
            <Dialog onClose={(event, reason) => this.setState({renderTarget: null})} open={true} scroll="body" maxWidth={"xl"} maxHeight={"xl"}>
                <DialogTitle>Event</DialogTitle>
                <DialogActions>
                    <CCButton color="primary" onClick={() => this.setState({renderTarget: null})}>
                        Close
                    </CCButton>
                </DialogActions>
                <DialogContent>
                    <p style={{fontFamily: "Courier New", fontSize: "12px"}}>
                        <ReactJson keySort={true} src={this.applet.getEnterpriseComponent().getAttributeValue("EventPayloadJSON")} collapseStringsAfterLength={175} collapsed={10}/>
                    </p>
                </DialogContent>
                <DialogActions>
                    <CCButton color="primary" onClick={() => this.setState({renderTarget: null})}>
                        Close
                    </CCButton>
                </DialogActions>
            </Dialog>
        )
    }

    renderExtension() {
        if (this.state.renderTarget === "Show Event") {
            return this.renderJSONDialog()
        }
    }
}

export default withNavigate(withAppContext(withStyles(styles, {withTheme: true})(withBreakpoint(DeveloperLogTable))));