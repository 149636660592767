import React from "react";
import {Box, IconButton, Popover, Typography} from "@mui/material";
import {Api, Apps, CellTower, ErrorOutline, ManageAccounts, MonitorHeart, Notes, ViewInAr} from "@mui/icons-material";
import {Blue3} from "../theme/styles";
import css_self from "./css/Pages.module.scss";
import bill_logo from "../assets/bill-logo.png";
import css_buttons from "../components/Common/css/CCButton.module.scss";
import useBreakpoint from "../util/useBreakpoint";
import {useNavigate} from "react-router-dom";

type MenuSelection = {id: string, target: EventTarget & HTMLButtonElement}

export default function Dashboard() {
    const breakpoint = useBreakpoint();
    const navigate = useNavigate();
    const [menu, setMenu] = React.useState<MenuSelection | null>(null);

    function renderDashboard() {
        let dashboard = null;
        if (breakpoint.getBreakPointName() === "xl") {
            dashboard = (
                <>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", height: "200px", marginTop: "20px"}}>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/rest-api-management");
                            }}>
                                <ManageAccounts sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>REST API Management</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/endpoint-management");
                            }}>
                                <Api sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Endpoint Management</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/event-management");
                            }}>
                                <CellTower sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Event Management</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/monitoring");
                            }}>
                                <MonitorHeart sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Monitoring</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/exception-management");
                            }}>
                                <ErrorOutline sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Exception Management</Typography>
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "200px", marginTop: "100px"}}>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", width: "100px", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/sandbox-management");
                            }}>
                                <ViewInAr sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Sandbox Management</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", width: "100px", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/log-management");
                            }}>
                                <Notes sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Log Management</Typography>
                        </div>
                       <div style={{display: "flex", flexDirection: "column", justifyContent: "center", width: "100px", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/bill-configuration");
                            }}>
                                <img alt="bill logo" src={bill_logo} style={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Bill.com Configuration</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", width: "100px", marginLeft: "auto", marginRight: "auto"}}>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", width: "100px", marginLeft: "auto", marginRight: "auto"}}>
                        </div>
                    </div>
                </>
            )
        }
        if (breakpoint.getBreakPointName() === "lg") {
            dashboard = (
                <>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", height: "200px", marginTop: "20px"}}>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/rest-api-management");
                            }}>
                                <ManageAccounts sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>REST API Management</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/endpoint-management");
                            }}>
                                <Api sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Endpoint Management</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/event-management");
                            }}>
                                <CellTower sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Event Management</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/monitoring");
                            }}>
                                <MonitorHeart sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Monitoring</Typography>
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "200px", marginTop: "100px"}}>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/exception-management");
                            }}>
                                <ErrorOutline sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Exception Management</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/sandbox-management");
                            }}>
                                <ViewInAr sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Sandbox Management</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/log-management");
                            }}>
                                <Notes sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Log Management</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/bill-configuration");
                            }}>
                                <img alt="bill logo" src={bill_logo} style={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Bill.com Configuration</Typography>
                        </div>
                    </div>
                </>
            )
        }
        if (breakpoint.getBreakPointName() === "md") {
            dashboard = (
                <>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", height: "200px", marginTop: "20px"}}>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/rest-api-management");
                            }}>
                                <ManageAccounts sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>REST API Management</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/endpoint-management");
                            }}>
                                <Api sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Endpoint Management</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/event-management");
                            }}>
                                <CellTower sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Event Management</Typography>
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "200px", marginTop: "100px"}}>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/monitoring");
                            }}>
                                <MonitorHeart sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Monitoring</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/exception-management");
                            }}>
                                <ErrorOutline sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Exception Management</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/sandbox-management");
                            }}>
                                <ViewInAr sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Sandbox Management</Typography>
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "200px", marginTop: "100px"}}>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", width: "100px", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/log-management");
                            }}>
                                <Notes sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Log Management</Typography>
                        </div>
                       <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/bill-configuration");
                            }}>
                                <img alt="bill logo" src={bill_logo} style={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Bill.com Configuration</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", width: "100px", marginLeft: "auto", marginRight: "auto"}}>
                        </div>
                    </div>
                </>
            )
        }
        if (breakpoint.getBreakPointName() === "sm") {
            dashboard = (
                <>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", height: "200px", marginTop: "20px"}}>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/rest-api-management");
                            }}>
                                <ManageAccounts sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>REST API Management</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/endpoint-management");
                            }}>
                                <Api sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Endpoint Management</Typography>
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "200px", marginTop: "100px"}}>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/event-management");
                            }}>
                                <CellTower sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Event Management</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/exception-management");
                            }}>
                                <ErrorOutline sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Exception Management</Typography>
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "200px", marginTop: "100px"}}>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/monitoring");
                            }}>
                                <MonitorHeart sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Monitoring</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/sandbox-management");
                            }}>
                                <ViewInAr sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Sandbox Management</Typography>
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "200px", marginTop: "100px"}}>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", width: "100px", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/log-management");
                            }}>
                                <Notes sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Log Management</Typography>
                        </div>
                       <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/bill-configuration");
                            }}>
                                <img alt="bill logo" src={bill_logo} style={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Bill.com Configuration</Typography>
                        </div>
                    </div>
                </>
            )
        }
        if (breakpoint.getBreakPointName() === "xs") {
            dashboard = (
                <>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", height: "200px", marginTop: "20px"}}>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/rest-api-management");
                            }}>
                                <ManageAccounts sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>REST API Management</Typography>
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "200px", marginTop: "100px"}}>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/endpoint-management");
                            }}>
                                <Api sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Endpoint Management</Typography>
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "200px", marginTop: "100px"}}>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/event-management");
                            }}>
                                <CellTower sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Event Management</Typography>
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "200px", marginTop: "100px"}}>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/exception-management");
                            }}>
                                <ErrorOutline sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Exception Management</Typography>
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "200px", marginTop: "100px"}}>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/monitoring");
                            }}>
                                <MonitorHeart sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Monitoring</Typography>
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "200px", marginTop: "100px"}}>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/sandbox-management");
                            }}>
                                <ViewInAr sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Sandbox Management</Typography>
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "200px", marginTop: "100px"}}>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", width: "100px", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/log-management");
                            }}>
                                <Notes sx={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Log Management</Typography>
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "200px", marginTop: "100px"}}>
                       <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                            <IconButton className={css_buttons.blueIconButton} style={{height: "175px"}} onClick={() => {
                                navigate("/dashboard/bill-configuration");
                            }}>
                                <img alt="bill logo" src={bill_logo} style={{color: Blue3, width: "150px", height: "150px"}}/>
                            </IconButton>
                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Bill.com Configuration</Typography>
                        </div>
                    </div>
                </>
            )
        }

        return dashboard
    }

    return (
        <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", height: 52, backgroundColor: "#1498D8"}}>
                <div style={{paddingTop: "9px", paddingLeft: "20px"}}>
                    <Typography style={{fontSize: 24, fontWeight: 400, color: "#FFFFFF"}}>Dashboard</Typography>
                </div>
                <div>
                    <IconButton className={css_self.DashboardMenuButton} onClick={(e) => setMenu({id: "apps", target: e.currentTarget})}>
                        <Apps/>
                    </IconButton>{menu?.id === "apps" ? (
                    <Popover anchorEl={menu.target}
                             anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                             transformOrigin={{vertical: -12, horizontal: "left"}}
                             open={true}
                             onClose={() => setMenu(null)}
                             TransitionProps={{
                                 onExited: () => setMenu(null),
                             }}
                             PaperProps={{
                                 elevation: 2,
                             }}
                    >
                        <Box sx={{p: 2, bgcolor: "background.paper", width: "375px", height: "375px"}}>
                            <div style={{display: "flex", flexDirection: "column", padding: "10px"}}>
                                <div style={{display: "flex", flexDirection: "row", alignContent: "flex-start"}}>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                            onClick={() => {
                                                navigate("/dashboard/rest-api-management");
                                            }}>
                                            <ManageAccounts sx={{color: Blue3, width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>REST API Management</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                            onClick={() => {
                                                navigate("/dashboard/endpoint-management");
                                            }}>
                                            <Api sx={{color: Blue3, width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Endpoint Management</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                            onClick={() => {
                                                navigate("/dashboard/event-management");
                                            }}>
                                            <CellTower sx={{color: Blue3, width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Event Management</Typography>
                                    </div>
                                </div>
                                <div style={{marginTop: "20px", display: "flex", flexDirection: "row", alignContent: "flex-start"}}>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                            onClick={() => {
                                                navigate("/dashboard/monitoring");
                                            }}>
                                            <MonitorHeart sx={{color: Blue3, width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Monitoring</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                            onClick={() => {
                                                navigate("/dashboard/exception-management");
                                            }}>
                                            <ErrorOutline sx={{color: Blue3, width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Exception Management</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                            onClick={() => {
                                                navigate("/dashboard/sandbox-management");
                                            }}>
                                            <ViewInAr sx={{color: Blue3, width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Sandbox Management</Typography>
                                    </div>
                                </div>
                                <div style={{marginTop: "20px", display: "flex", flexDirection: "row", alignContent: "flex-start"}}>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                            onClick={() => {
                                                navigate("/dashboard/log-management");
                                            }}>
                                            <Notes sx={{color: Blue3, width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Log Management</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                            onClick={() => {
                                                navigate("/dashboard/bill-configuration");
                                            }}>
                                            <img alt="bill logo" src={bill_logo} style={{color: Blue3, width: "45px", height: "45px"}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Bill.com Configuration</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Popover>
                ) : null}
                </div>
            </div>
            {renderDashboard()}
        </div>
    );
}

function navigate(arg0: string) {
    throw new Error("Function not implemented.");
}
