import React from "react";

import Applet from "../../Applet";
import BillSubscriptionTable from "../../../../components/BillConfiguration/BillSubscriptionTable";

class BillSubscriptionTableApplet extends Applet {

    render() {
        return <BillSubscriptionTable applet={this}
                               pageSize={this.state.PageSize}
                               placement={this.state.Placement}
                               columns={this.state.Columns}
                               title="Bill Subscription"
                               enterpriseComponent={this.getEnterpriseComponent()}
                               sort={this.state.Sort}
                               menu={this.state.Menu}
                               rowHeight={this.state.RowHeight}
                               enableSort={true}
        />;
    }

    state= {
        Name: "BillSubscriptionTableApplet",
        Menu: {
            options: [
                {
                    displayValue: "Refresh",
                    value: "Refresh",
                },
            ],
        },
        RowHeight: 50,
        PageSize: {
            "default": 20,
            "xs": 3,
            "sm": 3,
            "md": 20,
            "lg": 20,
            "lx": 20,
        },
        Placement: {
            "xl": [
                {
                    id: "RecordPointer",
                },
                {
                    id: "Name",
                },
                {
                    id: "Status",
                },
                {
                    id: "EventType",
                },
                {
                    id: "SubscriptionID",
                },
                {
                    id: "SecurityKey",
                },
                {
                    id: "Actions",
                },
            ],
        },
        Columns: {
            "RecordPointer": {
                id: "RecordPointer",
                width: 25,
                cellRenderer: "Row Selection Indicator Cell",
                header: null,
                headerRenderer: "",
                headerTitle: "",
            },
            "Name": {
                id: "Name",
                width: 300,
                cellRenderer: "Standard Single Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Name",
            },
            "Status": {
                id: "Status",
                width: 150,
                cellRenderer: "Subscription Status Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Status",
            },
            "EventType": {
                id: "EventType",
                width: 300,
                cellRenderer: "Event Type and Version Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Event Type/Version",
            },
            "SubscriptionID": {
                id: "SubscriptionID",
                width: 300,
                cellRenderer: "Standard Single Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Subscription ID",
            },
            "SecurityKey": {
                id: "SecurityKey",
                width: 400,
                cellRenderer: "Standard Single Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Security Key",
            },
            "Actions": {
                id: "Actions",
                width: 50,
                cellRenderer: "Action Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "",
                pinned: "right",
                hideable: false,
            }
        },
        Sort: [
            {
                "Identifier": 1,
                "Visible": true,
                "Default": true,
                "Options": [{
                    "Direction": "ASC",
                    "SortAttribute": "Name",
                    "DisplayName": "Name",
                }],
            },
        ],
    }
}

export default BillSubscriptionTableApplet;
