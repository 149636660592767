import {AddCircleOutline, CheckCircle, Clear, Error, Help, Visibility, VisibilityOff} from "@mui/icons-material";
import {Alert, Button, Checkbox, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, InputAdornment, Popper, Switch, TextField, Typography} from "@mui/material";
import {withStyles} from "@mui/styles";
import moment from "moment-timezone";
import React, {Component} from "react";
import {v4 as uuidv4} from "uuid";
import {withAppContext} from "./withAppContext";
import LeftArrow from "../assets/leftarrow.png";
import spinner from "../assets/Spinner-1s-200px.svg";
import CCButton from "../components/Common/CCButton";
import CCDatePicker from "../components/Common/CCDatePicker";
import CCDateTimePicker from "../components/Common/CCDateTimePicker";
import CCInputFloat from "../components/Common/CCInputFloat";
import CCInputInteger from "../components/Common/CCInputInteger";
import CCInputPhone from "../components/Common/CCInputPhone";
import DynamicLookupDefaultValue from "../eoec/util/DynamicLookupDefaultValue";
import UniqueValidator from "../eoec/util/UniqueValidator";
import styles from "../theme/styles";
import {prepareBuffer} from "../util/Buffer";
import {updateObject} from "../util/utility";
import * as validators from "../util/Validators";
import EnlilSelect from "./EnlilSelect";
import css_buttons from "../components/Common/css/CCButton.module.scss";

class EnlilDetail extends Component {
    state = {
        fetchBackgroundOperation: false,
        buffer: {},
        filtering: false,
        controls: null,
        errors: {},
        saveAndAddNew: false,
        previousValues: {},
        updateBackgroundOperation: false,
        insertBackgroundOperation: false,
        queryBackgroundOperation: false,
        deleteBackgroundOperation: false,
        errorReportingMethod: "RealTime",
        showErrors: true,
        showPassword: false,
        groupCounter: 1,
        showAddAnother: false,
        patientNotificationOptionalChecked: true,
        initialModification: false,
        showInitialModificationWarning: false,
        helpAnchor: null,
        helpFormElementID: null,
        performConfirmOnSave: false,
        confirmOnSave: this.props.options && this.props.options.ConfirmOnSave === true,
    };

    constructor(props) {
        super(props);
        this.applet = props.applet;
    }

    blurHandler = (event, formElementId) => {
        if (this.state.controls[formElementId].requiresConfirmation !== true) return;
        if (this.state.controls[formElementId].valid === false) return;
        if (this.state.controls[formElementId].touched === false) return;
        if (this.state.controls[formElementId].confirmedValue === this.state.controls[formElementId].value) return;

        let original_value = this.applet.getEnterpriseComponent().getAttributeValue(this.props.controls[formElementId].enterpriseComponentAttribute, "original");

        let field = this.state.controls[formElementId];

        let err = field["errors"];

        let cumulativeErrors = {...this.state.errors};

        for (let i = 0; i < err.length; i++) {
            let testCase = err[i];

            if (testCase.validator === "Unique") {
                if (this.state.controls[formElementId].value !== "" && this.state.controls[formElementId].confirmedValue !== event.target.value && original_value !== this.state.controls[formElementId].value) {
                    this.validatorUnique(event.target.value, formElementId);
                }
                delete cumulativeErrors[field.id + "_" + testCase.validator];
            }
        }

        this.setState({errors: cumulativeErrors});

        return true;
    };

    componentDidMount() {
        this.applet.getEnterpriseComponent().addRecordChangedListener(this);
        this.applet.getEnterpriseComponent().addLoadStartedListener(this);
        this.applet.getEnterpriseComponent().addLoadCompletedListener(this);
        this.applet.getEnterpriseComponent().addDeleteStartedListener(this);
        this.applet.getEnterpriseComponent().addDeleteCompletedListener(this);
        this.applet.getEnterpriseComponent().addUpdateStartedListener(this);
        this.applet.getEnterpriseComponent().addUpdateCompletedListener(this);
        this.applet.getEnterpriseComponent().addInsertStartedListener(this);
        this.applet.getEnterpriseComponent().addInsertCompletedListener(this);
        this.applet.getEnterpriseComponent().addInsertRollbackCompletedListener(this);
        this.applet.getEnterpriseComponent().addUpdateRollbackCompletedListener(this);
        this.applet.getEnterpriseComponent().addStartNewRecordModeCompletedListener(this);
        this.applet.getEnterpriseComponent().addStartEditRecordModeCompletedListener(this);

        this.applet.setMode(this.props.mode);

        this.initialize();
    }

    componentWillUnmount() {
        this.applet.getEnterpriseComponent().removeAllListeners(this);
    }

    cancel1Handler = (event, _) => {
        if (this.applet.getMode() === "View") {
            this.applet.cancel();
        } else if (this.applet.getMode() === "Query") {
            this.applet.cancel();
        } else if (this.isFormTouched()) {
            event.preventDefault();
            this.setState({showConfirmation: true});
        } else {
            this.applet.cancel();
        }
    };

    cancel2Handler = (event) => {
        event.preventDefault();
        this.setState({errors: {}, showConfirmation: false});
        this.applet.cancel();
    };

    checkValidity = (formElementId, value) => {
        let check = {};

        let values = {};
        let keys = Object.keys(this.state.controls);
        for (let i = 0; i < keys.length; i++) {
            if (keys[i] === formElementId) {
                values[keys[i]] = value;
            } else {
                values[keys[i]] = this.state.controls[keys[i]].value;
            }
        }
        let cumulativeErrors = {...this.state.errors};

        for (let t = 0; t < keys.length; t++) {
            let formElementToTestId = keys[t];

            let field = this.state.controls[formElementToTestId];
            let err = field["errors"];
            if (err === undefined) {
                err = {};
            }
            let attribute = this.applet.getEnterpriseComponent().getAttribute(field.enterpriseComponentAttribute);

            if (formElementId === formElementToTestId) {
                check[formElementToTestId] = {
                    ...field,
                    touched: true,
                    value: values[formElementToTestId],
                    valid: true,
                };
            } else {
                check[formElementToTestId] = {
                    ...field,
                    touched: this.state.controls[formElementToTestId].touched,
                    value: values[formElementToTestId],
                    valid: true,
                };
            }
            for (let i = 0; i < err.length; i++) {
                let testCase = err[i];
                let localCheck = true;

                if (testCase.validator === "Required") {
                    localCheck = validators.Required(values[formElementToTestId]);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "No Leading Zeros") {
                    localCheck = validators.NoLeadingZeros(values[formElementToTestId]);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "No Leading Spaces") {
                    localCheck = validators.NoLeadingSpaces(values[formElementToTestId]);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "No Trailing Spaces") {
                    localCheck = validators.NoTrailingSpaces(values[formElementToTestId]);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Minimum") {
                    localCheck = validators.Minimum(values[formElementToTestId], attribute.getMinLength());
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Maximum") {
                    localCheck = validators.Maximum(values[formElementToTestId], attribute.getLength());
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "No Special Characters") {
                    localCheck = validators.NoSpecialCharacters(values[formElementToTestId]);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "No Spaces") {
                    localCheck = validators.NoSpaces(values[formElementToTestId]);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Maximum Value") {
                    localCheck = validators.MaximumValue(values[formElementToTestId], testCase.value);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Minimum Value") {
                    localCheck = validators.MinimumValue(values[formElementToTestId], testCase.value);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Required Integer") {
                    localCheck = validators.RequiredInteger(values[formElementToTestId]);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Required Pattern") {
                    if (values[formElementToTestId] === null) {
                        localCheck = false;
                    } else {
                        localCheck = validators.RequiredPattern(values[formElementToTestId], attribute.getPattern());
                    }
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Optional Pattern") {
                    localCheck = validators.OptionalPattern(values[formElementToTestId], attribute.getPattern());
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Float") {
                    localCheck = isNaN(Number(values[formElementToTestId])) !== true;
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Numbers Only") {
                    localCheck = validators.NumbersOnlyPattern(values[formElementToTestId], attribute.getPattern());
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Match Values") {
                    localCheck = validators.MatchValues(values[formElementToTestId], values[testCase.field]);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "All Or None") {
                    localCheck = validators.AllOrNone(values[formElementToTestId], testCase.fields.map(x => values[x]));
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Either Values") {
                    localCheck = validators.EitherValues(values[formElementToTestId], values[testCase.field]);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Either Values Not NULL") {
                    localCheck = validators.EitherValuesNotNULL(values[formElementToTestId], values[testCase.field]);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "One Field Must Be Provided") {
                    localCheck = validators.OneFieldMustBeProvided(testCase.field, values, this.state.controls);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "One Checkbox Must Be True") {
                    localCheck = validators.OneCheckboxMustBeTrue(testCase.field, values);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Is Same Or Before Day") {
                    localCheck = validators.IsSameOrBeforeDay(values[formElementToTestId], testCase.value ?? values[testCase.field]);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Is Same Or After Day") {
                    localCheck = validators.IsSameOrAfterDay(values[formElementToTestId], values[testCase.field]);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Cannot Match Values") {
                    localCheck = validators.CannotMatchValues(values[formElementToTestId], values[testCase.field]);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Cannot Match Values - Case Insensitive") {
                    localCheck = validators.CannotMatchValuesCaseInsensitive(values[formElementToTestId], values[testCase.field]);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Not All Spaces") {
                    if (values[formElementToTestId].length === 0) {
                        localCheck = true;
                    } else localCheck = values[formElementToTestId].trim().length !== 0;
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Unique") {
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                }

                check[formElementToTestId].hasBeenConfirmed = this.state.controls[formElementToTestId].hasBeenConfirmed;
                if (this.state.controls[formElementToTestId].requiresConfirmation === true) {
                    if (this.state.controls[formElementToTestId].confirmedValue !== values[formElementToTestId]) {
                        check[formElementToTestId].hasBeenConfirmed = false;
                        check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                    } else if (this.state.controls[formElementToTestId].hasBeenConfirmed === false && values[formElementToTestId] !== "") {
                        check[formElementToTestId].valid = false;
                    }
                }

                if (this.state.controls[formElementToTestId].requiresConfirmation) {
                    if (values[formElementToTestId] === "") {
                        check[formElementToTestId].hasBeenConfirmed = false;
                        check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                    }
                }

                if (localCheck === false) {
                    if (this.props.errorReportingMethod === "SubmitTime") {
                        cumulativeErrors[field.id + "_" + testCase.validator] = testCase.errorText;
                    } else {
                        if (check[formElementToTestId].touched === true) {
                            cumulativeErrors[field.id + "_" + testCase.validator] = testCase.errorText;
                        }
                    }
                } else {
                    if (testCase.validator !== "Unique") {
                        delete cumulativeErrors[field.id + "_" + testCase.validator];
                    }
                }
            }
        }
        this.setState({errors: cumulativeErrors});

        return check;
    };

    deleteHandler = () => {
        this.applet.setMode("Delete");
    };

    editHandler = () => {
        this.applet.setMode("Edit");
    };

    enterpriseComponentInsertStarted = () => {
        this.setState({insertBackgroundOperation: true});
    };

    enterpriseComponentInsertCompleted = (ec) => {
        let controls = this.makeControls().controls;

        let buffer = prepareBuffer(ec);
        buffer = buffer.slice(this.applet.getEnterpriseComponent().getRecordPointer(), this.applet.getEnterpriseComponent().getRecordPointer() + 1);

        let keys = Object.keys(controls);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            controls[key]["value"] = buffer[0][key] === null ? "" : buffer[0][key];
            controls[key]["valid"] = true;
            controls[key]["touched"] = false;
            controls[key]["showSpinner"] = false;
        }

        if (this.state.saveAndAddNew) {
            this.applet.setMode("New");
            this.prepareLocalState(buffer[0]);
        } else {
            this.applet.setMode("Save Completed");
        }

        this.setState({controls: controls, errors: {}, insertBackgroundOperation: false});
    };

    enterpriseComponentInsertRollbackCompleted = (ec) => {
        let buffer = prepareBuffer(ec);
        buffer = buffer.slice(this.applet.getEnterpriseComponent().getRecordPointer(), this.applet.getEnterpriseComponent().getRecordPointer() + 1);
        if (buffer.length > 0) {
            this.setState({buffer: buffer[0], fetchBackgroundOperation: false});
            this.prepareLocalState(buffer[0]);
        } else {
            this.setState({buffer: [], fetchBackgroundOperation: false});
        }
    };

    enterpriseComponentLoadStarted = () => {
        this.setState({fetchBackgroundOperation: true});
    };

    enterpriseComponentLoadCompleted = (ec) => {
        let buffer = prepareBuffer(ec);
        buffer = buffer.slice(this.applet.getEnterpriseComponent().getRecordPointer(), this.applet.getEnterpriseComponent().getRecordPointer() + 1);
        this.setState({buffer: buffer[0], fetchBackgroundOperation: false});
        if (this.applet.getMode() === "Edit") {
            this.applet.getEnterpriseComponent().setUpdateMode();
        }
        this.prepareLocalState(buffer[0]);
    };

    enterpriseComponentRecordChanged = (ec) => {
        let buffer = prepareBuffer(ec);
        buffer = buffer.slice(this.applet.getEnterpriseComponent().getRecordPointer(), this.applet.getEnterpriseComponent().getRecordPointer() + 1);
        this.setState({buffer: buffer[0]});
        this.prepareLocalState(buffer[0]);
    };

    enterpriseComponentStartNewRecordModeCompleted = (ec) => {
        let buffer = prepareBuffer(ec);
        buffer = buffer.slice(this.applet.getEnterpriseComponent().getRecordPointer(), this.applet.getEnterpriseComponent().getRecordPointer() + 1);
        this.prepareLocalState(buffer[0]);
    };

    enterpriseComponentStartEditRecordModeCompleted = (ec) => {
        let buffer = prepareBuffer(ec);
        buffer = buffer.slice(this.applet.getEnterpriseComponent().getRecordPointer(), this.applet.getEnterpriseComponent().getRecordPointer() + 1);
        if (buffer.length > 0) {
            this.prepareLocalState(buffer[0]);
        }
    };

    enterpriseComponentUpdateStarted = () => {
        this.setState({updateBackgroundOperation: true});
    };

    enterpriseComponentDeleteStarted = () => {
        this.setState({deleteBackgroundOperation: true});
    };

    enterpriseComponentDeleteCompleted = (ec) => {
        let buffer = prepareBuffer(ec);
        buffer = buffer.slice(this.applet.getEnterpriseComponent().getRecordPointer(), this.applet.getEnterpriseComponent().getRecordPointer() + 1);

        let controls = this.makeControls().controls;

        let keys = Object.keys(controls);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            controls[key]["value"] = buffer[0][key] === null ? "" : buffer[0][key];
            controls[key]["valid"] = true;
            controls[key]["touched"] = false;
            controls[key]["showSpinner"] = false;
        }

        this.setState({controls: controls, errors: {}, deleteBackgroundOperation: false});

        this.applet.setMode("Save Completed");
    };

    enterpriseComponentUpdateCompleted = (ec) => {
        let buffer = prepareBuffer(ec);
        buffer = buffer.slice(this.applet.getEnterpriseComponent().getRecordPointer(), this.applet.getEnterpriseComponent().getRecordPointer() + 1);

        let controls = this.makeControls().controls;

        let keys = Object.keys(controls);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            controls[key]["value"] = buffer[0][key] === null ? "" : buffer[0][key];
            controls[key]["valid"] = true;
            controls[key]["touched"] = false;
            controls[key]["showSpinner"] = false;
        }

        this.setState({controls: controls, errors: {}, updateBackgroundOperation: false});

        this.applet.setMode("Save Completed");
    };

    enterpriseComponentUpdateRollbackCompleted = (ec) => {
        let buffer = prepareBuffer(ec);
        buffer = buffer.slice(this.applet.getEnterpriseComponent().getRecordPointer(), this.applet.getEnterpriseComponent().getRecordPointer() + 1);

        let controls = this.makeControls().controls;

        let keys = Object.keys(controls);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            controls[key]["value"] = buffer[0][key] === null ? "" : buffer[0][key];
            controls[key]["valid"] = true;
            controls[key]["touched"] = false;
            controls[key]["showSpinner"] = false;
        }

        this.setState({controls: controls, errors: {}, updateBackgroundOperation: false});
    };

    evaluateSaveDisabledStatus = () => {
        if (this.useRealTimeReportingMethod()) {
            if (this.applet.getMode() === "Delete") {
                return false
            }
            return this.isFormValid() === false || this.isFormTouched() === false;
        } else {
            return false;
        }
    };

    getDefaultValue(name) {
        if (name === "APIKey") return uuidv4();
        else if (name === "SecretKey") return uuidv4();
    };

    getField = (formElement, renderGroupRemoveButton, group) => {
        /*
        formElement.ip (https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attributes)
        formElement.inputLabelProps (https://material-ui.com/api/input-label/)
        formElement.formHelperTextProps (https://material-ui.com/api/form-helper-text/)
         */
        let out;
        let field;

        if (formElement.csp === undefined) {
            formElement["csp"] = {};
        }
        if (formElement.ip === undefined) {
            formElement["ip"] = {};
        }

        if (formElement["placement"] === undefined) {
            formElement["placement"] = {
                "View": {order: 100, xs: 1, sm: 1, md: 1, lg: 1, xl: 1},
                "Edit": {order: 100, xs: 1, sm: 1, md: 1, lg: 1, xl: 1},
                "New": {order: 100, xs: 1, sm: 1, md: 1, lg: 1, xl: 1},
                "Delete": {order: 100, xs: 1, sm: 1, md: 1, lg: 1, xl: 1},
            };
        }

        let attribute = this.applet.getEnterpriseComponent().getAttribute(formElement.enterpriseComponentAttribute);
        formElement.csp.required = formElement.csp.required || (attribute === undefined ? false : attribute.getRequired());

        if (formElement.showWarning === true) {
            formElement.csp["helperText"] = formElement.warningText;
        } else {
            formElement.csp["helperText"] = null;
        }
        if (this.state.showErrors === true) {
            let helperText = formElement.csp["helperText"];

            formElement.csp["helperText"] = null;
            let keys = Object.keys(this.state.errors);
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];

                if (key.includes(formElement.id)) {
                    helperText = this.state.errors[key];
                    formElement.csp["helperText"] = helperText;
                }
            }

            if (formElement.csp.required && helperText === null && !this.state.controls[formElement.id].valid) {
                formElement.csp["helperText"] = "Field must contain a value";
            }
        }

        let inputProps = {
            ...formElement.ip,
        };

        if (formElement.type === "Checkbox") {
            delete formElement.csp["helperText"];
        }

        let inputLabelProps = {shrink: true};

        if (formElement.longLabel) {
            inputLabelProps = {
                shrink: true,
                classes: {
                    root: this.props.classes.longLabel,
                },
            };
        }

        let adornments = {};
        let helpButton = null;
        let helpContent = null;
        let elementIndex = 0;
        if (formElement.help !== undefined && this.state.helpAnchor !== null) {
            helpContent = (
                <ClickAwayListener onClickAway={this.hideHelp}>
                    <Popper id={"1"} style={{zIndex: "4000"}} open={formElement.id === this.state.helpFormElementID} placement="right" anchorEl={this.state.helpAnchor}>
                        <Alert severity="info">
                            <>{formElement.help.map(element => (<React.Fragment key={elementIndex++}><span key={elementIndex++}>{element.helpText}</span><br /></React.Fragment>))}</>
                        </Alert>
                    </Popper>
                </ClickAwayListener>
            );
        }

        if (formElement.help !== undefined) {
            helpButton = (
                <IconButton style={{paddingRight: "0px"}} onClick={(event) => this.showHelp(event, formElement)}><Help color="primary" /></IconButton>
            );
            adornments["endAdornment"] = (
                <InputAdornment position="end">
                    {helpButton}
                    {helpContent}
                </InputAdornment>
            );
        }
        if (formElement.csp.uom !== undefined) {
            adornments["endAdornment"] = (
                <InputAdornment position="end">
                    <span style={{color: "#bac3ce"}}>{formElement.csp.uom}</span>
                    {helpButton}
                    {helpContent}
                </InputAdornment>
            );
        }
        if (formElement.showSpinner === true) {
            adornments["endAdornment"] = (
                <InputAdornment position="end">
                    <img src={spinner} alt="Spinner" style={{width: "35px", height: "35px"}} />
                </InputAdornment>
            );
        }
        if (!formElement.valid && formElement.touched && this.state.showErrors) {
            adornments["startAdornment"] = (
                <InputAdornment position="start">
                    <Error color="error" />
                </InputAdornment>
            );
        }
        if (formElement.valid && formElement.touched && this.state.showErrors) {
            adornments["startAdornment"] = (
                <InputAdornment position="start">
                    <CheckCircle color="primary" />
                </InputAdornment>
            );
        }
        if (formElement.type === "TextField") {
            field = (
                <TextField
                    key={formElement.id}
                    {...formElement.csp}
                    inputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    error={!formElement.valid && formElement.touched && this.state.showErrors}
                    fullWidth={true}
                    id={formElement.id}
                    onBlur={(event) => this.blurHandler(event, formElement.id)}
                    onChange={(event) => this.inputChangedHandler(event, formElement.id)}
                    value={formElement.value}
                    variant="outlined"
                    InputProps={adornments}
                />
            );
        }  else if (formElement.type === "Placeholder") {
            field = null;
        } else if (formElement.type === "Phone") {
            field = (
                <CCInputPhone
                    key={formElement.id}
                    {...formElement.csp}
                    inputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    error={!formElement.valid && formElement.touched && this.state.showErrors}
                    id={formElement.id}
                    onBlur={(event) => this.blurHandler(event, formElement.id)}
                    onChange={(event) => this.inputChangedHandler(event, formElement.id)}
                    value={formElement.value}
                    InputProps={adornments}
                />
            );
        } else if (formElement.type === "Integer") {
            field = (
                <CCInputInteger
                    key={formElement.id}
                    {...formElement.csp}
                    inputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    error={!formElement.valid && formElement.touched && this.state.showErrors}
                    id={formElement.id}
                    onBlur={(event) => this.blurHandler(event, formElement.id)}
                    onChange={(event) => this.inputChangedHandler(event, formElement.id)}
                    value={formElement.value}
                    InputProps={adornments}
                />
            );
        } else if (formElement.type === "Select") {
            let attribute = this.applet.getEnterpriseComponent().getAttribute(formElement.enterpriseComponentAttribute);

            let picklist = attribute !== null ? attribute.getPicklist() : null;

            field = (
                <EnlilSelect
                    key={formElement.id}
                    {...formElement.csp}
                    inputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    error={!formElement.valid && formElement.touched && this.state.showErrors}
                    fullWidth={true}
                    id={formElement.id}
                    onBlur={(event) => this.blurHandler(event, formElement.id)}
                    onChange={(event) => this.inputChangedHandler(event, formElement.id)}
                    value={formElement.value}
                    variant="outlined"
                    InputProps={adornments}
                    picklist={picklist}
                    mode={this.applet.getMode()}
                    parent={this}
                />
            );
        } else if (formElement.type === "Number") {
            field = (
                <CCInputFloat
                    key={formElement.id}
                    {...formElement.csp}
                    inputProps={inputProps}
                    InputLabelProps={{shrink: true}}
                    error={!formElement.valid && formElement.touched && this.state.showErrors}
                    id={formElement.id}
                    onBlur={(event) => this.blurHandler(event, formElement.id)}
                    onChange={(event) => this.inputChangedHandler(event, formElement.id)}
                    value={formElement.value}
                    InputProps={adornments}
                />
            );
        } else if (formElement.type === "Positive Float") {
            field = (
                <CCInputFloat
                    key={formElement.id}
                    {...formElement.csp}
                    inputProps={inputProps}
                    InputLabelProps={{shrink: true}}
                    error={!formElement.valid && formElement.touched && this.state.showErrors}
                    id={formElement.id}
                    onBlur={(event) => this.blurHandler(event, formElement.id)}
                    onChange={(event) => this.inputChangedHandler(event, formElement.id)}
                    value={formElement.value}
                    InputProps={adornments}
                />
            );
        } else if (formElement.type === "Password") {
            adornments["endAdornment"] = (
                <InputAdornment position="end">
                    <IconButton onClick={() => this.toggleVisibility(formElement)}>
                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </InputAdornment>
            );
            field = (
                <TextField
                    key={formElement.id}
                    {...formElement.csp}
                    inputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    error={!formElement.valid && formElement.touched && this.state.showErrors}
                    fullWidth={true}
                    id={formElement.id}
                    type={this.state.showPassword ? "text" : "password"}
                    onBlur={(event) => this.blurHandler(event, formElement.id)}
                    onChange={(event) => this.inputChangedHandler(event, formElement.id)}
                    value={formElement.value}
                    variant="outlined"
                    InputProps={adornments}
                />
            );
        } else if (formElement.type === "DateTime") {
            let v = null;
            if (formElement.value) {
                v = moment(formElement.value);
            }
            field = (
                <CCDateTimePicker
                    key={formElement.id}
                    {...formElement.csp}
                    inputProps={inputProps}
                    error={!formElement.valid && formElement.touched && this.state.showErrors}
                    id={formElement.id}
                    onBlur={(event) => this.blurHandler(event, formElement.id)}
                    onChange={(event) => this.inputChangedHandler(event, formElement.id)}
                    value={v}
                    InputLabelProps={inputLabelProps}
                />
            );
        } else if (formElement.type === "Date") {
            let v = null;
            if (formElement.value) {
                v = moment(formElement.value);
            }
            field = (
                <CCDatePicker
                    key={formElement.id}
                    {...formElement.csp}
                    inputProps={inputProps}
                    error={!formElement.valid && formElement.touched && this.state.showErrors}
                    id={formElement.id}
                    onBlur={(event) => this.blurHandler(event, formElement.id)}
                    onChange={(event) => this.inputChangedHandler(event, formElement.id)}
                    value={v}
                    style={{width: "100%"}}
                    InputLabelProps={inputLabelProps}
                />
            );
        } else if (formElement.type === "Switch") {
            field = (
                <FormControlLabel
                    value="start"
                    control={<Switch
                        key={formElement.id}
                        {...formElement.csp}
                        id={formElement.id}
                        onChange={(event) => this.inputChangedHandler(event, formElement.id)}
                        checked={formElement.value}
                    />
                    }
                    label={formElement.csp?.label}
                    labelPlacement={formElement.csp?.labelPlacement}
                />
            );
        } else if (formElement.type === "Checkbox") {
            field = (
                <FormControlLabel
                    key={formElement.id}
                    control={
                        <Checkbox
                            key={formElement.id}
                            inputProps={inputProps}
                            id={formElement.id}
                            onBlur={(event) => this.blurHandler(event, formElement.id)}
                            onChange={(event) => this.inputChangedHandler(event, formElement.id)}
                            checked={formElement.value === "true" || formElement.value === true}
                        />
                    }
                    {...formElement.csp}
                />
            );
        }

        let style = {};

        if (formElement.other?.marginBottom !== undefined) {
            style["marginBottom"] = formElement.other.marginBottom;
        } else {
            style["marginBottom"] = "1.0em";
        }

        if (formElement.other?.hidden === true) {
            style["display"] = "none";
        }

        let underNarrativeText = this.renderUnderNarrativeText(formElement);
        let assistiveText = this.renderAssistiveText(formElement);
        if (formElement.csp?.disabled === true) {
            assistiveText = null;
        }
        if (formElement.other?.renderAssistiveText === false) {
            assistiveText = null;
        }
        if (field === null) {
            assistiveText = null;
        }

        if (renderGroupRemoveButton === true && this.applet.getMode() !== "View") {
            out = (
                <Grid key={formElement.id} item style={style}
                      xs={formElement.placement[this.applet.getMode()].xs}
                      sm={formElement.placement[this.applet.getMode()].sm}
                      md={formElement.placement[this.applet.getMode()].md}
                      lg={formElement.placement[this.applet.getMode()].lg}
                      xl={formElement.placement[this.applet.getMode()].xl}>

                    <div key={1} style={{display: "flex", flexDirection: "column"}}>
                        <div key={1} style={{display: "flex", flexDirection: "row"}}>
                            <div key={1} style={{width: "95%"}}>{field}</div>
                            <div key={2} style={{width: "40px"}}>
                                <Button
                                    style={{marginTop: "11px", width: "25px", color: "#E74C3C"}}
                                    startIcon={<Clear />}
                                    onClick={() => this.onDemandDecrementHandler(group)}>
                                </Button>
                            </div>
                        </div>
                        <div key={2}>
                            {assistiveText}
                        </div>
                    </div>
                </Grid>
            );
        } else {
            out = (
                <Grid key={formElement.id} item style={style}
                      xs={formElement.placement[this.applet.getMode()].xs}
                      sm={formElement.placement[this.applet.getMode()].sm}
                      md={formElement.placement[this.applet.getMode()].md}
                      lg={formElement.placement[this.applet.getMode()].lg}
                      xl={formElement.placement[this.applet.getMode()].xl}>

                    {field}
                    {assistiveText}
                    {underNarrativeText}
                </Grid>
            );
        }
        return out;
    };

    getGroups = () => {
        if (this.props.modes[this.applet.getMode()] !== undefined) {
            if (this.props.modes[this.applet.getMode()]["Groups"] === undefined) {
                return [];
            }
            return this.props.modes[this.applet.getMode()]["Groups"];
        }

        return [];
    };

    getName = () => {
        return this.applet.getName();
    };

    hideHelp = (event) => {
        event.stopPropagation();
        this.setState({helpAnchor: null});
    };

    initialize = () => {
        let buffer = prepareBuffer(this.applet.getEnterpriseComponent());
        buffer = buffer.slice(this.applet.getEnterpriseComponent().getRecordPointer(), this.applet.getEnterpriseComponent().getRecordPointer() + 1);
        this.setState({buffer: buffer, fetchBackgroundOperation: false});
        if (this.applet.getMode() === "Edit") {
            this.applet.getEnterpriseComponent().setUpdateMode();
        }
        this.prepareLocalState(buffer[0]);
    };

    inputChangedHandler = (event, inputIdentifier) => {
        if (this.applet.getMode() === "View") return;

        let _value = null;

        if (event !== null) {
            if (this.state.controls[inputIdentifier].type === "Checkbox") {
                _value = event.target.checked;
            } else if (this.state.controls[inputIdentifier].type === "Phone") {
                _value = event.target.value.replace(/\D+/g, "").substring(0, 10);
            } else if (this.state.controls[inputIdentifier].type === "Switch") {
                _value = event.target.checked;
            } else if (this.state.controls[inputIdentifier].type === "DateTime") {
                _value = event;
            } else if (this.state.controls[inputIdentifier].type === "Date") {
                _value = event;
            } else {
                _value = event.target.value;
            }
        }

        if (_value === "") _value = null;

        let updatedFormElement;
        let updatedObjectForm;

        let results = this.checkValidity(inputIdentifier, _value ?? "");
        let keys = Object.keys(this.state.controls);
        let formResults = {};

        for (let i = 0; i < keys.length; i++) {

            let lengthWarningText = this.state.controls[keys[i]].lengthWarningText;

            if (_value !== null) {
                let attribute = this.applet.getEnterpriseComponent().getAttribute(this.state.controls[keys[i]].enterpriseComponentAttribute);
                if (attribute?.getName() === inputIdentifier) {
                    let length = attribute.getLength();
                    if (length !== null && _value.length === length) {
                        lengthWarningText = length + " characters allowed";
                    } else {
                        lengthWarningText = null;
                    }
                }
            }

            updatedFormElement = updateObject(this.state.controls[keys[i]], {
                value: results[keys[i]].value,
                valid: results[keys[i]].valid,
                touched: results[keys[i]].touched,
                hasBeenConfirmed: results[keys[i]].hasBeenConfirmed,
                lengthWarningText: lengthWarningText,
            });

            formResults[keys[i]] = updatedFormElement;
        }

        updatedObjectForm = updateObject(this.state.controls, formResults);

        let formIsValid = true;
        for (let inputIdentifier in updatedObjectForm) {
            formIsValid = updatedObjectForm[inputIdentifier].valid && formIsValid;
        }

        this.applet.getEnterpriseComponent().setAttributeValue(inputIdentifier, _value);

        let _state = {
            controls: updatedObjectForm,
            formIsValid: formIsValid,
            initialModification: true,
            showInitialModificationWarning: false,
        };

        if (this.isFieldInOnDemandGroup(inputIdentifier) === true && this.state.controls[inputIdentifier].value !== "") {
            _state["showAddAnother"] = true;
            this.setState(_state);
        } else {
            this.setState(_state);
        }
    };

    isFieldInOnDemandGroup = (inputIdentifier) => {
        for (let i = 0; i < this.props.modes[this.applet.getMode()].Groups.length; i++) {
            let group = this.props.modes[this.applet.getMode()].Groups[i];
            if (group["type"] === "ondemand") {
                for (let j = 0; j < group["increments"].length; j++) {
                    let increment = group["increments"][j];

                    for (let k = 0; k < increment.membership.length; k++) {
                        let member = increment.membership[k];
                        if (member.id === inputIdentifier) {
                            return true;
                        }
                    }
                }
            }
        }

        return false;
    };

    isFormValid = () => {
        // eslint-disable-next-line no-unused-vars
        let formIsValid = true;
        for (let formElementIdentifier in this.state.controls) {

            if (this.state.controls[formElementIdentifier].other?.hidden === true) continue;

            formIsValid = this.state.controls[formElementIdentifier].valid && formIsValid;

            let originalValue = false;

            if (this.applet.getMode() === "Edit") {
                if (this.applet.getEnterpriseComponent().getAttributeValue(formElementIdentifier, "original") === this.state.controls[formElementIdentifier].value) {
                    originalValue = true;
                }
            }

            if (this.state.controls[formElementIdentifier].requiresConfirmation &&
                this.state.controls[formElementIdentifier].hasBeenConfirmed === false &&
                this.state.controls[formElementIdentifier].value !== "" &&
                originalValue === false) {
                formIsValid = formIsValid && false;
            } else {
                formIsValid = formIsValid && true;
            }

        }
        return formIsValid;
    };

    isEmpty(obj) {
        for (let prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false;
            }
        }

        return JSON.stringify(obj) === JSON.stringify({});
    }

    isFormTouched = () => {
        // eslint-disable-next-line no-unused-vars
        for (let formElementIdentifier in this.state.controls) {
            if (this.state.controls.hasOwnProperty(formElementIdentifier)) {
                if (this.state.controls[formElementIdentifier].touched === true) {
                    return true;
                }
            }
        }
        return false;
    };

    makeControls = () => {
        let controlsForMode = [];
        let groupCounter = 0;

        let groups = this.getGroups();
        for (let i = 0; i < groups.length; i++) {
            let group = groups[i];
            if (group["type"] === "ondemand") {
                if (this.applet.getMode() === "View") {
                    groupCounter = 0;
                } else if (this.applet.getMode() === "Edit") {
                    groupCounter = 0;
                    this.setState({showAddAnother: true});
                } else groupCounter = 1;


                for (let j = 0; j < group["increments"].length; j++) {
                    let increment = group["increments"][j];

                    controlsForMode.push(...increment.membership);

                    let memberPresent = false;
                    for (let t = 0; t < increment["membership"].length; t++) {
                        let member = increment["membership"][t];
                        let control = this.props.controls[member.id];
                        let value = this.applet.getEnterpriseComponent().getAttributeValue(control.enterpriseComponentAttribute);
                        if (value !== null && value !== "") memberPresent = true;
                    }
                    if (memberPresent === true) groupCounter++;
                }
                if (groupCounter === group["increments"].length + 1) groupCounter = group["increments"].length;
            } else {
                controlsForMode.push(...group.membership);
            }
        }

        let controls = {};
        for (let i = 0; i < controlsForMode.length; i++) {
            controls[controlsForMode[i].id] = this.props.controls[controlsForMode[i].id];
            if (controls[controlsForMode[i].id].placement === undefined) {
                controls[controlsForMode[i].id].placement = {};
            }
            if (controls[controlsForMode[i].id].placement["View"] === undefined) {
                controls[controlsForMode[i].id].placement["View"] = {
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: 12,
                };
            }
            if (controls[controlsForMode[i].id].placement["New"] === undefined) {
                controls[controlsForMode[i].id].placement["New"] = {
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: 12,
                };
            }
            if (controls[controlsForMode[i].id].placement["Edit"] === undefined) {
                controls[controlsForMode[i].id].placement["Edit"] = {
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: 12,
                };
            }
            if (controls[controlsForMode[i].id].placement["Query"] === undefined) {
                controls[controlsForMode[i].id].placement["Query"] = {
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: 12,
                };
            }
        }
        return {controls: controls, groupCounter: groupCounter};
    };

    newHandler = () => {
        this.applet.setMode("New");
    };

    nextRecordHandler = () => {
        this.applet.nextRecord();
    };

    onDemandDecrementHandler = (group) => {
        if (this.state.groupCounter !== 1) {
            let increments = group["increments"];
            let increment = increments[this.state.groupCounter - 1];

            let formResults = {};
            for (let j = 0; j < increment["membership"].length; j++) {
                let member = increment["membership"][j];

                let control = this.state.controls[member.id];
                let enterpriseComponentAttribute = control.enterpriseComponentAttribute;
                this.applet.getEnterpriseComponent().setAttributeValue(enterpriseComponentAttribute, null);

                let data = {
                    value: null,
                    touched: true,
                };
                formResults[member.id] = updateObject(this.state.controls[member.id], data);
            }
            let updatedObjectForm = updateObject(this.state.controls, formResults);

            this.setState({controls: updatedObjectForm, groupCounter: this.state.groupCounter - 1, showAddAnother: true, initialModification: true});
        }
    };

    onDemandIncrementHandler = () => {
        this.setState({groupCounter: this.state.groupCounter + 1, showAddAnother: false});
    };

    prepareLocalState = (row) => {
        let c = this.makeControls();
        let controls = c.controls;
        let groupCounter = c.groupCounter;

        if (this.applet.getMode() === "Save") {
            this.setState({controls: {}, groupCounter: 0, insertBackgroundOperation: true});
        } else if (this.applet.getMode() === "View") {
            let keys = Object.keys(controls);
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];

                if (controls[key]["csp"] === undefined) {
                    controls[key]["csp"] = {};
                }
                if (controls[key]["ip"] === undefined) {
                    controls[key]["ip"] = {};
                }

                let value = row[key] === null || row[key] === undefined ? "" : row[key];
                controls[key]["value"] = typeof value === "boolean" ? value : value.toString();
                controls[key]["valid"] = true;
                controls[key]["touched"] = false;
                controls[key]["showSpinner"] = false;
                controls[key]["passwordVisible"] = false;
                controls[key]["csp"]["disabled"] = true;
                controls[key]["ip"]["readOnly"] = true;
            }
            this.setState({controls: controls, groupCounter: groupCounter, insertBackgroundOperation: false});
        } else if (this.applet.getMode() === "Delete") {
            let keys = Object.keys(controls);
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];

                if (controls[key]["csp"] === undefined) {
                    controls[key]["csp"] = {};
                }
                if (controls[key]["ip"] === undefined) {
                    controls[key]["ip"] = {};
                }

                let value = row[key] === null || row[key] === undefined ? "" : row[key];
                controls[key]["value"] = typeof value === "boolean" ? value : value.toString();
                controls[key]["valid"] = true;
                controls[key]["touched"] = false;
                controls[key]["showSpinner"] = false;
                controls[key]["passwordVisible"] = false;
                controls[key]["csp"]["disabled"] = true;
                controls[key]["ip"]["readOnly"] = true;
            }
            this.setState({controls: controls, groupCounter: groupCounter, deleteBackgroundOperation: false});
        } else if (this.applet.getMode() === "Edit" || this.applet.getMode() === "Query") {
            let keys = Object.keys(controls);
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];

                if (controls[key]["csp"] === undefined) {
                    controls[key]["csp"] = {};
                }
                if (controls[key]["ip"] === undefined) {
                    controls[key]["ip"] = {};
                }

                let value = row.get(controls[key].enterpriseComponentAttribute) === null || row.get(controls[key].enterpriseComponentAttribute) === undefined ? "" : row.get(controls[key].enterpriseComponentAttribute);
                controls[key]["value"] = typeof value === "boolean" ? value : value.toString();
                controls[key]["valid"] = true;
                controls[key]["touched"] = false;
                controls[key]["showSpinner"] = false;
                controls[key]["passwordVisible"] = false;

                if (controls[key].enterpriseComponentAttribute !== null) {
                    let attribute = this.applet.getEnterpriseComponent().getAttribute(controls[key].enterpriseComponentAttribute);
                    if (attribute !== undefined) {
                        if (attribute.getLength() !== null) {
                            controls[key]["ip"]["maxLength"] = attribute.getLength();
                        }
                        if (attribute.getNoUpdate() !== null) {
                            if (controls[key]["csp"]["disabled"] === undefined) {
                                controls[key]["csp"]["disabled"] = attribute.getNoUpdate();
                            }
                            controls[key]["ip"]["readOnly"] = attribute.getNoUpdate();
                        }
                        if (attribute.getRequired() === true) {
                            let found = false;
                            for (let t = 0; t < controls[key]["errors"].length; t++) {
                                let err = controls[key]["errors"][t];
                                if (err.validator === "Required") found = true;
                            }
                            if (!found) {
                                controls[key]["errors"].push({
                                    validator: "Required",
                                    value: null,
                                    errorText: "Field must contain a value.",
                                });
                            }
                        }
                    }
                }

                if (controls[key]["requiresConfirmation"]) {
                    controls[key]["hasBeenConfirmed"] = true;
                    controls[key]["confirmedValue"] = value.toString();
                }
            }
            this.setState({controls: controls, formIsValid: true, groupCounter: groupCounter, insertBackgroundOperation: false});
        } else if (this.applet.getMode() === "New") {
            let keys = Object.keys(controls);
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];

                controls[key]["showSpinner"] = false;

                if (controls[key]["csp"] === undefined) {
                    controls[key]["csp"] = {};
                }
                if (controls[key]["ip"] === undefined) {
                    controls[key]["ip"] = {};
                }

                let value = "";
                if (controls[key].type === "Date" || controls[key].type === "Datetime") {
                    value = null;
                }
                if (controls[key].enterpriseComponentAttribute !== null) {
                    let attribute = this.applet.getEnterpriseComponent().getAttribute(controls[key].enterpriseComponentAttribute);
                    if (attribute !== undefined) {
                        if (attribute.getDefaultValue() !== null) {
                            value = attribute.getDefaultValue();
                        }
                        if (attribute.getLength() !== null) {
                            controls[key]["ip"]["maxLength"] = attribute.getLength();
                        }
                    }
                }

                let valid = true;
                if (controls[key].enterpriseComponentAttribute !== null) {
                    let attribute = this.applet.getEnterpriseComponent().getAttribute(controls[key].enterpriseComponentAttribute);
                    if (attribute !== undefined) {
                        if (attribute.getRequired() === true) {
                            valid = false;

                            let found = false;
                            for (let t = 0; t < controls[key]["errors"].length; t++) {
                                let err = controls[key]["errors"][t];
                                if (err.validator === "Required") found = true;
                            }
                            if (!found) {
                                controls[key]["errors"].push({
                                    validator: "Required",
                                    value: null,
                                    errorText: "Field must contain a value.",
                                });
                            }
                        }
                    }
                }
                controls[key]["valid"] = valid;
                controls[key]["touched"] = false;
                controls[key]["passwordVisible"] = false;

                if (controls[key]["requiresConfirmation"]) {
                    controls[key]["hasBeenConfirmed"] = false;
                    controls[key]["confirmedValue"] = null;
                }

                if (controls[key].default !== undefined) {
                    if (controls[key].default["type"] === "Literal") {
                        value = controls[key].default["value"];
                    } else if (controls[key].default["type"] === "Computed") {
                        if (controls[key].default["value"] === "Now") {
                            let stillUtc = moment.utc();
                            value = moment(stillUtc).local().format("YYYY-MM-DDTHH:mm");
                        } else if (controls[key].default["value"] === "Employee USID") {
                            value = this.props.appContext.sessionContext.getEmployeeUSID();
                        } else if (controls[key].default["value"] === "Dynamic Lookup") {
                        } else {
                            value = this.getDefaultValue(controls[key].default["value"])
                        }
                    }
                    controls[key]["valid"] = true;
                    controls[key]["touched"] = true;
                    this.applet.getEnterpriseComponent().setAttributeValue(controls[key].enterpriseComponentAttribute, value);
                }

                controls[key]["value"] = value;

            }
            this.setState({controls: controls, formIsValid: true, groupCounter: groupCounter, insertBackgroundOperation: false});
        }
    };

    previousRecordHandler = () => {
        this.applet.previousRecord();
    };

    renderAssistiveText = (formElement) => {
        if (formElement.csp.disabled) {
            return <Typography className={this.props.classes.assistiveText}>Disabled</Typography>;
        }
        if (!formElement.csp.required && formElement.type !== "Checkbox") {
            return <Typography className={this.props.classes.assistiveText}>Optional</Typography>;
        }
        if (formElement.type !== "Select" && this.state.controls[formElement.id].lengthWarningText !== null && this.state.controls[formElement.id].lengthWarningText !== undefined) {
            return <Typography className={this.props.classes.assistiveText}>{this.state.controls[formElement.id].lengthWarningText}</Typography>;
        }
        return null;
    };

    renderUnderNarrativeText = (formElement) => {
        if (this.state.controls[formElement.id].underNarrativeText !== null && this.state.controls[formElement.id].underNarrativeText !== undefined) {
            return <Typography className={this.props.classes.underNarrativeText}>{this.state.controls[formElement.id].underNarrativeText}</Typography>;
        }
        return null;
    };

    renderPreviousRecord = () => {
        if (this.applet.getSelectedRecordNumber() > 0) {
            return (
                <Button onClick={this.previousRecordHandler}><img src={LeftArrow} width="25px" height="25px" alt="Previous Record" title="Previous Record" /></Button>
            );
        } else {
            return null;
        }
    };

    renderStandardGroup = (group) => {
        let formElements = [];
        let membership = group["membership"];
        for (let i = 0; i < membership.length; i++) {
            let f = this.state.controls[membership[i].id];
            if (f === undefined) console.log("Warning: Cannot find " + membership[i].id);
            else {
                formElements.push(this.getField(this.state.controls[membership[i].id]));
            }
        }

        return (
            <React.Fragment key={group.identifier}>
                {group["title"] !== undefined ? <div style={{marginLeft: "-13px", marginTop: "-15px"}}><DialogTitle>{group["title"]}</DialogTitle></div> : null}
                <Grid key={group.identifier} style={{marginBottom: "5px"}} item container spacing={2}>
                    {formElements}
                </Grid>
            </React.Fragment>
        );
    };

    renderCheckboxGroup = (group) => {
        let formElements = [];
        let membership = group["membership"];
        let helperText = null;

        for (let i = 0; i < membership.length; i++) {
            let f = this.state.controls[membership[i].id];
            if (f === undefined) console.log("Warning: Cannot find " + membership[i].id);
            else {
                formElements.push(this.getField(this.state.controls[membership[i].id]));
            }

            let keys = Object.keys(this.state.errors);
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];

                if (key.includes(membership[i].id)) {
                    helperText = this.state.errors[key];
                }
            }
        }

        let error = (
            <Grid key={group.identifier + "_error"} item style={{marginTop: "-10px", marginBottom: "10px"}} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography style={{fontSize: "13px", color: "#E74C3C", marginLeft: "5px", marginTop: "20px"}}>{helperText}</Typography>
            </Grid>
        );

        if (this.isFormTouched() === false || this.state.showErrors === false || helperText === null) {
            error = null;
        }

        return (
            <React.Fragment key={group.identifier}>
                <Grid key={group.identifier} item container spacing={2}>
                    {formElements}
                </Grid>
                {error}
            </React.Fragment>
        );
    };

    renderOnDemandGroup = (group) => {
        let formElements = [];
        let increments = group["increments"];
        for (let i = 0; i < this.state.groupCounter; i++) {
            let increment = increments[i];

            for (let j = 0; j < increment["membership"].length; j++) {
                let member = increment["membership"][j];

                let renderGroupRemoveButton = false;

                if (i === this.state.groupCounter - 1 && i !== 0 && j === increment["membership"].length - 1) {
                    renderGroupRemoveButton = true;
                }
                formElements.push(this.getField(this.state.controls[member.id], renderGroupRemoveButton, group));
            }
        }

        let action = (
            <CCButton
                startIcon={<AddCircleOutline />}
                onClick={() => this.onDemandIncrementHandler(group)}>
                Add Another
            </CCButton>
        );
        if (this.state.showAddAnother === false || this.state.groupCounter === increments.length) {
            action = (
                <CCButton
                    key="key1"
                    disabled={true}
                    startIcon={<AddCircleOutline variant="secondary" />}
                    onClick={() => this.onDemandIncrementHandler(group)}>
                    Add Another
                </CCButton>
            );
        }
        if (this.applet.getMode() === "View") {
            action = null;
        }
        return (
            <React.Fragment key={group.identifier}>
                <Grid key={group.identifier} item container spacing={2}>
                    {formElements}
                </Grid>
                {action}
            </React.Fragment>
        );
    };

    render() {
        let buttonHandler = null;
        let objectDetailContent = null;

        if (this.state.fetchBackgroundOperation === false && this.state.controls !== null && this.state.insertBackgroundOperation === false && this.state.updateBackgroundOperation === false) {
            let content = [];

            let groups = this.getGroups();
            for (let i = 0; i < groups.length; i++) {
                let group = groups[i];

                if (group["type"] === "standard") {
                    content.push(this.renderStandardGroup(group));
                } else if (group["type"] === "ondemand") {
                    content.push(this.renderOnDemandGroup(group));
                } else if (group["type"] === "checkbox") {
                    content.push(this.renderCheckboxGroup(group));
                }
            }

            let initialModificationWarning = null;
            if (this.state.showInitialModificationWarning === true) {
                initialModificationWarning = (
                    <Grid key="warning" item container spacing={2}>
                        <Typography style={{fontSize: "13px", color: "#E74C3C", marginLeft: "5px", marginTop: "20px"}}>No changes made.</Typography>
                    </Grid>
                );
            }

            if (this.state.insertBackgroundOperation) {}  // force a new render whenever insertBackgroundOperation changes

            objectDetailContent = (
                <React.Fragment>
                    <Grid key="id7" container justifyContent="center">
                        <form onSubmit={e => e.preventDefault()}>
                            {content}
                        </form>
                    </Grid>
                    {initialModificationWarning}
                </React.Fragment>
            );

            let firstButtonHandlerContent;
            if (this.state.showConfirmation) {
                firstButtonHandlerContent = (
                    <CCButton className={css_buttons.redStandardButton} onClick={this.cancel2Handler}>
                        Don't Save
                    </CCButton>
                );
            } else if (this.applet.getMode() === "Save Completed") {
                firstButtonHandlerContent = (
                    <CCButton className={css_buttons.blueStandardButton} onClick={this.cancel2Handler}>
                        Done
                    </CCButton>
                );
            } else {
                firstButtonHandlerContent = (
                    <CCButton className={css_buttons.redStandardButton} onClick={this.cancel1Handler}>
                        Cancel
                    </CCButton>
                );
            }
            let secondButtonHandlerContent;
            if (this.applet.getMode() === "New" || this.applet.getMode() === "Edit") {
                secondButtonHandlerContent = (
                    <>
                        {this.props.saveAndNewAction && (
                            <CCButton
                                className={css_buttons.blueStandardButton}
                                disabled={this.evaluateSaveDisabledStatus()}
                                animate={this.state.updateBackgroundOperation === true || this.state.insertBackgroundOperation === true}
                                onClick={this.saveAndOpenNewHandler}
                            >
                                {this.applet.getMode() === "New" ? "+ Add Another" : "+ Add New"}
                            </CCButton>
                        )}
                        <CCButton
                            className={css_buttons.blueStandardButton}
                            disabled={this.evaluateSaveDisabledStatus()}
                            animate={this.state.updateBackgroundOperation === true || this.state.insertBackgroundOperation === true}
                            onClick={() => this.saveHandler()}>
                            {this.state.performConfirmOnSave ? "Confirm" : "Save"}
                        </CCButton>
                    </>
                );
            } else if (this.applet.getMode() === "Query") {
                secondButtonHandlerContent = (
                    <>
                        <CCButton
                            className={css_buttons.blueStandardButton}
                            disabled={this.evaluateSaveDisabledStatus()}
                            animate={this.state.queryBackgroundOperation === true}
                            onClick={() => this.searchHandler()}>
                            Search
                        </CCButton>
                    </>
                );
            } else if (this.applet.getMode() === "Delete") {
                secondButtonHandlerContent = (
                    <>
                        <CCButton
                            className={css_buttons.redStandardButton}
                            disabled={this.evaluateSaveDisabledStatus()}
                            animate={this.state.deleteBackgroundOperation === true}
                            onClick={() => this.saveHandler()}>
                            Delete
                        </CCButton>
                    </>
                );
            }

            buttonHandler = (
                <DialogActions style={{marginBottom: "10px"}}>
                    {firstButtonHandlerContent}
                    {secondButtonHandlerContent}
                </DialogActions>
            );
        }

        let title;
        if (this.props.title !== null && this.props.title !== undefined) {
            if (this.applet.getMode() === "New") {
                title = "Add " + this.props.title;
            } else if (this.applet.getMode() === "Delete") {
                title = "Delete " + this.props.title;
            } else if (this.applet.getMode() === "Edit") {
                title = "Edit " + this.props.title;
            } else if (this.applet.getMode() === "Save") {
                title = "Saving... One moment please.";
            } else if (this.applet.getMode() === "Save Completed") {
                title = "Save completed.";
            } else if (this.applet.getMode() === "Query") {
                title = "Search " + this.props.title;
            } else {
                title = "View " + this.props.title;
            }
        }

        if (this.applet.getMode() === "Query") {
            return (
                <div style={{display: "flex", flexDirection: "column", border: "1px solid #DDDDDD"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", margin: "20px 0 5px 0"}}>
                        {objectDetailContent || (
                            <div style={{display: "flex", justifyContent: "center", marginBottom: "40px"}}>
                                <img src={spinner} alt="spinner" width={80} height={80} style={{margin: "auto"}} />
                            </div>
                        )}
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
                        {buttonHandler}
                    </div>
                </div>
            );
        } else {
            return (
                <Dialog open={true} scroll="body">
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent>
                        {objectDetailContent || (
                            <div style={{display: "flex", justifyContent: "center", marginBottom: "40px"}}>
                                <img src={spinner} alt="spinner" width={80} height={80} style={{margin: "auto"}} />
                            </div>
                        )}
                    </DialogContent>
                    {buttonHandler}
                </Dialog>
            );
        }
    }

    saveAndOpenNewHandler = async () => {
        await this.setState({saveAndAddNew: true});
        this.saveHandler();
    };

    saveHandler() {
        if (this.useRealTimeReportingMethod() === true) {
            this.applet.save();
        } else {
            if (this.state.initialModification === false) {
                this.setState({showInitialModificationWarning: true});
            } else if (this.isFormValid() === true && this.isFormTouched() === false) {
                if (this.state.saveAndAddNew === true) {
                    this.applet.getView()?.initiateAction(this.props.saveAndNewAction);
                } else {
                    this.applet.cancel();
                }
            } else if (this.isFormValid() === false) {
                let keys = Object.keys(this.state.controls);
                let formResults = {};

                let updatedFormElement;
                let updatedObjectForm;

                for (let i = 0; i < keys.length; i++) {
                    let data = {
                        touched: true,
                    };
                    updatedFormElement = updateObject(this.state.controls[keys[i]], data);
                    formResults[keys[i]] = updatedFormElement;
                }
                updatedObjectForm = updateObject(this.state.controls, formResults);

                this.setState({controls: updatedObjectForm, showErrors: true});
            } else {
                if (this.props.options && this.props.options.ConfirmOnSave === true && this.state.confirmOnSave === true && this.state.performConfirmOnSave === false) {
                    this.setState({performConfirmOnSave: true});
                } else {
                    this.setState({performConfirmOnSave: false});
                    this.applet.save();
                }
            }
        }
    };

    saveDisabledHandler = () => {
        // eslint-disable-next-line no-unused-vars
        let formIsValid = this.isFormValid();
        let touched = false;
        for (let formElementIdentifier in this.state.controls) {
            if (this.state.controls[formElementIdentifier].touched === true) touched = true;
        }
        return !(formIsValid && touched);
    };

    searchHandler = () => {
        let searchSpecification = {};

        let keys = Object.keys(this.state.controls);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (this.state.controls[key]["value"] !== null && this.state.controls[key]["value"] !== "") {
                searchSpecification[key] = this.state.controls[key]["value"];
            }
        }
        this.props.embeddedParent.query(searchSpecification);
    };

    showHelp = (event, formElement) => {
        this.setState({helpAnchor: event.currentTarget, helpFormElementID: formElement.id});
    };

    showSpinner = (formElement) => {
        if (formElement.showSpinner) {
            return <img style={{position: "relative", top: "20px", left: "0px"}} width="35" height="35" src={spinner} alt="verify" />;
        }
        return null;
    };

    toggleVisibility = (formElement) => {
        let visible = this.state.showPassword;
        let data = {
            passwordVisible: !visible,
        };
        this.setState({showPassword: !visible});
        let updatedFormElement = updateObject(this.state.controls[formElement.id], data);
        let updatedObjectForm = updateObject(this.state.controls, {
            [formElement.id]: updatedFormElement,
        });
        this.setState({controls: updatedObjectForm});
    };

    useRealTimeReportingMethod = () => {
        if (this.state.errorReportingMethod === undefined) {
            return true;
        }
        return this.state.errorReportingMethod === "RealTime";
    };

    validatorFetchInitiated = (formElementId, value) => {
        let updatedFormElement = updateObject(this.state.controls[formElementId], {
            showSpinner: true,
            hasBeenConfirmed: false,
            confirmedValue: value,
        });
        let updatedObjectForm = updateObject(this.state.controls, {
            [formElementId]: updatedFormElement,
        });

        this.setState({controls: updatedObjectForm});
    };

    validatorFetchCompleted = (formElementId, ec) => {
        let check = false;

        let field = this.state.controls[formElementId];

        let err = field["errors"];

        let cumulativeErrors = {...this.state.errors};

        for (let i = 0; i < err.length; i++) {
            let testCase = err[i];

            if (testCase.validator === "Unique") {
                if (ec.getBuffer(ec.getPagePointer()).size > 0) {
                    check = true;
                }

                if (check === true) {
                    cumulativeErrors[field.id + "_" + testCase.validator] = testCase.errorText;
                } else {
                    delete cumulativeErrors[field.id + "_" + testCase.validator];
                }
            }
        }

        let updatedFormElement = updateObject(this.state.controls[formElementId], {
            showSpinner: false,
            valid: !check,
            hasBeenConfirmed: !check,
        });
        let updatedObjectForm = updateObject(this.state.controls, {
            [formElementId]: updatedFormElement,
        });

        this.setState({controls: updatedObjectForm, errors: cumulativeErrors});
    };

    validatorUnique = (value, formElementId) => {
        let validator = new UniqueValidator();

        let field = this.state.controls[formElementId];

        let attribute = this.applet.getEnterpriseComponent().getAttribute(field.enterpriseComponentAttribute);

        validator.validate(this, value, null, field, formElementId, attribute);

        return true;
    };

    defaultValueFetchCompleted = (formElementId, buffer) => {
        let field = this.state.controls[formElementId];

        let attribute = this.applet.getEnterpriseComponent().getAttribute(field.enterpriseComponentAttribute);

        let value = null;

        if (buffer.length > 0) {
            let updatedFormElements = [];
            for (let i = 0; i < attribute.getInboundPickMaps().length; i++) {
                let pickMap = attribute.getPickMaps()[i];
                value = buffer[0][pickMap.getPickListAttribute()];
                this.applet.getEnterpriseComponent().setAttributeValue(pickMap.getAttribute(), value);

                updatedFormElements.push(updateObject(this.state.controls[pickMap.getAttribute()], {
                    showSpinner: false,
                    value: value,
                }));
            }
            let payload = {};

            for (let i = 0; i < updatedFormElements.length; i++) {
                payload[updatedFormElements[i].id] = updatedFormElements[i];
            }

            let updatedObjectForm = updateObject(this.state.controls, payload);

            this.setState({controls: updatedObjectForm});
        }

    };

    retrieveDefaultValueAsDynamicLookup = (value, controls, formElementId) => {
        let dynamicDefaultValue = new DynamicLookupDefaultValue();

        let field = controls[formElementId];

        let attribute = this.applet.getEnterpriseComponent().getAttribute(field.enterpriseComponentAttribute);

        let picklist = attribute.getPicklist();
        picklist.setSearchSpecification({"USID": value});

        dynamicDefaultValue.fetch(this, value, null, field, formElementId, attribute);

        return true;
    };
}

export default withAppContext(withStyles(styles, {withTheme: true})(EnlilDetail));