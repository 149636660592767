import RelationshipMetadata from "../../RelationshipMetadata";

class BillSubscriptionBillSubscriptionCommunicationRelationshipMetadata extends RelationshipMetadata {
    constructor() {
        super();

        this.name = "BillSubscriptionBillSubscriptionCommunicationRelationshipMetadata";
        this.parentEnterpriseComponent = "BillSubscription";
        this.childEnterpriseComponent = "BillSubscriptionCommunication";
        this.sourceAttribute = "USID";
        this.destinationAttribute = "BillSubscriptionUSID";
    }
}

export default BillSubscriptionBillSubscriptionCommunicationRelationshipMetadata;