import React from "react";
import "../Navigation/css/NavBar.scss";
import {Black} from "../../theme/styles";
import login from "../../assets/login.png"
import dashboard from "../../assets/dashboard.png"
import new_application from "../../assets/newapplication.png"
import add_application from "../../assets/addapplication.png"
import new_credential from "../../assets/newcredential.png"
import add_credential from "../../assets/addcredential.png"
import post_add_credential from "../../assets/postaddcredential.png"

export default function CreateYourApplication() {

    return (
        <div style={{paddingLeft: "20px", paddingRight: "30px"}}>
            <p style={{fontSize: "24px", fontWeight: "400", color: Black}}>Create your application</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Time to get started on your journey to integrate to Enlil.</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black, marginTop: "30px"}}>Step 1: Create a user in Enlil.</p>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginTop: "30px", marginLeft: "30px", lineHeight: "1.9"}}>To login to the Enlil portal, you must have an account in Enlil. Your Enlil administrator can create this for you.<br/><br/>
                The Developer Application will require an association to an Enlil user. When integrating to Enlil through the REST API, this user will be the identity assumed by the traffic you initiate. It is recommended that your Enlil administrator create a dedicated user for this purpose. it is
                not recommended that you use your Enlil account that you login to the Developer portal for this purpose.
            </p>

            <p style={{fontSize: "16px", fontWeight: "400", color: Black, marginTop: "30px"}}>Step 2: Login to the Developer Portal</p>
            <img alt="login screen" src={login} style={{objectFit: "contain", width: "1000px"}}/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Step 3: Navigate to the Dashboard</p>
            <img alt="Navigate to the Dashboard" src={dashboard} style={{objectFit: "contain", width: "1000px"}}/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Step 4: Create an application</p>
            <img alt="Create an application" src={new_application} style={{objectFit: "contain", width: "1000px"}}/>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginTop: "30px", marginLeft: "30px", lineHeight: "1.9"}}>
                Provide an Application Name and select a user to associate to all API traffic with.<br/><br/>
                <img alt="Provide an Application Name" src={add_application} style={{objectFit: "contain", width: "300px"}}/>
            </p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Step 5: Create a new Credential (API Key)</p>
            <img alt="Create a new Credential" src={new_credential} style={{objectFit: "contain", width: "1000px"}}/>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginTop: "30px", marginLeft: "30px", lineHeight: "1.9"}}>
                Make a note of the Secret Key. Enlil will salt and hash the Secret Key and store that value. If a Secret is lost, the existing Secret will need to be deactivated and a new one created.<br/>
                <img alt="Add Credential" src={add_credential} style={{objectFit: "contain", width: "300px"}}/>
            </p>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginTop: "30px", marginLeft: "30px", lineHeight: "1.9"}}>
                After creating the credential the following data will be visible<br/><br/>
            </p>
            <img alt="Post Add Credential" src={post_add_credential} style={{objectFit: "contain", width: "1000px"}}/>
        </div>
    )
}