import React from "react";
import {Box, IconButton, Popover, Tooltip, Typography} from "@mui/material";
import {Api, Apps, CellTower, ErrorOutline, KeyboardBackspace, ManageAccounts, MonitorHeart, Notes, ViewInAr} from "@mui/icons-material";
import {Blue3} from "../theme/styles";
import css_buttons from "../components/Common/css/CCButton.module.scss";
import {useNavigate} from "react-router-dom";
import css_self from "./css/Pages.module.scss";
import bill_logo from "../assets/bill-logo.png";
import BillConfiguration from "../components/BillConfiguration/BillConfiguration";


type MenuSelection = {id: string, target: EventTarget & HTMLButtonElement, option: string | null}

export default function DashboardBillConfiguration() {
    const [menu, setMenu] = React.useState<MenuSelection | null>(null);

    const navigate = useNavigate();

    function renderContent() {
        return (
            <>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", height: "50px", backgroundColor: "#FFFFFF", borderBottom: "1px solid #EDEFF3"}}>
                    <div style={{display: "flex", flexDirection: "row", paddingTop: "9px", marginLeft: "20px", width: "300px"}}>
                        <IconButton
                            className={css_buttons.whiteStandardButton} style={{marginLeft: "-20px", marginTop: "-2px", cursor: "pointer"}} size="small"
                            onClick={() => {
                                navigate("/dashboard");
                            }}>
                            <KeyboardBackspace sx={{color: "#1498D8", width: "30px", height: "30px"}}/>
                        </IconButton>
                        <Typography style={{fontSize: 20, fontWeight: 500, color: "#1498D8", marginTop: "3px"}}>Bill.com Configuration</Typography>
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "column", backgroundColor: "#FFFFFF", height: "100vh", paddingRight: "60px"}}>
                    <div style={{paddingLeft: "30px", paddingTop: "30px", width: "100%"}}>
                        <BillConfiguration/>
                    </div>
                </div>
            </>
        );
    }

    return (
        <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", height: 52, backgroundColor: "#1498D8"}}>
                <div style={{paddingTop: "9px", paddingLeft: "20px"}}>
                    <Typography style={{fontSize: 24, fontWeight: 400, color: "#FFFFFF"}}>Dashboard</Typography>
                </div>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", marginRight: "15px"}}>
                    <Tooltip title={"Administration Menu"}>
                        <IconButton className={css_buttons.blueIconButton2} style={{marginTop: "11px"}} onClick={(e) => setMenu({id: "administrationAppsMenu", target: e.currentTarget, option: null})}>
                            <Apps/>
                        </IconButton>
                    </Tooltip>
                    {menu?.id === "administrationAppsMenu" ? (
                        <Popover anchorEl={menu.target}
                                 anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                                 transformOrigin={{vertical: -11, horizontal: "left"}}
                                 open={true}
                                 onClose={() => setMenu(null)}
                                 TransitionProps={{
                                     onExited: () => setMenu(null),
                                 }}
                                 PaperProps={{
                                     elevation: 2,
                                 }}
                        >
                            <Box sx={{p: 2, bgcolor: "background.paper", width: "375px", height: "375px"}}>
                                <div style={{display: "flex", flexDirection: "column", padding: "10px"}}>
                                    <div style={{display: "flex", flexDirection: "row", alignContent: "flex-start"}}>
                                        <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                            <IconButton className={css_self.AppMenuButton}
                                                onClick={() => {
                                                    navigate("/dashboard/rest-api-management");
                                                }}>
                                                <ManageAccounts sx={{color: Blue3, width: 45, height: 45}}/>
                                            </IconButton>
                                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>REST API Management</Typography>
                                        </div>
                                        <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                            <IconButton className={css_self.AppMenuButton}
                                                onClick={() => {
                                                    navigate("/dashboard/endpoint-management");
                                                }}>
                                                <Api sx={{color: Blue3, width: 45, height: 45}}/>
                                            </IconButton>
                                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Endpoint Management</Typography>
                                        </div>
                                        <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                            <IconButton className={css_self.AppMenuButton}
                                                onClick={() => {
                                                    navigate("/dashboard/event-management");
                                                }}>
                                                <CellTower sx={{color: Blue3, width: 45, height: 45}}/>
                                            </IconButton>
                                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Event Management</Typography>
                                        </div>
                                    </div>
                                    <div style={{marginTop: "20px", display: "flex", flexDirection: "row", alignContent: "flex-start"}}>
                                        <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                            <IconButton className={css_self.AppMenuButton}
                                                onClick={() => {
                                                    navigate("/dashboard/monitoring");
                                                }}>
                                                <MonitorHeart sx={{color: Blue3, width: 45, height: 45}}/>
                                            </IconButton>
                                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Monitoring</Typography>
                                        </div>
                                        <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                            <IconButton className={css_self.AppMenuButton}
                                                onClick={() => {
                                                    navigate("/dashboard/exception-management");
                                                }}>
                                                <ErrorOutline sx={{color: Blue3, width: 45, height: 45}}/>
                                            </IconButton>
                                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Exception Management</Typography>
                                        </div>
                                        <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                            <IconButton className={css_self.AppMenuButton}
                                                onClick={() => {
                                                    navigate("/dashboard/sandbox-management");
                                                }}>
                                                <ViewInAr sx={{color: Blue3, width: 45, height: 45}}/>
                                            </IconButton>
                                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Sandbox Management</Typography>
                                        </div>
                                    </div>
                                    <div style={{marginTop: "20px", display: "flex", flexDirection: "row", alignContent: "flex-start"}}>
                                        <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                            <IconButton className={css_self.AppMenuButton}
                                                onClick={() => {
                                                    navigate("/dashboard/log-management");
                                                }}>
                                                <Notes sx={{color: Blue3, width: 45, height: 45}}/>
                                            </IconButton>
                                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Log Management</Typography>
                                        </div>
                                        <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                            <IconButton className={css_self.AppMenuButton}
                                                onClick={() => {
                                                    navigate("/dashboard/bill-configuration");
                                                }}>
                                                <img alt="bill logo" src={bill_logo} style={{color: Blue3, width: "45px", height: "45px"}}/>
                                            </IconButton>
                                            <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Bill.com Configuration</Typography>
                                        </div>
                                        <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Popover>
                    ) : null}
                </div>
            </div>
            {renderContent()}
        </div>
    );
}