import React from "react";
import "../Navigation/css/NavBar.scss";
import {Black} from "../../theme/styles";
import part_revision_001 from '../../json/part/part-revision-001.json';
import part_revision_002 from '../../json/part/part-revision-002.json';
import part_revision_003 from '../../json/part/part-revision-003.json';
import ReactJson from 'react-json-view'
import partRequestQBE from "../../assets/partRequestQBE1.png";
import part_request_qbe_1 from "../../json/part-request-qbe-1.json";
import part_request_qbe_1_result from "../../json/part-request-qbe-1-result.json";
import v1_PartRevision_001 from "../../assets/v1.PartRevision-001.png";
import v1_PartRevision_002 from "../../assets/v1.PartRevision-002.png";
import v1_PartRevision_003 from "../../assets/v1.PartRevision-003.png";


export default function PartRevisionsGet() {

    return (
        <div style={{paddingLeft: "20px", paddingRight: "30px"}}>
            <p style={{fontSize: "24px", fontWeight: "400", color: Black}}>Part Revisions GET</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Overview</p>

            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                In Enlil, part revisions represent the revisions of documents with a document type group attribute equal to "PART". The part revisions GET API allows for the retrieval of part revision content.
            </p>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Parameters</p>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>Header Parameters
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>idoc-response (Required)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The name of the IDOC being requested.</li>
                </li>
                <li style={{marginTop: "20px"}}>Basic Query Parameters
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>USID (Optional)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The primary key of the part entity</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>DocumentUSID (Optional)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The USID (primary key) of the Part</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>DocumentID (Optional)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The ID of the part, such as SI-10002</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>Version (Optional)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The Version of the part. Each successive revision of a part has a monotonically increasing integer.</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>Revision (Optional)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The Revision of the part. Each successive revision of a part has a increasing revision that is based on the revision stage.</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>SubRevision (Optional)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The SubRevision of the part. Each successive revision of a part has a increasing revision that is based on the revision stage.</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>Limit (Optional: Default 5)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The number of rows to return in the primary integration component in the IDOC</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>Offset (Optional: Default 0)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The number of the row to start with in the result set</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>Sort (Optional: Default None)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The column or columns to sort the results set with in the primary integration component in the IDOC. Example: [Column1: DESC, Column1: ASC]</li>
                </li>
                <li style={{marginTop: "20px"}}>Advanced Query Parameters</li>
                <li style={{marginTop: "20px", marginLeft: "20px"}}>QBE (Optional Base64 encoded string)</li>
                <li style={{marginTop: "10px", marginLeft: "40px"}}>If this parameter is provided, the Basic Query Parameters will be ignored.</li>
                <li style={{marginTop: "20px", marginLeft: "20px"}}>Examples</li>
                <li style={{marginTop: "20px", marginLeft: "40px"}}>In this example, a QBE is run against Part, PartRevision and DocumentType.</li>
                <br/><br/>
                <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "40px"}}>
                    <img src={partRequestQBE} alt={"Model"}/>
                </p>
                <span style={{marginLeft: "40px"}}>The request will be :</span><br/><br/>

                <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "40px"}}>
                    <ReactJson src={part_request_qbe_1} collapseStringsAfterLength={75} collapsed={4}/>
                </p><br/><br/>
                <span style={{marginLeft: "40px"}}>The response will be :</span><br/><br/>

                <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "40px"}}>
                    <ReactJson src={part_request_qbe_1_result} collapseStringsAfterLength={75} collapsed={4}/>
                </p>
            </ul>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Examples</p>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                Enlil provides several IDOC formats to retrieve part revision related data using the part_revisions GET API. For example, if the user executes this request:<br/><br/>

                <span style={{fontFamily: "Courier New", fontSize: "14px",}}>curl -G -d "USID=88888888-4444-4444-4444-121212121212&limit=5&offset=0" -H "idoc-response: v1.PartRevision-001" -H "X-Enlil-AccessToken: &#60;token&#62;" http://integrate.enlil.io/t3/v1/part_revisions</span>
                <br/><br/>
                The response will resemble:<br/><br/>

                <p style={{fontFamily: "Courier New", fontSize: "12px"}}>
                    <ReactJson src={part_revision_001} collapseStringsAfterLength={75} collapsed={4}/>
                </p>
            </p>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Error Codes</p>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>400 CC-HE56-KED0 - Invalid parameter: Limit</li>
            </ul>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>400 CC-AP96-LKW3 - Invalid parameter: Offset</li>
            </ul>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>IDOCs</p>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                <span style={{textDecoration: "underline"}}><b>v1.PartRevision-001</b></span><br/><br/>
                <span style={{lineHeight: "1.5em"}}>v1.PartRevision-001 is an IDOC designed to return a focused get of a single part record.</span>

                <p style={{fontSize: "12px", fontWeight: "400", color: Black, fontFamily: "Courier New"}}>
                    <img src={v1_PartRevision_001} alt={"v1.PartRevision-001"}/>
                </p>

                <p style={{fontSize: "12px", fontWeight: "400", color: Black, fontFamily: "Courier New"}}>
                    <ReactJson src={part_revision_001} collapseStringsAfterLength={75} collapsed={4}/>
                </p>
            </p>
            <br/>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                <span style={{textDecoration: "underline"}}><b>v1.PartRevision-002</b></span><br/><br/>
                <span style={{lineHeight: "1.5em"}}>v1.PartRevision-002 is an IDOC designed to return a focused get of a single part record.</span>

                <p style={{fontSize: "12px", fontWeight: "400", color: Black, fontFamily: "Courier New"}}>
                    <img src={v1_PartRevision_002} alt={"v1.PartRevision-002"}/>
                </p>

                <p style={{fontSize: "12px", fontWeight: "400", color: Black, fontFamily: "Courier New"}}>
                    <ReactJson src={part_revision_002} collapseStringsAfterLength={75} collapsed={4}/>
                </p>
            </p>
            <br/>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                <span style={{textDecoration: "underline"}}><b>v1.PartRevision-003</b></span><br/><br/>
                <span style={{lineHeight: "1.5em"}}>v1.PartRevision-003 is an IDOC designed to return a focused get of a single part record.</span>

                <p style={{fontSize: "12px", fontWeight: "400", color: Black, fontFamily: "Courier New"}}>
                    <img src={v1_PartRevision_003} alt={"v1.PartRevision-003"}/>
                </p>

                <p style={{fontSize: "12px", fontWeight: "400", color: Black, fontFamily: "Courier New"}}>
                    <ReactJson src={part_revision_003} collapseStringsAfterLength={75} collapsed={4}/>
                </p>
            </p>
        </div>
    )
}