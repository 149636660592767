import React from "react";

import Applet from "../../Applet";
import BillSubscriptionTable from "../../../../components/BillConfiguration/BillSubscriptionTable";
import BillSubscriptionCommunicationTable from "../../../../components/BillConfiguration/BillSubscriptionCommunicationTable";

class BillSubscriptionCommunicationTableApplet extends Applet {

    render() {
        return <BillSubscriptionCommunicationTable applet={this}
                               pageSize={this.state.PageSize}
                               placement={this.state.Placement}
                               columns={this.state.Columns}
                               title="Subscription Communications"
                               enterpriseComponent={this.getEnterpriseComponent()}
                               sort={this.state.Sort}
                               menu={this.state.Menu}
                               rowHeight={this.state.RowHeight}
                               enableSort={true}
        />;
    }

    state= {
        Name: "BillSubscriptionCommunicationTableApplet",
        Menu: {
            options: [
                {
                    displayValue: "Refresh",
                    value: "Refresh",
                },
            ],
        },
        RowHeight: 50,
        PageSize: {
            "default": 20,
            "xs": 3,
            "sm": 3,
            "md": 20,
            "lg": 20,
            "lx": 20,
        },
        Placement: {
            "xl": [
                {
                    id: "RecordPointer",
                },
                {
                    id: "CreatedDatetime",
                },
                {
                    id: "Payload",
                },
                {
                    id: "Actions",
                },
            ],
        },
        Columns: {
            "RecordPointer": {
                id: "RecordPointer",
                width: 25,
                cellRenderer: "Row Selection Indicator Cell",
                header: null,
                headerRenderer: "",
                headerTitle: "",
            },
            "CreatedDatetime": {
                id: "CreatedDatetime",
                width: 300,
                cellRenderer: "Local Date Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Name",
            },
            "Payload": {
                id: "Payload",
                width: 300,
                cellRenderer: "Standard Single Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Payload",
            },
            "Actions": {
                id: "Actions",
                width: 50,
                cellRenderer: "Action Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "",
                pinned: "right",
                hideable: false,
            }
        },
        Sort: [
            {
                "Identifier": 1,
                "Visible": true,
                "Default": true,
                "Options": [{
                    "Direction": "ASC",
                    "SortAttribute": "Name",
                    "DisplayName": "Name",
                }],
            },
        ],
    }
}

export default BillSubscriptionCommunicationTableApplet;
