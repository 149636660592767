import React, {useEffect, useState} from "react";
import {Button, Typography} from "@mui/material";
import css_buttons from "../Common/css/CCButton.module.scss";
import css_typography from "../Common/css/Typography.module.scss";
import enterpriseObjectClassFactory from "../../eoec/EnterpriseObjectClassFactory";
import EnterpriseObject from "../../eoec/eo/EnterpriseObject";
import EnterpriseComponent from "../../eoec/ec/EnterpriseComponent";
import {prepareBuffer} from "../../util/Buffer";
import EventBus from "../../util/EventBus";
import BillConfigurationFormApplet from "../../eoec/applet/impl/BillConfiguration/BillConfigurationFormApplet";
import BillSubscriptionTableApplet from "../../eoec/applet/impl/BillConfiguration/BillSubscriptionTableApplet";
import BillSubscriptionCommunicationTableApplet from "../../eoec/applet/impl/BillConfiguration/BillSubscriptionCommunicationTableApplet";

export default function BillConfiguration() {
    const [billConfigurationEnterpriseObject] = useState(enterpriseObjectClassFactory("BillConfiguration") as EnterpriseObject);
    const [billConfigurationBuffer, setBillConfigurationBuffer] = React.useState<Map<string, any>>(new Map<string, any>());
    const [showConfigurationEditor, setShowConfigurationEditor] = React.useState(false);

    function enterpriseComponentLoadStartedListener(ec: EnterpriseComponent) {
    }

    function enterpriseComponentLoadCompletedListener(ec: EnterpriseComponent) {
        let b:Map<string, any>[] = prepareBuffer(ec)

        if (b.length > 0) {
            setBillConfigurationBuffer(b[0]);
        } else {
            setBillConfigurationBuffer(new Map<string, any>());
        }
    }

    function onCancelHandler(e: MouseEvent) {
        setShowConfigurationEditor(false);
    }

    useEffect(() => {
        EventBus.components.addListener("bill-configuration-form-applet-cancel", onCancelHandler);
        EventBus.components.addListener("bill-configuration-form-applet-save", onCancelHandler);

        billConfigurationEnterpriseObject.getEnterpriseComponent("BillConfiguration")?.setIDOCRequest("v1.BillConfiguration-001");
        billConfigurationEnterpriseObject.getEnterpriseComponent("BillConfiguration")?.setIDOCResponse("v1.BillConfiguration-001");
        billConfigurationEnterpriseObject.getEnterpriseComponent("BillConfiguration")?.addLoadStartedListener(enterpriseComponentLoadStartedListener);
        billConfigurationEnterpriseObject.getEnterpriseComponent("BillConfiguration")?.addLoadCompletedListener(enterpriseComponentLoadCompletedListener);
        billConfigurationEnterpriseObject.getEnterpriseComponent("BillConfiguration")?.executeQuery(1, 1);

        return () => {
            EventBus.components.removeListener("bill-configuration-form-applet-cancel", onCancelHandler);
            EventBus.components.removeListener("bill-configuration-form-applet-save", onCancelHandler);
        }
    }, [billConfigurationEnterpriseObject]);

    return (
        <div style={{display: "flex", flexDirection: "column", flexGrow: 1, width: "100%"}}>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "30px", width: "100%", marginBottom: "5px", marginRight: "20px"}}>
                <div style={{width: "100%"}}>
                    <Typography style={{fontSize: "22px", fontWeight: "500", color: "#1498D8"}}>Bill Configuration</Typography>
                </div>
            </div>
            <div style={{border: "1px solid #EDEFF3", width: "100%", padding: "10px", borderRadius: "5px"}}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "30px", width: "100%"}}>
                    <div style={{width: "100px"}}>
                        <Typography className={css_typography.DetailLabel} style={{marginBottom: 3}}>User Name:</Typography>
                    </div>
                    <div style={{width: "400px"}}>
                        <Typography className={css_typography.DetailData} style={{marginLeft: "10px"}}>{billConfigurationBuffer.get("UserName") ?? "--"}</Typography>
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "30px", width: "100%"}}>
                    <div style={{width: "100px"}}>
                        <Typography className={css_typography.DetailLabel} style={{marginBottom: 3}}>Password:</Typography>
                    </div>
                    <div style={{width: "400px"}}>
                        <Typography className={css_typography.DetailData} style={{marginLeft: "10px"}}>{billConfigurationBuffer.get("Password") ?? "--"}</Typography>
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "30px", width: "100%"}}>
                    <div style={{width: "100px"}}>
                        <Typography className={css_typography.DetailLabel} style={{marginBottom: 3}}>Organization ID:</Typography>
                    </div>
                    <div style={{width: "400px"}}>
                        <Typography className={css_typography.DetailData} style={{marginLeft: "10px"}}>{billConfigurationBuffer.get("OrganizationID") ?? "--"}</Typography>
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "30px", width: "100%"}}>
                    <div style={{width: "100px"}}>
                        <Typography className={css_typography.DetailLabel} style={{marginBottom: 3}}>Developer Key:</Typography>
                    </div>
                    <div style={{width: "400px"}}>
                        <Typography className={css_typography.DetailData} style={{marginLeft: "10px"}}>{billConfigurationBuffer.get("DeveloperKey") ?? "--"}</Typography>
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "30px", width: "100%"}}>
                    <div style={{width: "100px"}}>
                        <Typography className={css_typography.DetailLabel} style={{marginBottom: 3}}>V2 API Url:</Typography>
                    </div>
                    <div style={{width: "400px"}}>
                        <Typography className={css_typography.DetailData} style={{marginLeft: "10px"}}>{billConfigurationBuffer.get("V2APIUrl") ?? "--"}</Typography>
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "30px", width: "100%"}}>
                    <div style={{width: "100px"}}>
                        <Typography className={css_typography.DetailLabel} style={{marginBottom: 3}}>V3 API Url:</Typography>
                    </div>
                    <div style={{width: "400px"}}>
                        <Typography className={css_typography.DetailData} style={{marginLeft: "10px"}}>{billConfigurationBuffer.get("V3APIUrl") ?? "--"}</Typography>
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%", marginTop: "20px"}}>
                    <Button className={css_buttons.blueStandardButton} style={{cursor: "pointer", height: "35px"}} size="small"
                        onClick={() => {
                            setShowConfigurationEditor(true);
                        }}>
                        <Typography style={{margin: "0 0 0 6px", fontSize: "13px", fontWeight: 500, color: "#FFFFFF"}}>Edit Configuration</Typography>
                    </Button>
                    {showConfigurationEditor ? (
                        <BillConfigurationFormApplet enterpriseComponent={billConfigurationEnterpriseObject.getEnterpriseComponent("BillConfiguration")} availableModes={["Edit", "New", "View"]} defaultMode="Edit" />
                    ): null}
                </div>
            </div>
            <div>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%", marginTop: "50px"}}>
                    <BillSubscriptionTableApplet enterpriseComponent={billConfigurationEnterpriseObject.getEnterpriseComponent("BillSubscription")} />
                </div>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%", marginTop: "50px"}}>
                    <BillSubscriptionCommunicationTableApplet enterpriseComponent={billConfigurationEnterpriseObject.getEnterpriseComponent("BillSubscriptionCommunication")} />
                </div>
            </div>
        </div>
    );
}