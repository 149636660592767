import React from "react";
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import Logout from "./components/Logout/Logout";
import Home from "./pages/Home";
import ApiAndTools from "./pages/ApiAndTools";
import Community from "./pages/Community";
import Reference from "./components/APIDocsTools/Reference";
import Dashboard from "./pages/Dashboard";
import useAppContext from "./util/AppContext";
import DashboardLogManagement from "./pages/DashboardLogManagement";
import DashboardEventManagement from "./pages/DashboardEventManagement";
import DashboardEndpointManagement from "./pages/DashboardEndpointManagement";
import DashboardExceptionManagement from "./pages/DashboardExceptionManagement";
import DashboardSandboxManagement from "./pages/DashboardSandboxManagement";
import DashboardBillConfiguration from "./pages/DashboardBillConfiguration";
import DashboardMonitoring from "./pages/DashboardMonitoring";
import DashboardRestAPIManagement from "./pages/DashboardRestAPIManagement";


export default function AppRoutes() {
    const location = useLocation();
    const appContext = useAppContext();

    return (
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/logout" element={<Logout />} />
            <Route exact path="/home" element={<Home />} />
            <Route exact path="/dashboard" element={appContext?.sessionContext.valid() ? <Dashboard/> : <Home/>} />
            <Route exact path="/dashboard/bill-configuration" element={<DashboardBillConfiguration/>} />
            <Route exact path="/dashboard/log-management" element={<DashboardLogManagement />} />
            <Route exact path="/dashboard/sandbox-management" element={<DashboardSandboxManagement />} />
            <Route exact path="/dashboard/exception-management" element={<DashboardExceptionManagement />} />
            <Route exact path="/dashboard/monitoring" element={<DashboardMonitoring />} />
            <Route exact path="/dashboard/event-management" element={<DashboardEventManagement />} />
            <Route exact path="/dashboard/endpoint-management" element={<DashboardEndpointManagement />} />
            <Route exact path="/dashboard/rest-api-management" element={<DashboardRestAPIManagement />} />
            <Route path="/api-docs-tools/*" element={<ApiAndTools />} />
            <Route exact path="/community" element={<Community />} />
            <Route exact path="/community/aiplayground" element={<Reference />} />
            <Route exact path="/community/discussions" element={<Reference />} />
            <Route exact path="/community/blogs" element={<Reference />} />
            <Route exact path="/community/enlilanswers" element={<Reference />} />
            <Route exact path="/community/enlilanswers/ask" element={<Reference />} />
            <Route exact path="/community/enlilanswers/answer" element={<Reference />} />
            <Route exact path="/community/enlilanswers/browse" element={<Reference />} />
            <Route exact path="/community/enlilanswers/contributors" element={<Reference />} />
            <Route exact path="/community/enlilanswers/recentactivity" element={<Reference />} />
            <Route exact path="/community/enlilanswers/help" element={<Reference />} />
            <Route path="/*" element={<Navigate to="/" replace state={{
                referrer: {
                    pathname: location.pathname,
                    search: location.search,
                },
            }} />} />
        </Routes>
    );
}