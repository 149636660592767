import EnterpriseComponentAttributeMetadata from "../../EnterpriseComponentAttributeMetadata";
import EnterpriseComponentMetadata from "../../EnterpriseComponentMetadata";

class BillSubscriptionCommunicationEnterpriseComponentMetadata extends EnterpriseComponentMetadata {

    constructor() {
        super();

        this.name = "BillSubscriptionCommunication";
        this.defaultCacheSize = 50;

        this.attributes["Payload"] = new EnterpriseComponentAttributeMetadata(
            "Payload", // name
            false, // NoCopy
            false, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "String", // Type
            null, // default value
            false, // computed
        );
        this.attributes["BillSubscriptionUSID"] = new EnterpriseComponentAttributeMetadata(
            "BillSubscriptionUSID", // name
            false, // NoCopy
            true, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "USID", // Type
            null, // default value
            false, // computed
        );
        this.attributes["CreatedDatetime"] = new EnterpriseComponentAttributeMetadata(
            "CreatedDatetime", // name
            false, // NoCopy
            true, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "Datetime", // Type
            null, // default value
            false, // computed
        );
        this.attributes["LastModifiedDatetime"] = new EnterpriseComponentAttributeMetadata(
            "LastModifiedDatetime", // name
            false, // NoCopy
            true, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "Datetime", // Type
            null, // default value
            false, // computed
        );
        this.attributes["USID"] = new EnterpriseComponentAttributeMetadata(
            "USID", // name
            false, // NoCopy
            true, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            36, // length
            null, // minLength
            null, // pattern
            "UUID", // Type
            null, // default value
            false, // computed
        );
    }
}

export default BillSubscriptionCommunicationEnterpriseComponentMetadata;
