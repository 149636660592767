import {v4 as uuidv4} from "uuid";
import EventBus from "../../util/EventBus";
import EnterpriseComponentAttribute from "./EnterpriseComponentAttribute";
import enterpriseComponentMetadataClassFactory from "../../metadata/EnterpriseComponentMetadataClassFactory";
import EnterpriseComponentMetadata, {IAttributeDictionary} from "../../metadata/ec/EnterpriseComponentMetadata";
import EnterpriseComponentAttributeMetadata from "../../metadata/ec/EnterpriseComponentAttributeMetadata";
import EnterpriseObject from "../eo/EnterpriseObject";
import Relationship from "../relationship/Relationship";

type SortSpecification = {
    Direction: string,
    DisplayName: string,
    SortAttribute: string
}

class EnterpriseComponent {
    public MODE_NEW: number = 100;
    public MODE_QUERY: number = 101;
    public MODE_IDLE: number = 102;
    public MODE_RESULTS: number = 103;
    public MODE_DELETE: number = 104;
    public MODE_UPDATE: number = 105;

    visibilityMode: string | null = null;
    visibilityValue: string | null = null;
    attributes: IAttributeDictionary<EnterpriseComponentAttribute> = {};
    recordPointer: number = -1;
    pagePointer: number = -1;
    enterpriseObject: EnterpriseObject | null = null;
    isComponentValid: boolean = false;
    resultsBuffer: Map<number, Map<string, any>[]> = new Map();
    queryBuffer: Map<number, Map<string, any>[]> = new Map();
    recordWillChangeListeners: object[] = [];
    recordChangedListeners: object[] = [];
    invalidListeners: object[] = [];
    startNewRecordModeListeners: object[] = [];
    startEditRecordModeListeners: object[] = [];
    singleRecordRefreshStartedListeners: object[] = [];
    singleRecordRefreshCompletedListeners: object[] = [];
    insertRollbackCompletedListeners: object[] = [];
    loadStartedListeners: object[] = [];
    updateRollbackCompletedListeners: object[] = [];
    queryRollbackCompletedListeners: object[] = [];
    loadCompletedListeners: object[] = [];
    valueChangedListeners: object[] = [];
    updateStartedListeners: object[] = [];
    updateCompletedListeners: object[] = [];
    insertStartedListeners: object[] = [];
    insertCompletedListeners: object[] = [];
    deleteStartedListeners: object[] = [];
    deleteCompletedListeners: object[] = [];
    newQueryListeners: object[] = [];
    newDeleteListeners: object[] = [];
    searchSpecification: IAttributeDictionary<String> = {};
    sortSpecification: SortSpecification[] = [];
    totalRowcount: number = -1;
    waiting: boolean = false;
    activeTimeout: NodeJS.Timeout | null = null;
    mode: number = this.MODE_IDLE;
    supportingData = {};
    idocRequest: string | undefined = undefined;
    idocResponse: string | undefined = undefined;
    error: string | null = null;

    store = {}

    enterpriseComponentMetadata: EnterpriseComponentMetadata;

    constructor(name: string) {
        let metadataClass:Object | undefined = enterpriseComponentMetadataClassFactory(name);
        // @ts-ignore
        this.enterpriseComponentMetadata = new (metadataClass)(name);

        let metadataAttributes: IAttributeDictionary<EnterpriseComponentAttributeMetadata> = this.enterpriseComponentMetadata.getAttributes();

        for (const key of Object.keys(metadataAttributes)) {
            let md = metadataAttributes[key];

            this.attributes[md.getName()] = new EnterpriseComponentAttribute(this, md);
        }

    };

    addRecordWillChangeListener = (newListener: any) => {
        // @ts-ignore
        if (!this.recordWillChangeListeners.some(listener => listener.applet.getName() === newListener.applet.getName())) {
            this.recordWillChangeListeners.push(newListener);
        }
    };

    addRecordChangedListener = (newListener: any) => {
        // @ts-ignore
        if (!this.recordChangedListeners.some(listener => listener.getName() === newListener.getName())) {
            this.recordChangedListeners.push(newListener);
        }
    };

    addDeleteCompletedListener(newListener: any) {
        // @ts-ignore
        if (!this.deleteCompletedListeners.some(listener => listener.getName() === newListener.getName())) {
            this.deleteCompletedListeners.push(newListener);
        }
    };

    addDeleteStartedListener(newListener: any) {
        // @ts-ignore
        if (!this.deleteStartedListeners.some(listener => listener.getName() === newListener.getName())) {
            this.deleteStartedListeners.push(newListener);
        }
    };

    addInvalidListener(newListener: any) {
        // @ts-ignore
        if (!this.invalidListeners.some(listener => listener.getName() === newListener.getName())) {
            this.invalidListeners.push(newListener);
        }
    };

    addInsertRollbackCompletedListener(newListener: any) {
        // @ts-ignore
        if (!this.insertRollbackCompletedListeners.some(listener => listener.getName() === newListener.getName())) {
            this.insertRollbackCompletedListeners.push(newListener);
        }
    };

    addLoadCompletedListener(newListener: any) {
        // @ts-ignore
        if (!this.loadCompletedListeners.includes(newListener)) {
            this.loadCompletedListeners.push(newListener);
        }
    };

    addValueChangedListener(newListener: any) {
        // @ts-ignore
        if (!this.valueChangedListeners.some(listener => listener.getName() === newListener.getName())) {
            this.valueChangedListeners.push(newListener);
        }
    };

    addLoadStartedListener(newListener: any) {
        // @ts-ignore
        if (!this.loadStartedListeners.includes(newListener)) {
            this.loadStartedListeners.push(newListener);
        }
    };

    addNewDeleteListener(newListener: any) {
        // @ts-ignore
        if (!this.newDeleteListeners.some(listener => listener.getName() === newListener.getName())) {
            this.newDeleteListeners.push(newListener);
        }
    };

    addNewQueryListener(newListener: any) {
        // @ts-ignore
        if (!this.newQueryListeners.some(listener => listener.getName() === newListener.getName())) {
            this.newQueryListeners.push(newListener);
        }
    };

    addStartNewRecordModeCompletedListener (newListener: any) {
        // @ts-ignore
        if (!this.startNewRecordModeListeners.some(listener => listener.getName() === newListener.getName())) {
            this.startNewRecordModeListeners.push(newListener);
        }
    };

    addStartEditRecordModeCompletedListener(newListener: any) {
        // @ts-ignore
        if (!this.startEditRecordModeListeners.some(listener => listener.getName() === newListener.getName())) {
            this.startEditRecordModeListeners.push(newListener);
        }
    };

    addSingleRecordRefreshStartedListener(newListener: any) {
        // @ts-ignore
        if (!this.singleRecordRefreshStartedListeners.some(listener => listener.getName() === newListener.getName())) {
            this.singleRecordRefreshStartedListeners.push(newListener);
        }
    };

    addSingleRecordRefreshCompletedListener(newListener: any) {
        // @ts-ignore
        if (!this.singleRecordRefreshCompletedListeners.some(listener => listener.getName() === newListener.getName())) {
            this.singleRecordRefreshCompletedListeners.push(newListener);
        }
    };

    addUpdateCompletedListener(newListener: any) {
        // @ts-ignore
        if (!this.updateCompletedListeners.some(listener => listener.getName() === newListener.getName())) {
            this.updateCompletedListeners.push(newListener);
        }
    };

    addUpdateRollbackCompletedListener(newListener: any) {
        // @ts-ignore
        if (!this.updateRollbackCompletedListeners.some(listener => listener.getName() === newListener.getName())) {
            this.updateRollbackCompletedListeners.push(newListener);
        }
    };

    addUpdateStartedListener(newListener: any) {
        // @ts-ignore
        if (!this.updateStartedListeners.some(listener => listener.getName() === newListener.getName())) {
            this.updateStartedListeners.push(newListener);
        }
    };

    addInsertCompletedListener(newListener: any) {
        // @ts-ignore
        if (!this.insertCompletedListeners.some(listener => listener.getName() === newListener.getName())) {
            this.insertCompletedListeners.push(newListener);
        }
    };

    addInsertStartedListener(newListener: any) {
        // @ts-ignore
        if (!this.insertStartedListeners.some(listener => listener.getName() === newListener.getName())) {
            this.insertStartedListeners.push(newListener);
        }
    };

    addQueryRollbackCompletedListener(newListener: any) {
        // @ts-ignore
        if (!this.queryRollbackCompletedListeners.some(listener => listener.getName() === newListener.getName())) {
            this.queryRollbackCompletedListeners.push(newListener);
        }
    };

    canChangeAttributeValue(attributeName: string): boolean {
        if (this.mode === this.MODE_IDLE) {
            return false;
        }

        let attribute = this.getAttributes()[attributeName];

        if (attribute === null) {
            return false;
        }

        return !(this.mode === this.MODE_RESULTS && this.getBuffer().size === 0);
    };

    clearAttribute(attributeName: string) {
        if (!this.canChangeAttributeValue(attributeName)) {
            return false;
        }

        let row: Map<string, any> = this.getBuffer().get(this.getPagePointer())![this.getRecordPointer()]

        row.set("__operation", "query results");

        row.set(attributeName, null);

        if (row.get(attributeName + "_original") !== undefined) {
            row.delete(attributeName + "_original");
        }
        if (row.get(attributeName + "_old") !== undefined) {
            row.delete(attributeName + "_old");
        }

        this.notifyValueChangedListeners();
    };

    deleteEnterpriseComponentFailed() {
        EventBus.error.emit("show-system-error");
    };

    deleteEnterpriseComponentSuccess() {
        if (this.getBuffer().size > 0 && this.getBuffer().get(this.getPagePointer())!.length > 0) {
            this.getBuffer().get(this.getPagePointer())!.splice(this.getRecordPointer(), 1);
            if (this.getBuffer().get(this.getPagePointer())!.length > 0) {
                if (this.getRecordPointer() > 0) this.recordPointer = this.recordPointer - 1;
            }
            else this.recordPointer = -1;
        }
        if (this.getBuffer().size > 0 && this.getBuffer().get(this.getPagePointer())!.length > 0) {
            this.getBuffer().get(this.getPagePointer())![this.getRecordPointer()].set("RowSelected", true);
        }
        if (this.getBuffer().size === 1 && this.getBuffer().get(this.getPagePointer())!.length === 0) {
            this.totalRowcount = 0;
        } else {
            this.totalRowcount -= 1;
        }
        this.mode = this.MODE_RESULTS;

        this.isComponentValid = true;

        this.notifyDeleteCompletedListeners();
    };

    editRecord = () => {
        this.mode = this.MODE_UPDATE;

        this.notifyStartEditRecordModeListeners();
    };

    executeDelete() {
        this.performDelete();
    };

    executeQuery = (page: number, pageSize: number) => {
        if (this.waiting) {
            if (this.activeTimeout !== null) {
                clearTimeout(this.activeTimeout);
            }
            this.activeTimeout = setTimeout(() => {
                this.executeQuery(page, pageSize);
            }, 1000);
            return;
        }
        this.waiting = true;

        let foreignKey = null;
        if (this.getEnterpriseObject() === null) {
            this._executeQuery(page, pageSize, foreignKey);
        } else {
            let relationship: Relationship | undefined = this.getEnterpriseObject()!.getRelationshipForChildEnterpriseComponent(this);
            if (relationship === undefined) {
                this._executeQuery(page, pageSize, foreignKey);
            } else {
                let parentEnterpriseComponent: EnterpriseComponent = relationship.getParentEnterpriseComponent()!;

                if (!parentEnterpriseComponent!.isValid()) {
                    this.waiting = false;
                    this.notifyLoadCompletedListeners();
                    return;
                }

                if (parentEnterpriseComponent!.getBuffer().size === 0) {
                    this.waiting = false;
                    this.isComponentValid = true;
                    this.invalidateChildren();
                    this.notifyLoadCompletedListeners();
                } else {
                    if (parentEnterpriseComponent!.getRecordPointer() !== -1) {
                        let p: Map<string, any>[] = parentEnterpriseComponent!.getBuffer().get(parentEnterpriseComponent!.getPagePointer())!
                        
                        foreignKey = p[parentEnterpriseComponent.getRecordPointer()].get(relationship.getSourceAttribute());
                    }
                }

                if (foreignKey !== null) {
                    this._executeQuery(page, pageSize, foreignKey);
                }
            }
        }
    };

    _executeQuery = (page: number, pageSize: number, foreignKey: string | null) => {
        if (page === undefined || pageSize === undefined) {
            this.resultsBuffer = new Map();
            this.performQuery(1, 100, foreignKey);
        } else {
            if (this.mode === this.MODE_QUERY) {
                this.resultsBuffer = new Map();
                this.error = null;
                this.performQuery(page, pageSize, foreignKey);
                this.mode = this.MODE_RESULTS;
            } else if (this.resultsBuffer.size === 0 && this.totalRowcount === 0) {
                this.isComponentValid = true;

                this.waiting = false;
                this.notifyLoadCompletedListeners();
            } else if (this.resultsBuffer.get(page) !== undefined) {
                this.isComponentValid = true;
                this.pagePointer = page;

                this.waiting = false;
                this.notifyLoadCompletedListeners();
            } else {
                if (this.pagePointer !== page) this.recordPointer = -1;
                this.mode = this.MODE_QUERY;
                this.error = null;
                this.performQuery(page, pageSize, foreignKey);
                this.mode = this.MODE_RESULTS;
            }
        }
    };

    executeInsert = () => {
        this.performInsert();
    };

    executeInsertRollback = () => {
        this.mode = this.MODE_RESULTS;

        if (this.getBuffer().size > 0 && this.getBuffer().get(this.getPagePointer())!.length > 0) {
            let operation = this.resultsBuffer.get(this.getPagePointer())![this.getRecordPointer()].get("__operation");
            if (operation && operation === "new") {
                this.resultsBuffer.get(this.getPagePointer())!.splice(this.getRecordPointer(), 1);
            }

            if (this.getBuffer().size > 0 && this.getBuffer().get(this.getPagePointer())!.length > 0) {
                for (let i = 0; i < this.getBuffer().get(this.getPagePointer())!.length; i++) {
                    this.getBuffer().get(this.getPagePointer())![i].set("RowSelected", false);
                    this.getBuffer().get(this.getPagePointer())![i].set("RowNum", i);
                }

                this.getBuffer().get(this.getPagePointer())![this.getRecordPointer()].set("RowSelected", true);
            } else {
                this.setRecordPointer(-1);
            }
        } else {
            this.setRecordPointer(-1);
        }
        this.notifyInsertRollbackCompletedListeners();
    };

    executeQueryRollback = () => {
        this.mode = this.MODE_RESULTS;

        this.notifyQueryRollbackCompletedListeners();
    };

    executeSingleRecordRefresh = () => {
        if (this.singleRecordRefreshCompletedListeners.length === 0) {
            return;
        }

        if (this.waiting) {
            if (this.activeTimeout !== null) {
                clearTimeout(this.activeTimeout);
            }
            this.activeTimeout = setTimeout(() => {
                let record: Map<string, any> | undefined = this.getCurrentRecord();
                if (record) this.performSingleRecordRefresh(record.get("USID"));
            }, 1000);
            return;
        }

        let record = this.getCurrentRecord();
        if (record) {
            this.waiting = true;
            this.performSingleRecordRefresh(record.get("USID"));
        }
    };

    executeUpdate = () => {
        this.performUpdate();

        let row: Map<string, any> | undefined = this.getCurrentRecord();
        if (row === undefined) return;

        for (const key of Object.keys(this.getAttributes())) {
            let attribute = this.getAttributes()[key];

            if (row.get(attribute.getName() + "_original") !== undefined) {
                row.delete(attribute.getName() + "_original");
                row.delete(attribute.getName() + "_old");
            }
        }
        row.set("__operation", "query results");
    };

    executeUpdateRollback() {
        this.mode = this.MODE_RESULTS;

        let row = this.getCurrentRecord();
        if (row === undefined) return;

        for (const key of Object.keys(this.getAttributes())) {
            let attribute: EnterpriseComponentAttribute = this.getAttributes()[key];

            if (row.get(attribute.getName() + "_original") !== undefined) {
                row.set(attribute.getName(), row.get(attribute.getName() + "_original"));
                row.delete(attribute.getName() + "_original");
                row.delete(attribute.getName() + "_old");
            }
        }
        row.set("__operation", "query results");

        this.notifyUpdateRollbackCompletedListeners();
    };

    fetchEnterpriseComponentFailed() {
        EventBus.error.emit("show-system-error");
    };

    fetchEnterpriseComponentSuccess(data: any, page: number, pageSize: number) {
        this.mode = this.MODE_RESULTS;

        if (data["Results"].length !== 0) {
            let existingRows: Map<string, any>[] | undefined = this.resultsBuffer.get(page);
            if (existingRows) {
                let newArray: Map<string, any>[] = existingRows.concat(data["Results"]);
                this.resultsBuffer.set(page, newArray);
            } else {
                let newPage: Map<string, any>[] = [];
                for (let i = 0; i < data.Results.length; i++) {
                    let newRow = new Map<string, any>()
                    for (let key of Object.keys(data.Results[i])) {
                        newRow.set(key, data.Results[i][key])
                    }
                    newPage.push(newRow)
                }
                this.resultsBuffer.set(page, newPage);
            }

            let b: Map<string, any>[] = this.resultsBuffer.get(page)!;
            for (let i = 0; i < b.length; i++) {
                b[i].set("RowNum", i);
            }
        }

        if (data["Error"] !== undefined) {
            this.error = data["Error"];
        }

        if (data["Count"] === undefined) {
            if (data["Results"].length < pageSize) {
                this.totalRowcount = this.resultsBuffer.size;
            } else {
                this.totalRowcount = -1;
            }
        } else {
            let count: any = data["Count"];
            if (count) {
                this.totalRowcount = parseInt(count);
            }
        }

        this.isComponentValid = true;

        this.pagePointer = page;

        if (this.recordPointer < 1) {
            if (data["Results"].length > 0) {
                this.recordPointer = 0;
                this.resultsBuffer.get(this.getPagePointer())![0].set("RowSelected", true);
            }
            if (this.getEnterpriseObject() !== null) {
                let childEnterpriseComponents: Map<string, EnterpriseComponent> = this.getEnterpriseObject()!.getChildEnterpriseComponents(this);

                for (let key of childEnterpriseComponents.keys()) {
                    let childEnterpriseComponent: EnterpriseComponent | undefined = childEnterpriseComponents.get(key);

                    if (childEnterpriseComponent) {
                        for (let j = 0; j < data["Results"].length; j++) {
                            let childDataResults = data["Results"][j]["ListOf" + key];

                            if (childDataResults !== undefined) {
                                childEnterpriseComponent.fetchEnterpriseComponentSuccess(childDataResults, 1, 1000);
                            } else {
                                childEnterpriseComponent.invalidate();
                            }
                        }
                    }
                }
            }
            this.notifyLoadCompletedListeners();
        } else {
            this.notifyLoadCompletedListeners();
            this.nextRecord();
        }
        this.waiting = false;
    };

    firstRecord(enable_events = true) {
        if (this.hasEnterpriseComponentBeenModified()) {
            return false;
        }

        if (!this.isValid()) {
            return false;
        }

        if (this.getBuffer().size === 0) return false;

        this.recordPointer = 0;
        this.pagePointer = 1;

        let row: Map<string, any> | undefined = this.getCurrentRecord();
        if (row === undefined) return false;

        if (row.get("RowSelected") === true) {
            row.set("RowSelected", false);
        }

        let found: boolean = false;
        if (this.enterpriseObject !== null) {
            let relationship: Relationship | undefined = this.enterpriseObject.getRelationshipForChildEnterpriseComponent(this);
            if (relationship) {
                let value: any = this.getSourceAttributeValueForParent();
                for (let i = 0; i < this.getBuffer().get(this.getPagePointer())!.length; i++) {
                    let page: Map<string, any>[] | undefined = this.getBuffer().get(this.getPagePointer())
                    if (page && page![this.recordPointer].get(relationship.getDestinationAttribute()) === value) {
                        found = true;
                        break;
                    } else {
                        this.recordPointer++;
                    }
                }
            } else {
                found = true;
            }
        } else {
            found = true;
        }

        if (found) {
            this.getBuffer().get(this.getPagePointer())![this.recordPointer].set("RowSelected", true);
            if (enable_events) this.notifyRecordChangedListeners();
            this.invalidateChildren();
            return true;
        }

        return false;
    };

    forceAttributeValue = (attributeName: string, attributeValue: any) => {
        if (!this.canChangeAttributeValue(attributeName)) {
            return false;
        }

        let row: Map<string, any> | undefined = this.getCurrentRecord();
        if (row === undefined) return false;

        if (attributeValue === "NULL") {
            attributeValue = null;
        }
        row.set(attributeName, attributeValue);

        row.set("__operation", "update");
        row.set(attributeName + "_original", attributeValue);
        row.set(attributeName + "_old", attributeValue);

        this.notifyValueChangedListeners();
    };

    getAttributes() {
        return this.attributes;
    };

    getAttribute(name: string) {
        return this.getAttributes()[name];
    };

    getAttributeValue(attributeName: string, type: string) {
        if (this.getRecordPointer() === -1) return null;

        let value;
        let row: Map<string, any>;

        let suffix: string = "";

        if (type === "original") {
            suffix = "_original";
        }

        if (this.mode === this.MODE_QUERY) {
            row = this.getBuffer().get(this.getPagePointer())![0];
            value = row.get(attributeName + suffix);
        } else {
            row = this.getBuffer().get(this.getPagePointer())![this.getRecordPointer()];
            value = row.get(attributeName + suffix);
        }

        return value;
    };

    getAttributeValueAsString = (attributeName: string, type: string) => {
        let value = this.getAttributeValue(attributeName, type);
        let out;

        if (value === null || value === undefined) {
            out = null;
        } else {
            out = value.toString();
        }
        return out;
    };

    getBuffer(): Map<number, Map<string, any>[]> {
        if (this.mode === this.MODE_QUERY) {
            return this.queryBuffer;
        } else if (this.mode === this.MODE_RESULTS) {
            return this.resultsBuffer;
        } else if (this.mode === this.MODE_NEW) {
            return this.resultsBuffer;
        } else if (this.mode === this.MODE_IDLE) {
            return this.resultsBuffer;
        } else if (this.mode === this.MODE_UPDATE) {
            return this.resultsBuffer;
        } else if (this.mode === this.MODE_DELETE) {
            return this.resultsBuffer;
        }
        return new Map<number, Map<string, any>[]>();
    };

    getCurrentRecord(): Map<string, any> | undefined {
        let page: Map<string, any>[] | undefined = this.getBuffer().get(this.getPagePointer());
        if (!page) return undefined;

        try {
            return page[this.getRecordPointer()];
        } catch (error) {
            return undefined;
        }
    };

    getDefaultCacheSize() {
        return this.enterpriseComponentMetadata.defaultCacheSize;
    };

    getEnterpriseObject(): EnterpriseObject | null {
        return this.enterpriseObject;
    };

    getError() {
        return this.error;
    };

    getFormattedSortSpecification() {
        let out: string | null = "";
        for (let i: number = 0; i < this.getSortSpecification().length; i++) {
            let sortSpec: SortSpecification = this.getSortSpecification()[i];

            if (i !== 0) {
                out = out + ",";
            }
            out = out + sortSpec.SortAttribute + ":" + sortSpec.Direction;
            i++;
        }

        if (out === "") out = null;

        return out;
    };

    getIDOCRequest() {
        return this.idocRequest;
    };

    getIDOCResponse = () => {
        return this.idocResponse;
    };

    getMode = () => {
        return this.mode;
    };

    getName(): string {
        return this.enterpriseComponentMetadata.getName();
    };

    getPagePointer() {
        if (this.mode === this.MODE_QUERY) {
            return 1;
        } else if (this.mode === this.MODE_NEW) {
            return 1;
        }
        return this.pagePointer;
    };

    getParentEnterpriseComponent(): EnterpriseComponent | undefined {
        if (this.getEnterpriseObject() === null) {
            return undefined;
        }
        
        let relationship: Relationship | undefined = this.getEnterpriseObject()!.getRelationshipForChildEnterpriseComponent(this);

        if (relationship) {
            return relationship.getParentEnterpriseComponent();
        }
        return undefined;
    };

    getRecordPointer(): number {
        return this.recordPointer;
    };

    getSearchSpecification() {
        return this.searchSpecification;
    };

    getSelectedRecordNumber() {
        if (this.getBuffer().size !== 0) {
            for (let i = 0; i < this.getBuffer().get(this.getPagePointer())!.length; i++) {
                // @ts-ignore
                if (this.getBuffer().get(this.getPagePointer())![i].RowSelected === true) {
                    return i;
                }
            }
        }
        return -1;
    };

    getSortSpecification() {
        return this.sortSpecification;
    };

    getSourceAttributeValueForParent() {
        let out: any = undefined;

        let parentEnterpriseComponent: EnterpriseComponent | undefined = this.getParentEnterpriseComponent();
        if (parentEnterpriseComponent === undefined) {
            return out;
        }

        let parentECRecordPointer = parentEnterpriseComponent.getRecordPointer();
        let relationship: Relationship = this.getEnterpriseObject()!.getRelationshipForChildEnterpriseComponent(this)!;
        let parentPage: Map<string, any>[] = parentEnterpriseComponent?.getBuffer().get(parentEnterpriseComponent.getPagePointer())!
        let row: Map<string, any> = parentPage[parentECRecordPointer];
        return row.get(relationship.getSourceAttribute());
    };

    getStore = () => {
        return this.store;
    }

    getTotalRowcount = () => {
        return this.totalRowcount;
    };

    getVisibilityMode = () => {
        return this.visibilityMode;
    };

    getVisibilityValue = () => {
        return this.visibilityValue;
    };

    hasEnterpriseComponentBeenModified = () => {
        if (this.getBuffer().size === 0) {
            return false;
        }

        let page: Map<string, any>[] | undefined = this.getBuffer().get(this.getPagePointer());
        if (page === undefined) {
            return false;
        }

        for (let i = 0; i < page.length; i++) {
            let row: Map<string, any> = page[this.getRecordPointer()];

            if (row.get("__operation") !== "query results" && row.get("__operation") !== "delete") {
                return true;
            }

            /*
            for child in self.get_enterprise_object().get_child_enterprise_components(self).values() {
                if child.has_enterprise_component_been_modified() {
                    return true;
                }
                return false;
            }
            */
        }
        return false;
    };

    insertEnterpriseComponentFailed = () => {
        EventBus.error.emit("show-system-error");
    };

    insertEnterpriseComponentSuccess = (data: any) => {
        if (data.Results !== undefined && data.Results.length === 1) {
            this.totalRowcount += 1;

            let keys = Object.keys(data.Results[0]);
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];

                let page: Map<string, any>[] | undefined = this.getBuffer().get(this.getPagePointer());
                page![this.getRecordPointer()].set(key, data.Results[0][key]);
            }
        }

        for (let i = 0; i < this.getBuffer().size; i++) {
            let row: Map<string, any> = this.getBuffer().get(this.getPagePointer())![i];

            row.set("RowNum", i);
            row.set("__operation", "query results");
        }

        let row = this.getBuffer().get(this.getPagePointer())![this.getRecordPointer()];

        let keys = Object.keys(this.getAttributes());
        for (let i = 0; i < keys.length; i++) {
            let attribute = this.getAttributes()[keys[i]];
            row.set(attribute.getName(), data["Results"][0][attribute.getName()]);
            row.delete(attribute.getName() + "_old");
            row.delete(attribute.getName() + "_original");
        }

        this.mode = this.MODE_RESULTS;

        if (data["Count"] === undefined) {
            this.totalRowcount = -1;
        } else {
            let count: any = data["Count"];
            if (count) {
                this.totalRowcount = parseInt(count);
            }
        }

        this.isComponentValid = true;

        this.getBuffer().get(this.getPagePointer())![this.getRecordPointer()].set("RowSelected", true);

        this.notifyInsertCompletedListeners();
    };

    invalidate() {
        this.recordPointer = -1;
        this.resultsBuffer = new Map();
        this.queryBuffer = new Map();
        this.isComponentValid = false;
        this.totalRowcount = -1;
        this.mode = this.MODE_IDLE;
        this.error = null;

        this.invalidateChildren();

        this.notifyInvalidListeners();
    };

    invalidateChildren() {
        if (this.enterpriseObject !== null) {
            let children: Map<string, EnterpriseComponent> = this.enterpriseObject.getChildEnterpriseComponents(this);
            for (let key of children.keys()) {
                let childEnterpriseComponent: EnterpriseComponent = children.get(key)!;
                let relationship: Relationship | undefined = this.getEnterpriseObject()!.getRelationshipForChildEnterpriseComponent(childEnterpriseComponent);
                if (relationship !== undefined) {
                    if (relationship.getInvalidateChildren() === null || relationship.getInvalidateChildren() === undefined || relationship.getInvalidateChildren()) {
                        childEnterpriseComponent.invalidate();
                    }
                }
            }
        }
    };

    isEmpty = () => {
        if (this.getRecordPointer() === -1) return true;

        if (!this.isValid()) {
            return true;
        }

        if (this.getBuffer().size === 0) return true;

        return this.getBuffer().get(0)?.length === 0;
    };

    isDeleteMode = () => {
        return this.mode === this.MODE_DELETE;
    };

    isQueryMode = () => {
        return this.mode === this.MODE_QUERY;
    };

    isNewMode = () => {
        return this.mode === this.MODE_NEW;
    };

    isViewMode = () => {
        return this.mode === this.MODE_RESULTS;
    };

    isPrimaryEnterpriseComponent = () => {
        return this.getParentEnterpriseComponent() === null;
    };

    isUpdateMode = () => {
        return this.mode === this.MODE_UPDATE;
    };

    isValid = () => {
        return this.isComponentValid;
    };

    isLastRecord = () => {
        return this.getRecordPointer() === this.totalRowcount - 1;
    };

    lastRecord(enable_events = true) {
        if (this.hasEnterpriseComponentBeenModified()) {
            return false;
        }

        if (!this.isValid()) {
            return false;
        }

        let out;
        /*
        let page: Map<string, any>[] | undefined = this.getBuffer().get(this.getPagePointer())
        if (!page || page!.length === 0 || this.getRecordPointer() === page!.length - 1) {
           out = false;
        } else {
            if (this.getRecordPointer() && this.getBuffer()[this.getRecordPointer()].RowSelected === true) {
                this.getBuffer()[this.getRecordPointer()].RowSelected = false;
            }

            this.recordPointer = this.getBuffer().size - 1;
            this.getBuffer()[this.recordPointer].RowSelected = true;
            if (enable_events) this.notifyRecordChangedListeners();
            out = true;
        }
        */
        if (out) this.invalidateChildren();

        return out;
    };

    newQuery = () => {
        this.mode = this.MODE_QUERY;
        this.queryBuffer = new Map<number, Map<string, any>[]>();

        this.queryBuffer.set(1, []);
        let initialRow = new Map<string, any>();
        initialRow.set("__operation", "query")
        this.queryBuffer.set(1, [initialRow])
        this.recordPointer = 0;
        this.searchSpecification = {};
        this.sortSpecification = [];
        this.error = null;
        this.notifyNewQueryListeners();
    };

    newDelete = () => {
        this.mode = this.MODE_DELETE;
        let initialRow = new Map<string, any>();
        initialRow.set("__operation", "delete")
        this.queryBuffer.set(1, [initialRow])
        this.isComponentValid = true;
        this.error = null;
        this.notifyNewDeleteListeners();
    };

    newRecord = () => {
        this.mode = this.MODE_NEW;
        this.error = null;

        let row: Map<string, any> = new Map();

        let keys: string[] = Object.keys(this.getAttributes());
        for (let i = 0; i < keys.length; i++) {
            let attribute = this.getAttributes()[keys[i]];
            row.set(attribute.getName(), attribute.getDefaultValue());
        }

        row.set("USID", uuidv4());
        row.set("RowSelected", true);
        row.set("RowNum", 0);
        row.set("__operation", "new");

        let foreignKey = null;
        let destinationAttribute: string | undefined = undefined;
        let realm = null;
        if (this.getEnterpriseObject() !== null) {
            let relationship: Relationship | undefined = this.getEnterpriseObject()!.getRelationshipForChildEnterpriseComponent(this);
            if (relationship !== undefined) {
                destinationAttribute = relationship.getDestinationAttribute();

                let parentEnterpriseComponent: EnterpriseComponent = relationship.getParentEnterpriseComponent()!;

                if (!parentEnterpriseComponent.isValid()) {
                    return;
                }

                if (parentEnterpriseComponent.getBuffer().size !== 0) {
                    if (parentEnterpriseComponent.getRecordPointer() !== -1) {
                        foreignKey = parentEnterpriseComponent.getBuffer().get(parentEnterpriseComponent.getPagePointer())![parentEnterpriseComponent.getRecordPointer()].get(relationship.getSourceAttribute());
                        realm = parentEnterpriseComponent.getBuffer().get(parentEnterpriseComponent.getPagePointer())![parentEnterpriseComponent.getRecordPointer()].get("Realm");
                    }
                }
            } else {
                foreignKey = null;
            }
        } else {
            foreignKey = null;
        }

        if (foreignKey !== null) {
            row.set(destinationAttribute!, foreignKey);
            row.set("Realm", realm);
        }

        if (this.getBuffer().size > 0 && this.getBuffer().get(this.getPagePointer())!.length > 0) {
            for (let i = 0; i < this.getBuffer().get(this.getPagePointer())!.length; i++) {
                this.getBuffer().get(this.getPagePointer())![i].set("RowSelected", false);
            }
        }
        if (this.getRecordPointer() === -1) {
            this.setRecordPointer(0);
        }

        if (this.getBuffer().size === 0) {
            this.setPagePointer(1);
            this.getBuffer().set(this.getPagePointer(), [row]);
        } else {
            this.getBuffer().get(this.getPagePointer())!.splice(this.getRecordPointer(), 0, row);
        }

        if (this.getBuffer().size > 0 && this.getBuffer().get(this.getPagePointer())!.length > 0) {
            for (let i = 0; i < this.getBuffer().get(this.getPagePointer())!.length; i++) {
                this.getBuffer().get(this.getPagePointer())![i].set("RowNum", i);
            }
        }

        this.isComponentValid = true;

        this.notifyStartNewRecordModeListeners();
    };

    nextRecord = (enable_events = true) => {
        if (this.hasEnterpriseComponentBeenModified()) {
            return false;
        }

        if (!this.isValid()) {
            return false;
        }

        let out;
        let page: Map<string, any>[] | undefined = this.getBuffer().get(this.getPagePointer())
        if (!page || page!.length === 0 || this.getRecordPointer() === page!.length - 1) {
            out = false;
        } else {
            let a: any = page![this.getRecordPointer()].get("RowSelected");

            if (this.getRecordPointer() !== -1 && a === true) {
                this.getBuffer().get(this.getPagePointer())![this.getRecordPointer()].set("RowSelected", false);
            }

            let found = false;
            if (this.enterpriseObject !== null) {
                let relationship = this.enterpriseObject.getRelationshipForChildEnterpriseComponent(this);
                if (relationship) {
                    let value = this.getSourceAttributeValueForParent();
                    for (let i = this.recordPointer + 1; i < this.getBuffer().get(this.getPagePointer())!.length; i++) {
                        if (this.getBuffer().get(this.getPagePointer())![i].get(relationship.getDestinationAttribute()) === value) {
                            found = true;
                            this.recordPointer = i;
                            break;
                        } else {
                            this.recordPointer++;
                        }
                    }
                } else {
                    this.recordPointer++;
                    found = true;
                }
            } else {
                this.recordPointer++;
                found = true;
            }

            if (found) {
                page![this.recordPointer].set("RowSelected", true);
                if (enable_events) this.notifyRecordChangedListeners();
                this.invalidateChildren();
                out = true;
            } else {
                out = false;
            }
        }

        return out;
    };

    notifyDeleteStartListeners = () => {
        for (let i = 0; i < this.deleteStartedListeners.length; i++) {
            let listener = this.deleteStartedListeners[i];
            // @ts-ignore
            listener.enterpriseComponentDeleteStarted(this);
        }
    };

    notifyDeleteCompletedListeners = () => {
        for (let i = 0; i < this.deleteCompletedListeners.length; i++) {
            let listener = this.deleteCompletedListeners[i];
            // @ts-ignore
            listener.enterpriseComponentDeleteCompleted(this);
        }
    };

    notifyInsertCompletedListeners = () => {
        for (let i = 0; i < this.insertCompletedListeners.length; i++) {
            let listener = this.insertCompletedListeners[i];
            // @ts-ignore
            listener.enterpriseComponentInsertCompleted(this);
        }
    };

    notifyInsertRollbackCompletedListeners = () => {
        for (let i = 0; i < this.insertRollbackCompletedListeners.length; i++) {
            let listener = this.insertRollbackCompletedListeners[i];
            // @ts-ignore
            listener.enterpriseComponentInsertRollbackCompleted(this);
        }
    };

    notifyInsertStartListeners = () => {
        for (let i = 0; i < this.insertStartedListeners.length; i++) {
            let listener = this.insertStartedListeners[i];
            // @ts-ignore
            listener.enterpriseComponentInsertStarted(this);
        }
    };

    notifyInvalidListeners = () => {
        for (let i = 0; i < this.invalidListeners.length; i++) {
            let listener = this.invalidListeners[i];
            try {
                // @ts-ignore
                listener(this);
            } catch (error) {
                // @ts-ignore
                listener.enterpriseComponentInvalidated(this)
            }
        }
    };

    notifyLoadCompletedListeners = () => {
        for (let i = 0; i < this.loadCompletedListeners.length; i++) {
            let listener = this.loadCompletedListeners[i];
            try {
                // @ts-ignore
                listener(this);
            } catch (error) {
                // @ts-ignore
                listener.enterpriseComponentLoadCompleted(this)
            }
        }
    };

    notifyLoadStartListeners = () => {
        this.isComponentValid = false;

        for (let i = 0; i < this.loadStartedListeners.length; i++) {
            let listener: object = this.loadStartedListeners[i];
            try {
                // @ts-ignore
                listener(this);
            } catch (error) {
                // @ts-ignore
                listener.enterpriseComponentLoadStarted(this)
            }
        }
    };

    notifyNewDeleteListeners = () => {
        for (let i = 0; i < this.newDeleteListeners.length; i++) {
            let listener: object = this.newDeleteListeners[i];
            // @ts-ignore
            listener.enterpriseComponentNewDeleteStarted(this);
        }
    };

    notifyNewQueryListeners = () => {
        for (let i = 0; i < this.newQueryListeners.length; i++) {
            let listener: object = this.newQueryListeners[i];
            // @ts-ignore
            listener.enterpriseComponentNewQueryStarted(this);
        }
    };

    notifyQueryRollbackCompletedListeners = () => {
        for (let i = 0; i < this.queryRollbackCompletedListeners.length; i++) {
            let listener = this.queryRollbackCompletedListeners[i];
            // @ts-ignore
            listener.enterpriseComponentQueryRollbackCompleted(this);
        }
    };

    notifyRecordWillChangeListeners = () => {
        let okToChange = true;
        for (let i = 0; i < this.recordWillChangeListeners.length; i++) {
            let listener = this.recordWillChangeListeners[i];
            // @ts-ignore
            okToChange = okToChange && listener.enterpriseComponentRecordWillChange(this);
        }
        return okToChange;
    };

    notifyRecordChangedListeners = () => {
        for (let i = 0; i < this.recordChangedListeners.length; i++) {
            let listener = this.recordChangedListeners[i];
            // @ts-ignore
            listener.enterpriseComponentRecordChanged(this);
        }
    };

    notifySingleRecordRefreshStartListeners = () => {
        for (let i = 0; i < this.singleRecordRefreshStartedListeners.length; i++) {
            let listener = this.singleRecordRefreshStartedListeners[i];
            // @ts-ignore
            listener.enterpriseComponentSingleRecordRefreshStarted(this);
        }
    };

    notifySingleRecordRefreshCompletedListeners = () => {
        for (let i = 0; i < this.singleRecordRefreshCompletedListeners.length; i++) {
            let listener = this.singleRecordRefreshCompletedListeners[i];
            // @ts-ignore
            listener.enterpriseComponentSingleRecordRefreshCompleted(this);
        }
    };

    notifyStartNewRecordModeListeners = () => {
        for (let i = 0; i < this.startNewRecordModeListeners.length; i++) {
            let listener = this.startNewRecordModeListeners[i];
            // @ts-ignore
            listener.enterpriseComponentStartNewRecordModeCompleted(this);
        }
    };

    notifyStartEditRecordModeListeners = () => {
        for (let i = 0; i < this.startEditRecordModeListeners.length; i++) {
            let listener = this.startEditRecordModeListeners[i];
            // @ts-ignore
            listener.enterpriseComponentStartEditRecordModeCompleted(this);
        }
    };

    notifyUpdateStartListeners = () => {
        for (let i = 0; i < this.updateStartedListeners.length; i++) {
            let listener = this.updateStartedListeners[i];
            // @ts-ignore
            listener.enterpriseComponentUpdateStarted(this);
        }
    };

    notifyUpdateCompletedListeners = () => {
        for (let i = 0; i < this.updateCompletedListeners.length; i++) {
            let listener = this.updateCompletedListeners[i];
            // @ts-ignore
            listener.enterpriseComponentUpdateCompleted(this);
        }
    };

    notifyUpdateRollbackCompletedListeners = () => {
        for (let i = 0; i < this.updateRollbackCompletedListeners.length; i++) {
            let listener = this.updateRollbackCompletedListeners[i];
            // @ts-ignore
            listener.enterpriseComponentUpdateRollbackCompleted(this);
        }
    };

    notifyValueChangedListeners = () => {
        for (let i = 0; i < this.valueChangedListeners.length; i++) {
            let listener = this.valueChangedListeners[i];
            // @ts-ignore
            listener.enterpriseComponentValueChanged(this);
        }
    };

    performDelete() {
    };

    performInsert() {
    };

    performQuery(page?: number, pageSize?: number, foreignKey?: string | null) {
    };

    performSingleRecordRefresh(primaryKey: string){
    };

    performUpdate() {
    };

    prepareDeletePayload = () => {
        let out = {};

        // out["USID"] = this.getBuffer().get(this.getPagePointer())![this.getRecordPointer()]["USID"];

        return out;
    };

    preparePUTPayload() {
        let out: Map<string, any> = new Map<string, any>();

        let page: Map<string, any>[] | undefined = this.getBuffer().get(this.getPagePointer());

        out.set("USID", page![this.getRecordPointer()].get("USID"));

        let keys = Object.keys(this.getAttributes());
        for (let i = 0; i < keys.length; i++) {
            let attribute: EnterpriseComponentAttribute = this.getAttributes()[keys[i]];

            if (!attribute.getComputed()) {
                let record: Map<string, any> = page![this.getRecordPointer()]
                let originalValue: any = record.get(attribute.getName() + "_original");
                if (originalValue !== undefined && attribute.getName() !== "USID") {
                    if (originalValue === record.get(attribute.getName())) {
                        continue;
                    }
                    let value = record.get(attribute.getName());
                    if (attribute.getType() === "Number") {
                        if (value === "") {
                            out.set(attribute.getName(), null);
                        } else {
                            out.set(attribute.getName(), parseInt(value));
                        }
                    } else if (attribute.getType() === "String") {
                        if (value === "") {
                            out.set(attribute.getName(), null);
                        } else {
                            out.set(attribute.getName(), value);
                        }
                    } else {
                        out.set(attribute.getName(), record.get(attribute.getName()));
                    }
                }
            }
        }
        return Object.fromEntries(out);
    };

    previousRecord = (enable_events = true) => {
        if (this.hasEnterpriseComponentBeenModified()) {
            return false;
        }

        if (!this.isValid()) {
            return false;
        }

        let out: boolean;

        let page: Map<string, any>[] | undefined = this.getBuffer().get(this.getPagePointer());
        if (this.getBuffer().size === 0 || page === undefined) {
            out = false;
        } else {
            if (this.getRecordPointer() && page![this.getRecordPointer()].get("RowSelected") === true) {
                page[this.getRecordPointer()].set("RowSelected", false);
            }

            this.recordPointer -= 1;
            page[this.recordPointer].set("RowSelected", true);
            if (enable_events) this.notifyRecordChangedListeners();
            out = true;
        }

        if (out) {
            this.invalidateChildren();
        }

        return out;
    };

    refreshEnterpriseComponentSuccess(data: any) {
        this.mode = this.MODE_RESULTS;

        let results: any = data["Results"][0];

        let page: Map<string, any>[] | undefined = this.resultsBuffer.get(this.getPagePointer());

        if (this.getBuffer().size === 0 || page === undefined) {
            // no nothing
        } else {
            for (let i = 0; i < page.length; i++) {
                let row: Map<string, any> = page[i];

                if (row.get("USID") === results["USID"]) {
                    let attributes = this.getAttributes();

                    for (const key of Object.keys(attributes)) {
                        row.set(key, results[key]);
                    }
                }
            }
        }

        this.notifySingleRecordRefreshCompletedListeners();
        this.waiting = false;
    }

    removeDeleteStartedListener(obj: any) {
        let arr = this.deleteStartedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeDeleteCompletedListener(obj: any) {
        let arr = this.deleteCompletedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeInsertStartedListener(obj: any) {
        let arr = this.insertStartedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeInsertCompletedListener(obj: any) {
        let arr = this.insertCompletedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeInsertRollbackCompletedListener(obj: any) {
        let arr = this.insertRollbackCompletedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeInvalidListener(obj: any) {
        let arr = this.invalidListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeLoadCompletedListener(obj: any) {
        let arr = this.loadCompletedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeLoadStartedListener(obj: any){
        let arr = this.loadStartedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeNewQueryListener(obj: any){
        let arr = this.newQueryListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeRecordChangedListener(obj: any){
        let arr = this.recordChangedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeRecordWillChangeListener(obj: any){
        let arr = this.recordWillChangeListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeSingleRecordRefreshStartedListener(obj: any){
        let arr = this.singleRecordRefreshStartedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeSingleRecordRefreshCompletedListener(obj: any){
        let arr = this.singleRecordRefreshCompletedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeStartNewRecordModeCompletedListener(obj: any){
        let arr = this.startNewRecordModeListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeUpdateStartedListener(obj: any){
        let arr = this.updateStartedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeUpdateCompletedListener(obj: any){
        let arr = this.updateCompletedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeUpdateRollbackCompletedListener(obj: any){
        let arr = this.updateRollbackCompletedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeQueryRollbackCompletedListener(obj: any){
        let arr = this.queryRollbackCompletedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeValueChangedListener(obj: any){
        let arr = this.valueChangedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeAllListeners(obj: any){
        this.removeRecordWillChangeListener(obj);
        this.removeRecordChangedListener(obj);
        this.removeLoadStartedListener(obj);
        this.removeNewQueryListener(obj);
        this.removeLoadCompletedListener(obj);
        this.removeValueChangedListener(obj);
        this.removeInvalidListener(obj);
        this.removeUpdateStartedListener(obj);
        this.removeUpdateCompletedListener(obj);
        this.removeInsertStartedListener(obj);
        this.removeInsertCompletedListener(obj);
        this.removeInsertRollbackCompletedListener(obj);
        this.removeStartNewRecordModeCompletedListener(obj);
        this.removeSingleRecordRefreshStartedListener(obj);
        this.removeSingleRecordRefreshCompletedListener(obj);
        this.removeDeleteStartedListener(obj);
        this.removeDeleteCompletedListener(obj);
    };

    selectRecord(rowNum: number) {
        if (this.getBuffer().size === 0) return;

        let page: Map<string, any>[] | undefined = this.getBuffer().get(this.getPagePointer())

        if (page === undefined) return;

        if (rowNum === this.getRecordPointer() && page![rowNum].get("RowSelected")) return;

        if (this.getRecordPointer() !== -1) {
            page![this.getRecordPointer()].set("RowSelected", false);
        }

        this.setRecordPointer(rowNum);
        page![rowNum].set("RowSelected", true);
        this.notifyRecordChangedListeners();
        this.invalidateChildren();
    };

    setAttributeValue(attributeName: string, attributeValue: any, force = false, enable_events = true) {
        if (!this.canChangeAttributeValue(attributeName)) {
            return false;
        }

        let page: Map<string, any>[] | undefined = this.getBuffer().get(this.getPagePointer())

        let row: Map<string, any> = page![this.getRecordPointer()];

        let operation: any = row.get("__operation");

        if (operation === "query results" || operation === "update") {
            let previousValue: any = row.get(attributeName);

            if (attributeValue === "NULL") {
                attributeValue = null;
            }
            page![this.getRecordPointer()].set(attributeName, attributeValue);

            row.set("__operation", "update");

            if (force) {
                row.set(attributeName + "_original", previousValue ?? null); // force null because preparePUTPayload() skips undefined
                row.set(attributeName + "_old", previousValue ?? null); // force null because preparePUTPayload() skips undefined
            } else {
                if (row.get(attributeName + "_original") === undefined && row.get(attributeName) !== previousValue) {
                    row.set(attributeName + "_original", previousValue);
                    row.set(attributeName + "_old", previousValue);
                } else {
                    row.set(attributeName + "_old", previousValue);
                }
            }
        } else if ("new" === operation) {
            if (attributeValue === "NULL") {
                attributeValue = null;
            }
            page![this.getRecordPointer()].set(attributeName, attributeValue);
        } else if ("delete" === operation) {
            page![this.getRecordPointer()].set(attributeName, attributeValue);
        } else if ("query" === operation) {
            if (attributeValue === "NULL") {
                attributeValue = null;
            }
            page![this.getRecordPointer()].set(attributeName, attributeValue);
        } else if (undefined === operation) {
            if (attributeValue === "NULL") {
                attributeValue = null;
            }
            page![this.getRecordPointer()].set(attributeName, attributeValue);
        }

        if (enable_events) this.notifyValueChangedListeners();
    };

    setBuffer(buffer: any) {
        this.resultsBuffer = buffer;
    };

    setEnterpriseObject(eo: EnterpriseObject) {
        this.enterpriseObject = eo;
    };

    setIDOCRequest(request: string) {
        this.idocRequest = request;
    };

    setIDOCResponse(response: string) {
        this.idocResponse = response;
    };

    setPagePointer(page: number) {
        this.pagePointer = page;
    };

    setRecordPointer(position: number) {
        this.recordPointer = position;
    };

    setSearchSpecification(searchSpecification: any) {
        if (searchSpecification === null) {
            this.searchSpecification = {};
        } else {
            this.searchSpecification = searchSpecification;
        }
    };

    setSortSpecification(sortSpecification: SortSpecification[]) {
        this.sortSpecification = sortSpecification;
    }

    setStore(value: any) {
        this.store = value
    };

    setSupportingData(data: any) {
        this.supportingData = data;
    };

    setUpdateMode() {
        this.mode = this.MODE_UPDATE;
    };

    setVisibilityMode(mode: string) {
        this.visibilityMode = mode;
    };

    setVisibilityValue(value: string) {
        this.visibilityValue = value;
    };

    updateEnterpriseComponentFailed() {
        EventBus.error.emit("show-system-error");
    };

    updateEnterpriseComponentSuccess = (data: any) => {
        let dataset = data?.Results ?? data;

        for (let rowIndex = 0; rowIndex < dataset.length; rowIndex++) {
            let _recordPointer = this._alignBuffer(dataset[rowIndex]["USID"]);
            let keys = Object.keys(dataset[rowIndex]);
            for (let keysIndex = 0; keysIndex < keys.length; keysIndex++) {
                let key = keys[keysIndex];

                /*
                if (this.getEnterpriseObject() !== null) {
                    let childEnterpriseComponents: Map<string, EnterpriseComponent> | undefined = this.getEnterpriseObject()?.getChildEnterpriseComponents(this);
                    let childEnterpriseComponentKeys = Object.keys(childEnterpriseComponents);

                    for (let i = 0; i < childEnterpriseComponentKeys.length; i++) {
                        let childEnterpriseComponent = childEnterpriseComponents[childEnterpriseComponentKeys[i]];

                        if (key === childEnterpriseComponent.getName()) {
                            // process a single enterprise component child - code this another time
                        } else if (key === "ListOf" + childEnterpriseComponent.getName()) {
                            childEnterpriseComponent.updateEnterpriseComponentSuccess(dataset[rowIndex][key]["Results"]);
                        }
                    }
                }
                 */
                this.getBuffer().get(this.getPagePointer())![_recordPointer].set(key, dataset[rowIndex][key]);
                this.getBuffer().get(this.getPagePointer())![_recordPointer].set("RowNum", rowIndex);
                this.getBuffer().get(this.getPagePointer())![_recordPointer].set("__operation", "query results");
            }
        }

        if (dataset.length > 0) {
            this.getBuffer().get(this.getPagePointer())![this.getRecordPointer()].set("RowSelected", true);
        }

        this.mode = this.MODE_RESULTS;

        this.notifyUpdateCompletedListeners();
    };

    _alignBuffer(usidToFind: string) {
        let page: Map<string, any>[] = this.getBuffer().get(this.getPagePointer())!;
        for (let i = 0; i < page.length; i++) {
            if (page[i].get("USID") === usidToFind) return i;
        }
        return -1;
    };
}

export default EnterpriseComponent;